import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Status, initialStatus } from '../../../../status.interface';
import * as actions from './actions';
import { PerUserMfaModel } from './model';

export const featureKey = 'graphPerUserMfa';

export interface State extends EntityState<PerUserMfaModel>, Status {
    // additional entities state properties
}

export const adapter: EntityAdapter<PerUserMfaModel> = createEntityAdapter<PerUserMfaModel>({
    selectId: (item) => item.userId,
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.fetchGraphPerUserMfaStateSuccess, (state, action) =>
        adapter.setAll(action.data, { ...state, loaded: true }),
    ),
    on(actions.fetchGraphPerUserMfaStateFailure, (state, { error }) => ({ ...state, error, loaded: false })),

    on(actions.updatePerUserMfaState, (state) => ({ ...state, updating: true })),

    on(actions.updatePerUserMfaStateSuccess, (state, action) =>
        adapter.updateOne(
            { id: action.userId, changes: { value: action.perUserMfaState } },
            { ...state, updating: false },
        ),
    ),
    on(actions.updatePerUserMfaStateFailure, (state, { error }) => ({ ...state, error, updating: false })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
