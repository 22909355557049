import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { Geo } from './model';
import { initialStatus, Status } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'MspGeo';

export interface State extends EntityState<Geo>, Status {}

export const adapter: EntityAdapter<Geo> = createEntityAdapter<Geo>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadGeos, (state) => ({ ...state, loading: !state.loaded })),
    on(actions.loadGeosSuccess, (state, action) =>
        adapter.setAll(action.geos, { ...state, loading: false, loaded: true }),
    ),
    on(actions.loadGeosFailure, (state, { error }) => ({ ...state, loading: false, error })),

    on(actions.createGeo, (state) => ({ ...state, creating: true })),
    on(actions.createGeoSuccess, (state, action) => adapter.addOne(action.geo, { ...state, creating: false })),
    on(actions.createGeoFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(actions.updateGeo, (state) => ({ ...state, updating: true })),
    on(actions.updateGeoSuccess, (state, action) => adapter.upsertOne(action.geo, { ...state, updating: false })),
    on(actions.updateGeoFailure, (state, { error }) => ({ ...state, error, updating: false })),

    on(actions.deleteGeo, (state) => ({ ...state, deleting: true })),
    on(actions.deleteGeoSuccess, (state, action) => adapter.removeOne(action.id, { ...state, deleting: false })),
    on(actions.deleteGeoFailure, (state, { error }) => ({ ...state, error, deleting: false })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
