import { updateSpoTenant } from 'projects/angular-clarity/src/app/stores/client/powershell/spo/tenant/actions';
import { SpoTenant } from 'projects/angular-clarity/src/app/stores/client/powershell/spo/tenant/model';

export function Remediate(_tenant: string, data: Partial<SpoTenant>, schema: any) {
    if (schema.properties.DisallowInfectedFileDownload.const === false) {
        return updateSpoTenant({ _tenant, data: { DisallowInfectedFileDownload: false } });
    }

    if (schema.properties.DisallowInfectedFileDownload.const === true) {
        return updateSpoTenant({ _tenant, data: { DisallowInfectedFileDownload: true } });
    }
}
