import { getCompliancePolicyAssignments } from '..';
import { IOSCompliancePolicySchema } from 'projects/angular-clarity/src/app/modules/sway/tools/schemas/compliance-policies/IOSCompliancePolicy/model';
import {
    createCompliancePolicy,
    updateCompliancePolicy,
} from 'projects/angular-clarity/src/app/stores/client/graph/compliance-policies/policies/actions';
import { PolicyAssignment } from 'projects/angular-clarity/src/app/stores/client/graph/compliance-policies/policies/model';

export function Remediate(_tenant: string, data: PolicyAssignment[], schema: IOSCompliancePolicySchema) {
    const displayName = schema.contains.properties.policy.properties.displayName?.const;

    const currentPolicy = data.find((res) => res.policy?.displayName === displayName);

    const policy = {
        '@odata.type': '#microsoft.graph.iosCompliancePolicy',
        displayName: schema.contains.properties.policy.properties.displayName?.const,
        description: schema.contains.properties.policy.properties.description?.const,

        // Email
        managedEmailProfileRequired: schema.contains.properties.policy.properties.managedEmailProfileRequired?.const,

        // Device Health
        securityBlockJailbrokenDevices:
            schema.contains.properties.policy.properties.securityBlockJailbrokenDevices?.const,

        deviceThreatProtectionRequiredSecurityLevel:
            schema.contains.properties.policy.properties.deviceThreatProtectionRequiredSecurityLevel?.const,
        // Device Properties
        osMinimumVersion: schema.contains.properties.policy.properties.osMinimumVersion?.const || null, //string
        osMaximumVersion: schema.contains.properties.policy.properties.osMaximumVersion?.const || null, //string
        osMinimumBuildVersion: schema.contains.properties.policy.properties.osMinimumBuildVersion?.const || null, //string
        osMaximumBuildVersion: schema.contains.properties.policy.properties.osMaximumBuildVersion?.const || null, //string

        // Microsoft Defender for Endpoint
        advancedThreatProtectionRequiredSecurityLevel:
            schema.contains.properties.policy.properties.advancedThreatProtectionRequiredSecurityLevel?.const,

        // System Security (Password)
        passcodeRequired: schema.contains.properties.policy.properties.passcodeRequired?.const,
        passcodeBlockSimple: schema.contains.properties.policy.properties.passcodeBlockSimple?.const,
        passcodeMinimumLength: schema.contains.properties.policy.properties.passcodeMinimumLength?.const,
        passcodeRequiredType: schema.contains.properties.policy.properties.passcodeRequiredType?.const,

        passcodeMinimumCharacterSetCount:
            schema.contains.properties.policy.properties.passcodeMinimumCharacterSetCount?.const ?? null,

        passcodeMinutesOfInactivityBeforeScreenTimeout:
            schema.contains.properties.policy.properties.passcodeMinutesOfInactivityBeforeScreenTimeout?.const,

        passcodeMinutesOfInactivityBeforeLock:
            schema.contains.properties.policy.properties.passcodeMinutesOfInactivityBeforeLock?.const,
        passcodeExpirationDays: schema.contains.properties.policy.properties.passcodeExpirationDays?.const,
        passcodePreviousPasscodeBlockCount:
            schema.contains.properties.policy.properties.passcodePreviousPasscodeBlockCount?.const,
        restrictedApps:
            schema.contains.properties.policy.properties.restrictedApps?.items?.oneOf.map((res) => ({
                name: res.properties.name.const,
                appId: res.properties.appId.const,
            })) || [],
    };

    const assignments = getCompliancePolicyAssignments(schema);

    // need to update store
    if (currentPolicy?.id) {
        return updateCompliancePolicy({ _tenant, policy: { ...policy, id: currentPolicy.id }, assignments });
    } else {
        // const gracePeriodHours = schema.contains.properties.policy.properties.validOperatingSystemBuildRanges
        return createCompliancePolicy({
            _tenant,
            policy: {
                ...policy,

                scheduledActionsForRule: [
                    {
                        ruleName: 'DeviceNonCompliance',
                        scheduledActionConfigurations: [
                            {
                                actionType: 'block',
                                gracePeriodHours: 0, // 2 * 24 // convert days to hours
                            },
                        ],
                    },
                ],
            },
            assignments,
        });
    }
}
