import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { combineLatest, filter, take } from 'rxjs';
import { selectMspData } from 'projects/angular-clarity/src/app/modules/msp/store';
import {
    sendMspLockoutEmail,
    sendMspUpgradeRequestEmail,
} from 'projects/angular-clarity/src/app/modules/msp/store/msp/actions';
import * as fromRoot from 'projects/angular-clarity/src/app/stores/root.store';
import { deleteSession } from 'projects/angular-clarity/src/app/stores/root/octiga/session/actions';

@Component({
    selector: 'app-trial-expire-modal',
    templateUrl: './trial-expire-modal.component.html',
    styleUrls: ['./trial-expire-modal.component.scss'],
})
export class TrialExpireDialogComponent implements OnInit {
    activation_lockout;
    public step = 1;
    public user = '';

    private $session = this.store.pipe(
        select(fromRoot.selectSession),
        filter((state) => state.isAuthenticated && !!state.session.msp_id),
    );

    private $msp = this.store.pipe(
        select(selectMspData),
        filter((msp) => !!msp),
    );

    constructor(
        public store: Store<any>,
        public modal: MatDialogRef<TrialExpireDialogComponent>,
    ) {}

    ngOnInit(): void {
        combineLatest([this.$session, this.$msp])
            .pipe(take(1))
            .subscribe(([session, msp]) => {
                this.user = session.session.upn;
                this.store.dispatch(
                    sendMspLockoutEmail({
                        msp_id: msp.id,
                        name: msp.name,
                        upn: this.user,
                        country_code: msp.country_code,
                    }),
                );
                this.activation_lockout = msp.activation_lockout;
            });
    }

    public requestUpgrade(): void {
        this.$msp.pipe(take(1)).subscribe((msp) => {
            this.store.dispatch(
                sendMspUpgradeRequestEmail({
                    msp_id: msp.id,
                    name: msp.name,
                    upn: this.user,
                    country_code: msp.country_code,
                }),
            );
        });
        this.step = 2;
    }

    deleteSession(): void {
        this.store.dispatch(
            deleteSession({
                microsoftSignout: true,
            }),
        );
    }
}
