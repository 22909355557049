import { Clipboard } from '@angular/cdk/clipboard';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { SnackbarService } from '../../snackbar.service';
import { client } from 'projects/angular-clarity/src/app/stores/client';
import { reloadGraphRoles } from 'projects/angular-clarity/src/app/stores/client/octiga/status/roles/actions';
import { MissingRoles } from 'projects/angular-clarity/src/app/utils/configured-azure-permissions';
import { OkDialogComponent } from 'projects/angular-clarity/src/app/modules/shared/components/ok-dialog/ok-dialog.component';

@Component({
    selector: 'app-permission-dialog',
    templateUrl: './permission-dialog.component.html',
    styleUrls: ['./permission-dialog.component.scss'],
    animations: [
        trigger('showHide', [
            state('close', style({ height: '0px', overflow: 'hidden' })),
            state('open', style({ height: '*', overflow: 'hidden' })),
            transition('open <=> close', animate('600ms ease-in-out')),
        ]),
    ],
})
export class PermissionDialogComponent implements OnInit {
    public addInfo: 'open' | 'close' = 'close';

    roles: string[] = [];
    missing_roles = [];
    oldRoles: string[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { link: string; tenant: string },
        private store: Store<any>,
        private dialogRef: MatDialogRef<PermissionDialogComponent>,
        private snackbar: SnackbarService,
        private clipboard: Clipboard,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.store
            .select(client(this.data.tenant).octiga.status.roles.all)
            .pipe(take(1))
            .subscribe((roles) => {
                this.oldRoles = roles;
                this.missing_roles = MissingRoles(roles);
            });
    }

    public authenticatePermissions(): void {
        // Open the authentication link in a new window

        window.open(this.data.link, '_blank', 'width=1200,height=800');

        // Listen for messages from the popup window
        window.addEventListener(
            'message',
            (event) => {
                if (event.data.consented) {
                    this.snackbar.open({
                        data: {
                            message: 'Permission has been added successfully',
                            success: true,
                        },
                    });

                    this.dialogRef.close();

                    this.store.dispatch(reloadGraphRoles({ _tenant: this.data.tenant, oldRoles: this.oldRoles }));
                }
            },
            false,
        );
    }

    copyAuthorizeLink(): void {
        this.clipboard.copy(this.data.link);

        this.dialog.open(OkDialogComponent, {
            data: {
                title: 'Link has been copied to the clipboard',
                description: 'Share this link with your tenant admin to grant Octiga the required permissions.',
                confirm_button_text: 'OK',
            },
        });
    }
}
