import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Row } from '../../group-details/group-details.component';
import { Baseline } from 'projects/angular-clarity/src/app/stores/client/sway/baseline/model';

@Component({ template: '' })
export class BaselineFormComponent {
    @Input() baseline: Baseline;
    @Input() readonly = false;
    @Output() baselineChange = new EventEmitter<{ baseline: Baseline; autoSave?: boolean }>();
    field?: string; // if present, component with select 'field' from data
    @Input() mapCurrentValue: (value: any) => any;

    @Input() data: Row[];
}
