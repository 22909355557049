export const riskyWords: string[] = [
    // English
    'invoice',
    'bank detail',
    'payment detail',
    'account detail',
    'credit card',
    'billing',
    'iban',
    'swift code',
    'sort code',
    'aba routing number',
    'ach',
    'transit number',
    'institution number',
    'bsb code',
    'ifsc code',
    'micr code',
    'clabe',
    'wire transfer',
    'routing number',
    'paypal',
    'venmo',
    'bitcoin',
    'cryptocurrency',
    'payment link',
    'bank account',
    'account number',
    'security code',
    'cvv',
    'pin',
    'otp',
    'password',
    'username',
    'login details',
    'urgent payment',
    'overdue invoice',
    'scam',
    'fraud',
    'phishing',
    'malware',
    'lottery',
    'winner',
    'inheritance',
    'congratulations',
    'free money',
    'limited offer',

    // Portuguese
    'fatura',                  // invoice
    'dados bancários',         // bank details
    'detalhes de pagamento',   // payment details
    'detalhes de conta',       // account details
    'cartão de crédito',       // credit card
    'cobrança',                // billing
    'transferência bancária',  // wire transfer
    'golpe',                   // scam
    'fraude',                  // fraud
    'urgente',                 // urgent
    'parabéns',                // congratulations
    'oferta limitada',         // limited offer

    // French
    'facture',                 // invoice
    'coordonnées bancaires',   // bank details
    'détails de paiement',     // payment details
    'détails de compte',       // account details
    'carte de crédit',         // credit card
    'facturation',             // billing
    'virement bancaire',       // wire transfer
    'arnaque',                 // scam
    'hameçonnage',             // phishing
    'urgence',                 // urgent
    'félicitations',           // congratulations
    'offre limitée',           // limited offer

    // Spanish
    'factura',                 // invoice
    'datos bancarios',         // bank details
    'detalles de pago',        // payment details
    'detalles de cuenta',      // account details
    'tarjeta de crédito',      // credit card
    'facturación',             // billing
    'transferencia bancaria',  // wire transfer
    'estafa',                  // scam
    'fraude',                  // fraud
    'urgente',                 // urgent
    'felicitaciones',          // congratulations
    'oferta limitada',         // limited offer

    // German
    'rechnung',                // invoice
    'bankverbindung',          // bank details
    'zahlungsdetails',         // payment details
    'kontoinformationen',      // account details
    'kreditkarte',             // credit card
    'abrechnung',              // billing
    'überweisung',             // wire transfer
    'abzocke',                 // scam (colloquial)
    'betrug',                  // fraud
    'dringend',                // urgent
    'gewinner',                // winner
    'lotterie',                // lottery
    'erbschaft',               // inheritance
    'kostenloses geld',        // free money
    'begrenztes angebot',      // limited offer

    // Italian
    'fattura',                 // invoice
    'coordinate bancarie',     // bank details
    'dettagli di pagamento',   // payment details
    'dettagli del conto',      // account details
    'carta di credito',        // credit card
    'fatturazione',            // billing
    'bonifico bancario',       // wire transfer
    'truffa',                  // scam
    'frode',                   // fraud
    'urgente',                 // urgent
    'lotteria',                // lottery
    'vincitore',               // winner
    'eredità',                 // inheritance
    'soldi gratis',            // free money
    'offerta limitata',        // limited offer

    // Dutch
    'factuur',                 // invoice
    'bankgegevens',            // bank details
    'betalingsgegevens',       // payment details
    'rekeninggegevens',        // account details
    'creditcard',              // credit card
    'facturering',             // billing
    'overschrijving',          // wire transfer
    'oplichterij',             // scam
    'fraude',                  // fraud
    'dringend',                // urgent
    'winnaar',                 // winner
    'loterij',                 // lottery
    'erfenis',                 // inheritance
    'gratis geld',             // free money
    'beperkte aanbieding',     // limited offer

    // Chinese (Simplified)
    '发票',                     // invoice
    '银行信息',                  // bank details
    '付款信息',                  // payment details
    '账户信息',                  // account details
    '信用卡',                   // credit card
    '账单',                     // billing
    '电汇',                     // wire transfer
    '诈骗',                     // scam
    '欺诈',                     // fraud
    '网络钓鱼',                  // phishing
    '紧急',                     // urgent
    '彩票',                     // lottery
    '中奖者',                   // winner
    '遗产',                     // inheritance
    '免费钱',                    // free money
    '限时优惠',                   // limited offer

    // Arabic
    'فاتورة',                    // invoice
    'تفاصيل البنك',               // bank details
    'تفاصيل الدفع',               // payment details
    'تفاصيل الحساب',              // account details
    'بطاقة ائتمان',               // credit card
    'الفوترة',                    // billing
    'حوالة مصرفية',               // wire transfer
    'احتيال',                    // scam/fraud
    'تصيد احتيالي',               // phishing
    'عاجل',                      // urgent
    'يانصيب',                    // lottery
    'فائز',                      // winner
    'ميراث',                     // inheritance
    'مال مجاني',                  // free money
    'عرض محدود',                  // limited offer

    // Japanese
    '請求書',                    // invoice
    '銀行情報',                   // bank details
    '支払い詳細',                 // payment details
    '口座情報',                   // account details
    'クレジットカード',            // credit card
    '請求',                      // billing
    '振込',                      // wire transfer
    '詐欺',                      // scam/fraud
    'フィッシング',               // phishing
    '緊急',                      // urgent
    '宝くじ',                     // lottery
    '当選者',                     // winner
    '遺産',                      // inheritance
    '無料のお金',                 // free money
    '期間限定オファー',           // limited offer

    // Russian
    'счёт-фактура',             // invoice
    'банковские реквизиты',      // bank details
    'платёжные реквизиты',       // payment details
    'данные счета',             // account details
    'кредитная карта',          // credit card
    'выставление счетов',       // billing
    'банковский перевод',        // wire transfer
    'мошенничество',            // scam/fraud
    'фишинг',                   // phishing
    'срочный',                  // urgent
    'лотерея',                  // lottery
    'победитель',               // winner
    'наследство',               // inheritance
    'бесплатные деньги',         // free money
    'ограниченное предложение'   // limited offer
  ];

  export const riskyFolders: string[] = ["rss", "delete", "trash", "junk", "spam"];
