import { AdminAuditLogConfig } from 'projects/angular-clarity/src/app/stores/client/powershell/exo/admin-audit-log-config/model';
import { updateSpoTenant } from 'projects/angular-clarity/src/app/stores/client/powershell/spo/tenant/actions';

export function Remediate(_tenant: string, data: Partial<AdminAuditLogConfig>, schema: any) {
    if (schema.properties.LegacyAuthProtocolsEnabled.const === false) {
        return updateSpoTenant({ _tenant, data: { LegacyAuthProtocolsEnabled: false } });
    }

    if (schema.properties.LegacyAuthProtocolsEnabled.const === true) {
        return updateSpoTenant({ _tenant, data: { LegacyAuthProtocolsEnabled: true } });
    }
}
