import { Injectable } from '@angular/core';
import { ReducerManager, Store } from '@ngrx/store';
import { loadGraphRoles } from '../stores/client/octiga/status/roles/actions';
import { loadTenant } from './../stores/client/octiga/tenant/actions';
import * as clientReducers from 'projects/angular-clarity/src/app/stores/client';

export function isoDateAddDays(iso: string, days: number): string {
    const date = new Date(iso);
    date.setDate(date.getDate() + days);
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString();
}

function ClientActions(_tenant) {
    return [loadGraphRoles({ _tenant }), loadTenant({ _tenant })];
}
@Injectable({
    providedIn: 'root',
})
export class InitClientService {
    loaded: {
        [key: string]: Set<string>;
    } = {};

    constructor(
        private reducers: ReducerManager,
        private store: Store<any>,
    ) {}

    public loadClient(id: string) {
        this.addClientReducer(id);
        queueMicrotask(() => {
            this.dispatch(id, ClientActions(id));
        });
    }

    public addClientReducer(id: string) {
        if (Object.keys(this.reducers.currentReducers).indexOf(id) < 0) {
            this.reducers.addReducer(id, clientReducers.reducer(id));
            this.loaded[id] = new Set();
        }
    }

    private dispatch(id: string, actions: any[]) {
        for (const action of actions) {
            if (
                !this.loaded[id].has(action.type) ||
                (action.type === '[Root/Summary/API] Load Summarys' && action.num_days > 7)
            ) {
                this.loaded[id].add(action.type);
                this.store.dispatch(action);
            }
        }
    }
}
