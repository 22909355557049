import { Injectable } from '@angular/core';
import { DirectoryObject } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, expand, map, mergeMap, reduce } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as actions from './actions';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';
import { skipUntilTenantLoaded } from 'projects/angular-clarity/src/app/services/blob.service';

interface GraphMembersResponse {
    value: DirectoryObject[];
    '@odata.nextLink'?: string;
}

function parseToken(response: GraphMembersResponse) {
    let skiptoken: string;
    if (response['@odata.nextLink']) {
        skiptoken = response['@odata.nextLink'].split('skiptoken=')[1];
    }
    return skiptoken;
}

@Injectable()
export class GroupMembersEffects {
    loadMembers = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadMembers),
            skipUntilTenantLoaded(this.store),
            mergeMap(({ _tenant, id }) =>
                this.get(_tenant, id).pipe(map((members) => actions.loadMembersSuccess({ _tenant, id, members }))),
            ),
        ),
    );

    addMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.addMemberToGroup),
            mergeMap(({ _tenant, group, user }) =>
                this.addMember(_tenant, group.id, user.id).pipe(
                    map(() => actions.addMemberToGroupSuccess({ _tenant, group, user })),
                    catchError((error) => of(actions.addMemberToGroupFailure({ _tenant, group, error }))),
                ),
            ),
        ),
    );

    addMember(_tenant: string, groupId: string, userId: string) {
        const url = `/api/microsoft/graph/groups/${groupId}/members/$ref`;
        const directoryObject = {
            '@odata.id': `https://graph.microsoft.com/beta/directoryObjects/${userId}`,
        };

        return this.ajax.post(_tenant, url, directoryObject);
    }

    removeMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.removeMemberFromGroup),

            mergeMap(({ _tenant, group, userId }) =>
                this.removeMember(_tenant, group.id, userId).pipe(
                    map(() => actions.removeMemberFromGroupSuccess({ _tenant, group, userId })),
                    catchError((error) => of(actions.removeMemberFromGroupFailure({ _tenant, group, error }))),
                ),
            ),
        ),
    );

    removeMember(_tenant: string, groupId: string, userId: string) {
        const url = `/api/microsoft/graph/groups/${groupId}/members/${userId}/$ref`;
        return this.ajax.delete(_tenant, url);
    }

    private get(tenant: string, group_id: string) {
        const url = `/api/microsoft/graph/groups/${group_id}/members`;
        return this.ajax.get<GraphMembersResponse>(tenant, url).pipe(
            expand((data) => {
                const token = parseToken(data);
                if (token) {
                    return this.ajax.get<GraphMembersResponse>(tenant, `${url}?$skiptoken=${token}`);
                } else {
                    return EMPTY;
                }
            }),
            reduce((acc, data) => acc.concat(data?.value || []), [] as DirectoryObject[]),
        );
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store,
    ) {}
}
