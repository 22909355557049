import { Group } from '@microsoft/microsoft-graph-types-beta';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as GroupActions from './actions';
import { initialStatus, Status } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'graphGroup';

export interface State extends EntityState<Group>, Status {}

export const adapter: EntityAdapter<Group> = createEntityAdapter<Group>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(GroupActions.loadGroupsSuccess, (state, action) =>
        adapter.upsertMany(action.groups, { ...state, loaded: true }),
    ),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
