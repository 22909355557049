import { AssignedLicense, PasswordProfile, User } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadGraphUsers = createAction('[Graph/User] Load', props<{ _tenant: string }>());

export const reloadGraphUsers = createAction('[Graph/User] Reload', props<{ _tenant: string }>());

export const loadGraphUsersSuccess = createAction(
    '[Graph/User] Load Success',
    props<{ _tenant: string; users: User[] }>(),
);

export const loadGraphUsersFailure = createAction(
    '[Graph/User] Load Failure',
    props<{ _tenant: string; error: any }>(),
);

export const createGraphUser = createAction('[Graph/User] Create One', props<{ _tenant: string; user: User }>());

export const createGraphUsersSuccess = createAction(
    '[Graph/User] Create One Success',
    props<{ _tenant: string; user: User }>(),
);

export const createGraphUsersFailure = createAction(
    '[Graph/User] Create One Failure',
    props<{ _tenant: string; error: any }>(),
);

export const resetUserPassword = createAction(
    '[Graph/User] Reset Password',
    props<{ _tenant: string; userPrincipalName: string; passwordProfile: PasswordProfile; id?: string }>(),
);

export const resetUserPasswordSuccess = createAction(
    '[Graph/User] Reset Password Successful',
    props<{ _tenant: string; userPrincipalName: string }>(),
);

export const resetUserPasswordFailure = createAction(
    '[Graph/User] Reset Password Failure',
    props<{ _tenant: string; userPrincipalName: string; error: any }>(),
);

export const loadGraphUserSignInDatesFailure = createAction(
    '[Graph/User] Load Sign In Dates Failure',
    props<{ _tenant: string }>(),
);

export const reloadUserWithSignInActivity = createAction(
    '[Graph/User] Reload User With Signin Activity',
    props<{ _tenant: string; id: string }>(),
);

export const reloadUserWithSignInActivitySuccess = createAction(
    '[Graph/User] Reload User With Signin Activity Successful',
    props<{ _tenant: string; user: User }>(),
);

export const reloadUserWithSignInActivityFailure = createAction(
    '[Graph/User] Reload User With Signin Activity Failure',
    props<{ _tenant: string; error: any }>(),
);

export const revokeUserSignInSession = createAction(
    '[Graph/User] Revoke User Signin Session',
    props<{ _tenant: string; userPrincipalName: string }>(),
);

export const revokeUserSignInSessionSuccess = createAction(
    '[Graph/User] Revoke User Signin Session Success',
    props<{ _tenant: string; userPrincipalName: string }>(),
);

export const revokeUserSignInSessionFailure = createAction(
    '[Graph/User] Revoke User Signin Session Failure',
    props<{ _tenant: string; userPrincipalName: string; error: any }>(),
);

export const deleteGraphUsers = createAction(
    '[Graph/User] Delete Multiple',
    props<{ _tenant: string; ids: string[] }>(),
);

export const deleteGraphUsersSuccess = createAction(
    '[Graph/User] Delete Multiple Success',
    props<{ _tenant: string; ids: string[] }>(),
);

export const deleteGraphUsersFailure = createAction(
    '[Graph/User] Delete Multiple Failure',
    props<{ _tenant: string; error: any }>(),
);

export const assignLicense = createAction(
    '[Graph/User] Assign License',
    props<{ _tenant: string; userId: string; addLicenses: Array<AssignedLicense>; removeLicenses: Array<string> }>(),
);

export const assignLicenseSuccess = createAction(
    '[Graph/User] Assign License Success',
    props<{ _tenant: string; user: Partial<User> }>(),
);

export const assignLicenseFailure = createAction(
    '[Graph/User] Assign License Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateGraphUser = createAction(
    '[Graph/User] Update User',
    props<{ _tenant: string; userId: string; data: Partial<User> }>(),
);

export const updateGraphUserSuccess = createAction(
    '[Graph/User] Update User Success',
    props<{ _tenant: string; userId: string; data: Partial<User> }>(),
);

export const updateGraphUserFailure = createAction(
    '[Graph/User] Update User Failure',
    props<{ _tenant: string; error: any }>(),
);

export const createGraphUsersWithRole = createAction(
    '[Graph/User] Create Multiple With Role',
    props<{ _tenant: string; users: User[]; roleTemplateId: string }>(),
);

export const createGraphUsersWithRoleSuccess = createAction(
    '[Graph/User] Create Multiple With Role Success',
    props<{ _tenant: string; users: User[] }>(),
);
