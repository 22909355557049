import { User } from '@microsoft/microsoft-graph-types-beta';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, sample } from 'rxjs';
import { BooleanFormComponent, BooleanValidatorComponent } from '../generic/boolean';
import { UserExclusion } from '../../group-spec-registry.service';
import { client } from 'projects/angular-clarity/src/app/stores/client';
import { updateGraphUser } from 'projects/angular-clarity/src/app/stores/client/graph/user/user.actions';
import { Status } from 'projects/angular-clarity/src/app/stores/status.interface';

export interface ExtendedUser extends User {
    RecipientTypeDetails: string;
}

export const fieldName = 'accountEnabled';
export const href =
    'https://learn.microsoft.com/en-us/microsoft-365/admin/email/create-a-shared-mailbox?view=o365-worldwide#block-sign-in-for-the-shared-mailbox-account';

export { BooleanFormComponent as FormComponent, BooleanValidatorComponent as ValidatorComponent };

export function Remediate(_tenant: string, current: User, schema: any) {
    if (schema.properties[fieldName]?.const === true) {
        const data = {
            id: current.id,
            [fieldName]: true,
        };
        return updateGraphUser({ _tenant, userId: current.id, data });
    }

    if (schema.properties[fieldName]?.const === false) {
        const data = {
            id: current.id,
            [fieldName]: false,
        };
        return updateGraphUser({ _tenant, userId: current.id, data });
    }
}

export function fetch_data(store: Store<any>, tenant_id) {
    // users
    const usersReady$ = store.select(client(tenant_id).graph.users.status).pipe(filter((res) => res.loaded));

    const users$ = store.select(client(tenant_id).graph.users.all).pipe(sample(usersReady$));

    // mailboxes
    const mailboxesReady$ = store
        .select(client(tenant_id).powershell.exoMailbox.status)
        .pipe(filter((res) => res.loaded));
    const mailboxes$ = store.select(client(tenant_id).powershell.exoMailbox.all).pipe(sample(mailboxesReady$));

    return combineLatest([users$, mailboxes$]).pipe(
        map(([users, mailboxes]) => {
            const user_data: any[] = [];

            for (const user of users) {
                // filter roles where user is member

                user_data.push({
                    ...user,
                    RecipientTypeDetails: mailboxes.find((m) => m.UserPrincipalName === user.userPrincipalName)
                        ?.RecipientTypeDetails,
                });
            }
            return user_data;
        }),
    );
}

export function select_status(store: Store<any>, tenant_id): Observable<Status> {
    // users
    const usersReady$ = store.select(client(tenant_id).graph.users.status).pipe(filter((res) => res.loaded));

    // mailboxes
    const mailboxesReady$ = store
        .select(client(tenant_id).powershell.exoMailbox.status)
        .pipe(filter((res) => res.loaded));

    return combineLatest([usersReady$, mailboxesReady$]).pipe(
        map(([userStatus, mailboxStatus]) => ({
            loading: userStatus.loading && mailboxStatus.loading,
            loaded: userStatus.loaded && mailboxStatus.loaded,
            updating: userStatus.updating && mailboxStatus.updating,
            error: userStatus.error && mailboxStatus.error,
        })),
    );
}

export function exclude(user: ExtendedUser): UserExclusion {
    if (user.RecipientTypeDetails !== 'SharedMailbox') {
        return {
            type: 'other',
            desc: 'Excluded because it is not a shared mailbox.',
            class: 'na-icon',
            icon: 'not_interested',
        };
    }

    return;
}
