import { SubscribedSku } from '@microsoft/microsoft-graph-types-beta';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { initialStatus, Status } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'graphSubscribedSkus';

export interface State extends EntityState<SubscribedSku>, Status {}

export const adapter: EntityAdapter<SubscribedSku> = createEntityAdapter<SubscribedSku>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,

    on(actions.loadSubscribedSkusSuccess, (state, action) => adapter.setAll(action.skus, { ...state, loaded: true })),
    on(actions.loadSubscribedSkusFailure, (state, { error }) => ({ ...state, error, loaded: false })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
