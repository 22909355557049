import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TargetGroups } from '../generic';
import { MacOsCompliancePolicySchema } from './model';

export function initForm(schema: MacOsCompliancePolicySchema, fb: FormBuilder): FormGroup {
    let form = new FormGroup({});

    form = fb.group({
        displayName: [schema.contains.properties.policy.properties.displayName?.const, Validators.required],
        description: [schema.contains.properties.policy.properties.description?.const],

        systemIntegrityProtectionEnabled: [
            schema.contains.properties.policy.properties.systemIntegrityProtectionEnabled?.const,
        ], // boolean

        // device properties
        osMinimumVersion: [
            schema.contains.properties.policy.properties.osMinimumVersion?.const,
            [
                Validators.pattern(/^\d{1,5}(?:\.\d{1,5}){0,3}$/),
                minmaxValidator(form.get('osMinimumVersion'), 'osMaximumVersion', 'max'),
            ],
        ], //string
        osMaximumVersion: [
            schema.contains.properties.policy.properties.osMaximumVersion?.const,
            [
                Validators.pattern(/^\d{1,5}(?:\.\d+){0,3}$/),
                minmaxValidator(form.get('osMaximumVersion'), 'osMinimumVersion', 'min'),
            ],
        ], //string  //TODO check validity
        osMinimumBuildVersion: [
            schema.contains.properties.policy.properties.osMinimumBuildVersion?.const,
            [
                Validators.pattern(/^\d{2}[A-Z]\d{3,6}[a-z]?$/),
                minmaxValidator(form.get('osMinimumBuildVersion'), 'osMaximumBuildVersion', 'max'),
            ],
        ], //string
        osMaximumBuildVersion: [
            schema.contains.properties.policy.properties.osMaximumBuildVersion?.const,
            [
                Validators.pattern(/^\d{2}[A-Z]\d{3,6}[a-z]?$/),
                minmaxValidator(form.get('osMaximumBuildVersion'), 'osMinimumBuildVersion', 'min'),
            ],
        ], //string

        // system security
        passwordRequired: [schema.contains.properties.policy.properties.passwordRequired?.const], // boolean // check
        passwordBlockSimple: [schema.contains.properties.policy.properties.passwordBlockSimple?.const], // boolean
        passwordRequiredType: [schema.contains.properties.policy.properties.passwordRequiredType?.const], // string
        passwordMinimumLength: [
            schema.contains.properties.policy.properties.passwordMinimumLength?.const,
            [Validators.min(4), Validators.max(14)],
        ], // number
        passwordMinimumCharacterSetCount: [
            schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount?.const ?? 'notConfigured',
        ], // TODO
        passwordMinutesOfInactivityBeforeLock: [
            schema.contains.properties.policy.properties.passwordMinutesOfInactivityBeforeLock?.const ?? 0,
        ], // number
        passwordExpirationDays: [
            schema.contains.properties.policy.properties.passwordExpirationDays?.const,
            [Validators.min(1), Validators.max(65535)],
        ], // [Validators.min(1), Validators.max(225)]
        passwordPreviousPasswordBlockCount: [
            schema.contains.properties.policy.properties.passwordPreviousPasswordBlockCount?.const,
            [Validators.min(1), Validators.max(24)],
        ], // number

        // Encryption
        storageRequireEncryption: [schema.contains.properties.policy.properties.storageRequireEncryption?.const], // boolean
        firewallEnabled: [schema.contains.properties.policy.properties.firewallEnabled?.const], //boolean
        firewallBlockAllIncoming: [schema.contains.properties.policy.properties.firewallBlockAllIncoming?.const], //boolean
        firewallEnableStealthMode: [schema.contains.properties.policy.properties.firewallEnableStealthMode?.const], //boolean,
        gatekeeperAllowedAppSource: [schema.contains.properties.policy.properties.gatekeeperAllowedAppSource?.const],

        assignments: fb.group({
            allUsers: [
                schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.some(
                    (res) => res?.properties?.['@odata.type']?.const === TargetGroups.allUsers,
                ),
            ],
            allDevices: [
                schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.some(
                    (res) => res?.properties?.['@odata.type']?.const === TargetGroups.allDevices,
                ),
            ],
            includedGroups: [getTargetGroupsIds(schema, TargetGroups.groupInclusion)],
            groupInclusion: [
                schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.some(
                    (res) => res?.properties?.['@odata.type']?.const === TargetGroups.groupInclusion,
                ),
            ],
        }),

        scheduledActionsForRule: fb.group({
            // TODO
            DeviceNonCompliance: fb.group({
                gracePeriodHours: [],
            }),
        }),
    });

    form.get('passwordRequired')?.valueChanges.subscribe((isPasswordRequired) => {
        const passwordExpirationDaysControl = form.get('passwordExpirationDays');
        const passwordMinimumLengthControl = form.get('passwordMinimumLength');
        const passwordPreviousPasswordBlockCountControl = form.get('passwordPreviousPasswordBlockCount');
        if (isPasswordRequired) {
            passwordExpirationDaysControl?.addValidators(Validators.required);
            passwordMinimumLengthControl?.addValidators(Validators.required);
            passwordPreviousPasswordBlockCountControl?.addValidators(Validators.required);
        } else {
            passwordExpirationDaysControl?.removeValidators(Validators.required);
            passwordMinimumLengthControl?.removeValidators(Validators.required);
            passwordPreviousPasswordBlockCountControl?.removeValidators(Validators.required);
        }
        passwordExpirationDaysControl?.updateValueAndValidity();
    });

    return form;
}

export function getTargetGroupsIds(schema, type): string[] {
    const items =
        schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.filter(
            (res) => res?.properties?.['@odata.type']?.const === type,
        ) ?? [];
    const result = items?.map((res) => res.properties.groupId.const);

    return result;
}

export function minmaxValidator(control: AbstractControl, otherControlName: string, type: 'min' | 'max'): any {
    const validate = (control: AbstractControl) => {
        if (!control?.parent || !control) {
            return null;
        }

        const otherControlValue = control.parent.get(otherControlName).value;
        if (!control.value || !otherControlValue) {
            return null;
        }

        if (type === 'min') {
            if (getComparableNumber(control.value) < getComparableNumber(otherControlValue)) {
                return { min: { requiredMin: otherControlValue } };
            }
        } else {
            if (getComparableNumber(control.value) > getComparableNumber(otherControlValue)) {
                return { max: { requiredMin: otherControlValue } };
            }
        }

        return null;
    };

    return validate;
}

function getComparableNumber(value: string) {
    return +value.replaceAll('.', '');
}
