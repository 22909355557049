import { updateExoAntiPhishPolicy } from 'projects/angular-clarity/src/app/stores/client/powershell/exo/anti-phish-policy/actions';
import { AntiPhishPolicy } from 'projects/angular-clarity/src/app/stores/client/powershell/exo/anti-phish-policy/model';
// export const IdentityName = 'Office365 AntiPhish Default';

export function Remediate(_tenant: string, data: AntiPhishPolicy[], schema: any) {
    const default_policy = data.find((res) => res.IsDefault);

    return updateExoAntiPhishPolicy({
        _tenant,
        data: {
            Identity: default_policy?.Identity,
            EnableFirstContactSafetyTips: schema?.contains?.properties?.EnableFirstContactSafetyTips?.const,
            AuthenticationFailAction: schema?.contains?.properties?.AuthenticationFailAction?.const,
            SpoofQuarantineTag: schema?.contains?.properties?.SpoofQuarantineTag?.const,
            EnableSpoofIntelligence: schema?.contains?.properties?.EnableSpoofIntelligence?.const,
            EnableViaTag: schema?.contains?.properties?.EnableViaTag?.const,
            EnableUnauthenticatedSender: schema?.contains?.properties?.EnableUnauthenticatedSender?.const,
        },
    });
}
