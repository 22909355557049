import { Classification, Meta } from "../../interfaces/meta.interface";

function hard_delete_description(fields: HardDeleteDescFields) {
    let str = '';
    if (!!fields.message) {
        str += `A message ${fields.message} was purged from the Recoverable Items folder ${fields.folder}.`
    } else {
        str += `A message was purged from the Recoverable Items folder ${fields.folder}.`
    }
    if (!!fields.risky_keyword) {
        str += `Contains risky word: ${fields.risky_keyword}.`
    }
    return str;
}

type HardDeleteFields = {
    risky_keyword: boolean
}

type HardDeleteDescFields = {
    message: string | undefined
    folder: string
    risky_keyword: string | undefined
}

const MailHardDeleteClx: Classification<HardDeleteFields>[] = [
    // TODO: dodgy keywords in subject (critical)
    {
        severity: 'critical',
        title: 'Message Purged from Group Mailbox (dodgy keyword in subject)',
        description: (fields: any) => hard_delete_description(fields),
        fields: {
            risky_keyword: [true]
        }
    },
    // hard delete.
    {
        severity: 'warning',
        title: 'Message Purged from Group Mailbox',
        description: (fields: any) => hard_delete_description(fields),
    }

]

// const HardDelete = {
//     info: {
//         title: 'Message purged from mailbox.',
//         description: 'A message was purged from the Recoverable Items folder (permanently deleted).',
//     },
//     extractor: (log: any) => {
//         let risky_keyword = false;
//         if (Array.isArray(log.AffectedItems) && log.AffectedItems.length > 0) {
//             risky_keyword = risky_words.some(word => log.AffectedItems[0]['Subject'].toLowerCase().includes(word))
//         }
//         return { risky_keyword };
//     },
//     description_extractor: (log: any) => {
//         return {
//             message: !!log.AffectedItems && !!log.AffectedItems[0]['Subject'] ? log.AffectedItems[0]['Subject'] : undefined,
//             folder: !!log.AffectedItems ? log.AffectedItems[0]['ParentFolder']['Path'] : undefined,
//             risky_keyword: !!log.AffectedItems && !!log.AffectedItems[0]['Subject'] ? risky_words.find(word => log.AffectedItems[0]['Subject'].toLowerCase().includes(word)) : undefined
//         }
//     },
//     classifications: MailHardDeleteClx
// }

const HardDelete: Meta<any> = {
    info: {
        title: 'Message Purged from Group Mailbox',
        description: 'A message was purged from the Recoverable Items folder (permanently deleted).',
    }
}

const operations: { [key: string]: Meta<HardDeleteFields, HardDeleteDescFields> } = {
    'HardDelete': HardDelete
}

export default operations;
