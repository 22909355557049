import { DirectoryRole } from '@microsoft/microsoft-graph-types-beta';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as RoleActions from './actions';
import { Status, initialStatus } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'directoryRoleTemplates';

export interface State extends EntityState<DirectoryRole>, Status {}

export const adapter: EntityAdapter<DirectoryRole> = createEntityAdapter();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(RoleActions.loadDirectoryRoleTemplatesSuccess, (state, action) =>
        adapter.setAll(action.roles, { ...state, loaded: true }),
    ),
    on(RoleActions.loadDirectoryRoleTemplatesFailure, (state, action) => ({
        ...state,
        loaded: false,
        error: action.error,
    })),
);

export function byDisplayName(state: State, displayName: string) {
    for (const id of state.ids) {
        if (state.entities[id].displayName === displayName) {
            return state.entities[id];
        }
    }
    return undefined;
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
