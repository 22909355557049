import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as Windows10CompliancePolicyActions from './actions';
import { PolicyAssignment } from './model';
import { initialStatus, Status } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'graphCompliancePolicyWindows10';

export interface State extends EntityState<PolicyAssignment>, Status {}

export const adapter: EntityAdapter<PolicyAssignment> = createEntityAdapter<PolicyAssignment>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    // load

    on(Windows10CompliancePolicyActions.loadCompliancePoliciesSuccess, (state, action) =>
        adapter.upsertMany(action.data, { ...state, loaded: true, error: null, updating: false, creating: false }),
    ),

    on(Windows10CompliancePolicyActions.reloadCompliancePoliciesSuccess, (state, action) =>
        adapter.upsertMany(action.data, { ...state, loaded: true, error: null, updating: false, creating: false }),
    ),

    on(Windows10CompliancePolicyActions.loadCompliancePoliciesFailure, (state, { error }) => ({
        ...state,
        error,
        loaded: false,
    })),

    // create
    on(Windows10CompliancePolicyActions.createCompliancePolicy, (state, action) => ({
        ...state,
        updating: true,
        error: null,
    })),

    on(Windows10CompliancePolicyActions.createCompliancePolicyFailure, (state, { error }) => ({
        ...state,
        error,
        updating: false,
    })),

    on(Windows10CompliancePolicyActions.createCompliancePolicyFailure, (state, { error }) => ({
        ...state,
        error,
        updating: false,
    })),

    //update
    on(Windows10CompliancePolicyActions.updateCompliancePolicy, (state, action) => ({
        ...state,
        updating: true,
        error: null,
    })),

    on(Windows10CompliancePolicyActions.updateCompliancePolicyFailure, (state, { error }) => ({
        ...state,
        error,
        updating: false,
    })),

    // delete
    on(Windows10CompliancePolicyActions.deleteCompliancePolicy, (state, action) => ({
        ...state,
        updating: true,
        error: null,
    })),

    on(Windows10CompliancePolicyActions.deleteCompliancePolicySuccess, (state, action) =>
        adapter.removeOne(action.id, { ...state, updating: false, creating: false, error: null }),
    ),

    on(Windows10CompliancePolicyActions.deleteCompliancePolicyFailure, (state, { error }) => ({
        ...state,
        error,
    })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
