import { createAction, props } from '@ngrx/store';
import { TemplateItem } from './../items/model';
import { SwayTemplate } from './model';

export const loadTemplates = createAction('[Templates/API] Load Templates', props<{ msp_id: string }>());

export const loadTemplatesSuccess = createAction(
    '[Templates/API] Load Templates Success',
    props<{ templates: SwayTemplate[] }>(),
);

export const loadTemplatesFailure = createAction('[Templates/API] Load Templates Failure', props<{ error: any }>());

export const createTemplate = createAction(
    '[Templates/API] Create Templates',
    props<{ msp_id: string; _type: 'tenant' | 'group'; name: string }>(),
);

export const createTemplateSuccess = createAction(
    '[Templates/API] Create Templates Success',
    props<{ template: SwayTemplate }>(),
);

export const createTemplateFailure = createAction('[Templates/API] Create Templates Failure', props<{ error: any }>());

export const updateTemplate = createAction(
    '[Templates/API] update Templates',
    props<{ msp_id: string; id: string; name: string }>(),
);

export const updateTemplateSuccess = createAction(
    '[Templates/API] update Templates Success',
    props<{ template: SwayTemplate }>(),
);

export const updateTemplateFailure = createAction('[Templates/API] update Templates Failure', props<{ error: any }>());

export const deleteTemplate = createAction(
    '[Templates/API] Delete Templates',
    props<{ msp_id: string; id: string; deleteBaselines: boolean }>(),
);

export const deleteTemplateSuccess = createAction('[Templates/API] Delete Templates Success', props<{ id: string }>());

export const deleteTemplateFailure = createAction('[Templates/API] Delete Templates Failure', props<{ error: any }>());

export const detachTemplate = createAction(
    '[Templates/API] Detach Templates',
    props<{ tenant_id; template_id: string; deleteBaselines: boolean; _type: 'tenant' | 'group'; group_id?: string }>(),
);

export const detachTemplateSuccess = createAction('[Templates/API] Detach Templates Success', props<{ id: string }>());

export const detachTemplateFailure = createAction('[Templates/API] Detach Templates Failure', props<{ error: any }>());

export const applyTemplate = createAction(
    '[Templates/API] Apply Template',
    props<{
        tenant_id: string;
        template_id: string;
        required_items: Array<Partial<TemplateItem>>;
        _type: 'group' | 'tenant';
        group_id?: string;
        ignored_items_ids?: Array<string>;
    }>(),
);

export const applyTemplateSuccess = createAction('[Templates/API] Apply Template Success');

export const applyTemplateFailure = createAction('[Templates/API] Apply Template Failure', props<{ error: any }>());
