import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FeaturesResourceMapper } from './features/features';
import { MethodsResourceMapper } from './features/methods';
import {
    FeaturePermissions,
    RoleAction,
    RoleFeature,
    Session,
    TenantAccess,
} from 'projects/angular-clarity/src/app/stores/root/octiga/session/model';

type httpMethod = 'get' | 'create' | 'post' | 'put' | 'delete';

// create, read, update, delete

@Injectable({
    providedIn: 'root',
})
export class RoleAccessService {
    constructor(private store: Store<any>) {}

    public mapAccess(tenantsAccess: TenantAccess, request: HttpRequest<unknown>): boolean {
        const method = request.method.toLocaleLowerCase() as httpMethod;
        const resource = request.url;

        const tenantId = request.headers.get('tenant-id');
        const mspId = request.headers.get('msp-id');

        if (tenantId) {
            const resourceFeature = this.getResourceFeature(tenantId, resource);

            const hasAccess = this.tenantHasAccess(tenantsAccess[tenantId], resourceFeature, method);

            return hasAccess;
        } else {
            const resourceFeature = this.getResourceFeature(mspId, resource);

            const hasAccess = this.tenantHasAccess(tenantsAccess[mspId], resourceFeature, method);

            return hasAccess;
        }
    }

    getResourceFeature(pathItem: string, resource: string): RoleFeature {
        const featureName = (Object.keys(FeaturesResourceMapper) as RoleFeature[]).find((key) => {
            const feature = FeaturesResourceMapper[key];

            return feature.find((urlFn) => urlFn(pathItem) === resource || resource.includes(urlFn(pathItem)));
        });

        return featureName;
    }

    userHaPermission(feature: RoleFeature, action: RoleAction, session: Session, scope: 'msp' | 'tenant'): boolean {
        let userFeaturesActions;
        if (scope === 'msp') {
            userFeaturesActions = session?.mspAccess?.[session.msp_id];
        } else {
            userFeaturesActions = session?.tenantAccess?.[session.clientId];
        }

        if (userFeaturesActions) {
            return userFeaturesActions[feature]?.includes(action);
        }

        return false;
    }

    private tenantHasAccess(
        tenantAccess: FeaturePermissions,
        resourceFeature: RoleFeature,
        method: httpMethod,
    ): boolean {
        const currentFeaturePermission = tenantAccess?.[resourceFeature]; // TODO, karim now
        return currentFeaturePermission?.includes(MethodsResourceMapper[method]);
    }
}
