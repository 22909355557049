import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
// eslint-disable-next-line import/no-unresolved
import { Action, TypedAction } from '@ngrx/store/src/models';
import { JSONSchema7 } from 'json-schema';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PermissionCheckService } from './../../../../services/permission-check.service';
import {
    updateExoMobileDeviceMailboxPolicyFailure,
    fetchMobileDeviceMailboxPolicy,
} from './../../../../stores/client/powershell/exo/mobile-device-mailbox-policy/actions';
import * as AntiPhishPolicy from './baselines/exo/AntiPhishPolicy';
import * as MailboxAuditLog from './baselines/exo/AuditDisabled';
import * as AutoForwardBlockTransportRule from './baselines/exo/AutoForwardBlockTransportRule';
import * as AutoForwarding from './baselines/exo/AutoForwardEnabled';
import * as CustomerLockbox from './baselines/exo/CustomerLockboxEnabled';
import * as DkimSigningConfig from './baselines/exo/DkimSigningConfig';
import * as HostedOutboundSpamFilterPolicy from './baselines/exo/HostedOutboundSpamFilterPolicy';
import * as MalwareFileTypesFilterPolicy from './baselines/exo/MalwareFileTypesFilterPolicy';
import * as MalwareInternalSenderFilterNotificationPolicy from './baselines/exo/MalwareInternalSenderFilterNotificationPolicy';
import * as MobileDeviceMailBoxPolicy from './baselines/exo/MobileDeviceMailboxPolicy';
import * as ModernAuth from './baselines/exo/OAuth2ClientProfileEnabled';
import * as PresetEOPProtectionPolicyRuleStandard from './baselines/exo/PresetEOPProtectionPolicyRuleStandard';
import * as PresetEOPProtectionPolicyRuleStrict from './baselines/exo/PresetEOPProtectionPolicyRuleStrict';
import * as SmtpAccess from './baselines/exo/SmtpClientAuthenticationDisabled';
import * as UnifiedAuditLog from './baselines/exo/UnifiedAuditLogIngestionEnabled';

// graph
import * as Windows10CompliancePolicy from './baselines/graph/compliance-policy/Windows10CompliancePolicy';
import * as MacOsCompliancePolicy from './baselines/graph/compliance-policy/MacOsCompliancePolicy';
import * as IOSCompliancePolicy from './baselines/graph/compliance-policy/IOSCompliancePolicy';
import * as MfaConditionalAccessPolicy from './baselines/graph/conditional-access-policy/AdminMFAConditionalAccessPolicy';
import * as CAPAppEnforcedRestrictionUnmanagedDevices from './baselines/graph/conditional-access-policy/CAPAppEnforcedRestrictionUnmanagedDevices';
import * as CAPAppProtectionPolicy from './baselines/graph/conditional-access-policy/CAPAppProtectionPolicy';
import * as CAPEnforceMFA from './baselines/graph/conditional-access-policy/CAPEnforceMFA';
import * as CAPLegacyAuth from './baselines/graph/conditional-access-policy/CAPLegacyAuth';
import * as CAPNoPersistentBrowsers from './baselines/graph/conditional-access-policy/CAPNoPersistentBrowsers';
import * as CAPRequireCompliantOrHybridAzureADJoinedDevice from './baselines/graph/conditional-access-policy/CAPRequireCompliantOrHybridAzureADJoinedDevice';
import * as CAPRestrictAzureAccess from './baselines/graph/conditional-access-policy/CAPRestrictAzureAccess';
import * as CAPRestrictMicrosoftAdminPortalAccess from './baselines/graph/conditional-access-policy/CAPRestrictMicrosoftAdminPortalAccess';
import * as CAPRiskySignin from './baselines/graph/conditional-access-policy/CAPRiskySignin';
import * as CAPSecuringSecurityInfoRegistration from './baselines/graph/conditional-access-policy/CAPSecuringSecurityInfoRegistration';
import * as CAPUnSupportedDeviceBlock from './baselines/graph/conditional-access-policy/CAPUnSupportedDeviceBlock';
import * as LegacyAuthConditionalAccessPolicy from './baselines/graph/conditional-access-policy/LegacyAuthConditionalAccessPolicy';
import * as DomainPasswordPolicy from './baselines/graph/DomainPasswordPolicy';
import * as SecurityDefaultsPolicy from './baselines/graph/SecurityDefaultsPolicy';
import * as SharepointStorageWarning from './baselines/graph/SharepointStorageWarning';
import * as LegacyAuthProtocolsEnabled from './baselines/spo/LegacyAuthProtocolsEnabled';
import * as PreventExternalUsersFromResharing from './baselines/spo/PreventExternalUsersFromResharing';
import * as RequireAnonymousLinksExpireInDays from './baselines/spo/RequireAnonymousLinksExpireInDays';
import * as SharepointInfectedFilesDownload from './baselines/spo/SharepointInfectedFilesDownload';
import { selectCompliancePolicyData } from './baselines/graph/compliance-policy';
import {
    createCompliancePolicyFailure,
    deleteCompliancePolicyFailure,
    loadCompliancePolicies,
    updateCompliancePolicyFailure,
} from 'projects/angular-clarity/src/app/stores/client/graph/compliance-policies/policies/actions';
import { environment } from 'projects/angular-clarity/src/environments/environment';
import { Status } from 'projects/angular-clarity/src/app/stores/status.interface';
import { loadDirectoryRoleTemplates } from 'projects/angular-clarity/src/app/stores/root/graph/directoryRoleTemplates/actions';
import {
    fetchSpoTenant,
    updateSpoTenantFailure,
} from 'projects/angular-clarity/src/app/stores/client/powershell/spo/tenant/actions';
import {
    createExoTransportRuleFailure,
    deleteExoTransportRuleFailure,
    loadExoTransportRules,
    updateExoTransportRuleFailure,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/transport-rule/actions';
import {
    fetchExoTransportConfig,
    updateExoTransportConfigFailure,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/transport-config/actions';
import {
    fetchExoRemoteDomain,
    updateExoRemoteDomainFailure,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/remote-domain/actions';
import {
    fetchExoOrganizationConfig,
    updateExoOrganizationConfigFailure,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/organization-config/actions';
import {
    loadMalwareFilterPolicy,
    updateExoMalwareFilterPolicyFailure,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/malware-filter-policy/actions';
import {
    fetchHostedOutboundSpamFilterPolicy,
    updateExoHostedOutboundSpamFilterPolicyFailure,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/hosted-outbound-spam-filter-policy/actions';
import {
    disableExoEOPProtectionPolicyFailure,
    enableExoEOPProtectionPolicyFailure,
    loadEOPProtectionPolicy,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/eop-protection-policy-rule/actions';
import {
    fetchDkimSigningConfig,
    updateDKIMSigningConfigFailure,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/dkim-signing-config/actions';
import {
    loadAntiPhishPolicy,
    updateExoAntiPhishPolicyFailure,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/anti-phish-policy/actions';
import {
    fetchExoAdminAuditLogConfig,
    updateExoAdminAuditLogConfigFailure,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/admin-audit-log-config/actions';
import { loadSPOSiteUsage } from 'projects/angular-clarity/src/app/stores/client/graph/spo/sharepoint-site/actions';
import {
    loadSecurityDefaultsPolicy,
    updateSecurityDefaultsFailure,
} from 'projects/angular-clarity/src/app/stores/client/graph/security-defaults/actions';
import { loadDomains, updateDomainFailure } from 'projects/angular-clarity/src/app/stores/client/graph/domains/actions';
import {
    createConditionalAccessPolicyFailure,
    deleteConditionalAccessPolicyFailure,
    loadConditionalAccessPolicy,
    updateConditionalAccessPolicyFailure,
} from 'projects/angular-clarity/src/app/stores/client/graph/conditional-access/actions';
import { client } from 'projects/angular-clarity/src/app/stores/client';
import { SERVICE_PLAN_KEY } from 'projects/angular-clarity/src/app/services/check-service-plans.service';

interface RegistryItem {
    tag: string; // spec tag
    component: any;
    createRemediationAction?: (
        _tenant: string,
        data: any,
        schema: JSONSchema7,
        metaData?: any,
        key?: string,
    ) => TypedAction<any> | Array<TypedAction<any>> | null;
    remediationActionSuccess?: TypedAction<any> | Array<TypedAction<any>> | null;
    remediationActionFailure?: Array<TypedAction<any>> | null;

    fetch_data: Array<Action>;
    select_data: Observable<any>;
    select_status: Observable<Status>;
    meta_data?: Observable<any>;
    meta_data_status?: Observable<Status>;
    scopes?: string[];
    href?: string;
    deprecated?: boolean;
    canUpdate?: boolean; // can update if there is a template,
    allowAutoRemediation: boolean;
    plans?: Array<SERVICE_PLAN_KEY | SERVICE_PLAN_KEY[]>; // nested array for OR plans
}

@Injectable({
    providedIn: 'root',
})
export class TenantSpecRegistryService {
    unDespatchedItems: Array<RegistryItem> = [];
    isInitialed = false;

    constructor(
        private store: Store<any>,
        private permissionCheck: PermissionCheckService,
    ) {}

    public init(_tenant: string): void {
        if (this.isInitialed) return;

        const reg_items = this.getAll(_tenant);
        this.dispatchItems(reg_items, _tenant);
    }

    dispatchItems(reg_items: RegistryItem[], _tenant): void {
        for (const item of reg_items) {
            const scopes = item.scopes;

            const hasPermissions = this.permissionCheck.hasPermissions(_tenant, scopes);
            // don't dispatch actions if tenant doesn't have the required permission;
            hasPermissions.pipe(filter((res) => !!res)).subscribe(() => {
                item.fetch_data.forEach((action) => {
                    this.store.dispatch(action);
                });
            });
        }
    }

    public get(tenant: string, tag: string): RegistryItem {
        const items = this.registry.map((fn) => fn(tenant));
        const item = items.find((item) => item.tag == tag);

        if (item === undefined) {
            if (environment.production) {
                throw new Error(`no component registered for spec: ${tag}`);
            } else {
                const item = {
                    // purpose of this is not to throw error when we have components that are not registered.
                    tag,
                    component: 'item is not registered',

                    select_data: of([]),
                    allowAutoRemediation: false,

                    fetch_data: [],

                    select_status: of({ loading: false, loaded: true, error: false, creating: false, updating: false }),
                };

                return item;
            }
        }

        return item;
    }

    public getAll(tenant: string): RegistryItem[] {
        return this.registry.map((fn) => fn(tenant));
    }

    private registry: Array<(tid: string) => RegistryItem> = [
        (tenant: string): RegistryItem => ({
            tag: 'OrgSMTP',
            component: SmtpAccess.BaselineComponent,
            select_data: this.store.pipe(select(client(tenant).powershell.exoTransportConfig.item)),
            fetch_data: [fetchExoTransportConfig({ _tenant: tenant })],
            select_status: this.store.pipe(select(client(tenant).powershell.exoTransportConfig.status)),
            createRemediationAction: SmtpAccess.Remediate,
            remediationActionFailure: [updateExoTransportConfigFailure],
            href: SmtpAccess.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'HostedOutboundSpamFilterPolicy',
            component: HostedOutboundSpamFilterPolicy.BaselineComponent,
            fetch_data: [fetchHostedOutboundSpamFilterPolicy({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.hostedOutboundSpamFilterPolicy.all)),
            canUpdate: true,
            select_status: this.store.pipe(select(client(tenant).powershell.hostedOutboundSpamFilterPolicy.status)),
            createRemediationAction: HostedOutboundSpamFilterPolicy.Remediate,
            remediationActionFailure: [updateExoHostedOutboundSpamFilterPolicyFailure],
            href: HostedOutboundSpamFilterPolicy.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'MobileDeviceMailboxPolicy',
            component: MobileDeviceMailBoxPolicy.BaselineComponent,
            fetch_data: [fetchMobileDeviceMailboxPolicy({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.mobileDeviceMailboxPolicy.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.mobileDeviceMailboxPolicy.status)),
            createRemediationAction: MobileDeviceMailBoxPolicy.Remediate,
            remediationActionFailure: [updateExoMobileDeviceMailboxPolicyFailure],
            href: MobileDeviceMailBoxPolicy.href,
            allowAutoRemediation: false,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'MalwareFileTypesFilterPolicy',
            component: MalwareFileTypesFilterPolicy.BaselineComponent,
            fetch_data: [loadMalwareFilterPolicy({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoMalwareFilterPolicy.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoMalwareFilterPolicy.status)),
            createRemediationAction: MalwareFileTypesFilterPolicy.Remediate,
            remediationActionFailure: [updateExoMalwareFilterPolicyFailure],
            href: MalwareFileTypesFilterPolicy.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'MalwareInternalSenderFilterNotificationPolicy',
            component: MalwareInternalSenderFilterNotificationPolicy.BaselineComponent,
            fetch_data: [loadMalwareFilterPolicy({ _tenant: tenant })],
            select_data: this.store.pipe(
                select(client(tenant).powershell.exoMalwareFilterPolicy.all),
                // make it case insensitive as ajv doesn't direct support case insensitive we can use pattern in ajv but it is complicated
                map((res) =>
                    res.map((item) => ({
                        ...item,
                        InternalSenderAdminAddress: item.InternalSenderAdminAddress?.toLocaleLowerCase(),
                    })),
                ),
            ),
            canUpdate: true,
            select_status: this.store.pipe(select(client(tenant).powershell.exoMalwareFilterPolicy.status)),
            createRemediationAction: MalwareInternalSenderFilterNotificationPolicy.Remediate,
            remediationActionFailure: [updateExoMalwareFilterPolicyFailure],
            href: MalwareInternalSenderFilterNotificationPolicy.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'PresetEOPProtectionPolicyRuleStrict',
            component: PresetEOPProtectionPolicyRuleStrict.BaselineComponent,
            fetch_data: [loadEOPProtectionPolicy({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoEOPProtectionPolicyRule.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoEOPProtectionPolicyRule.status)),
            createRemediationAction: PresetEOPProtectionPolicyRuleStrict.Remediate,
            remediationActionFailure: [enableExoEOPProtectionPolicyFailure, disableExoEOPProtectionPolicyFailure],
            href: PresetEOPProtectionPolicyRuleStrict.href,
            allowAutoRemediation: false,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'PresetEOPProtectionPolicyRuleStandard',
            component: PresetEOPProtectionPolicyRuleStandard.BaselineComponent,
            fetch_data: [loadEOPProtectionPolicy({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoEOPProtectionPolicyRule.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoEOPProtectionPolicyRule.status)),
            createRemediationAction: PresetEOPProtectionPolicyRuleStandard.Remediate,
            remediationActionFailure: [enableExoEOPProtectionPolicyFailure, disableExoEOPProtectionPolicyFailure],
            href: PresetEOPProtectionPolicyRuleStandard.href,
            allowAutoRemediation: false,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'AntiPhishPolicy',
            component: AntiPhishPolicy.BaselineComponent,
            fetch_data: [loadAntiPhishPolicy({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoAntiPhishPolicy.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoAntiPhishPolicy.status)),

            createRemediationAction: AntiPhishPolicy.Remediate,
            remediationActionFailure: [updateExoAntiPhishPolicyFailure],
            href: AntiPhishPolicy.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'OrgAutoForwarding',
            component: AutoForwarding.BaselineComponent,
            fetch_data: [fetchExoRemoteDomain({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoRemoteDomain.item)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoRemoteDomain.status)),
            createRemediationAction: AutoForwarding.Remediate,
            remediationActionFailure: [updateExoRemoteDomainFailure],
            href: AutoForwarding.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'OrgCustomerLockbox',
            component: CustomerLockbox.BaselineComponent,
            fetch_data: [fetchExoOrganizationConfig({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoOrganizationConfig.item)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoOrganizationConfig.status)),
            createRemediationAction: CustomerLockbox.Remediate,
            remediationActionFailure: [updateExoOrganizationConfigFailure],
            href: CustomerLockbox.href,
            plans: [['9f431833-0334-42de-a7dc-70aa40db46db']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'OrgModernAuth',
            component: ModernAuth.BaselineComponent,
            fetch_data: [fetchExoOrganizationConfig({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoOrganizationConfig.item)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoOrganizationConfig.status)),
            createRemediationAction: ModernAuth.Remediate,
            remediationActionFailure: [updateExoOrganizationConfigFailure],
            href: ModernAuth.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'OrgMailboxAuditLog',
            component: MailboxAuditLog.BaselineComponent,
            fetch_data: [fetchExoOrganizationConfig({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoOrganizationConfig.item)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoOrganizationConfig.status)),
            createRemediationAction: MailboxAuditLog.Remediate,
            remediationActionFailure: [updateExoOrganizationConfigFailure],
            href: MailboxAuditLog.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'DkimSigningConfig',
            component: DkimSigningConfig.BaselineComponent,
            fetch_data: [fetchDkimSigningConfig({ _tenant: tenant }), loadDomains({ _tenant: tenant })],
            select_data: combineLatest([
                this.store.pipe(select(client(tenant).powershell.exoDkimSigningConfig.item)),
                this.store.pipe(
                    select(client(tenant).graph.domains.all),
                    map((domains) => domains.filter((domain) => !!domain.isVerified).find((res) => res.isDefault)),
                ),
            ]).pipe(
                map(([dkimConfig, defaultDomain]) => {
                    if (!defaultDomain) {
                        return [];
                    } else {
                        const dkimSettingForDefaultDomain = dkimConfig.filter(
                            (res) => res.Id?.toLocaleLowerCase() === defaultDomain.id?.toLocaleLowerCase(),
                        );
                        console.log(dkimSettingForDefaultDomain, 'karim');
                        return dkimSettingForDefaultDomain.map((item) => ({ ...item, IsDefault: true }));
                    }
                }),
            ),

            select_status: combineLatest([
                this.store.pipe(select(client(tenant).powershell.exoDkimSigningConfig.status)),
                this.store.pipe(select(client(tenant).graph.domains.status)),
            ]).pipe(
                map(([dkimStatus, domain_status]) => ({
                    ...dkimStatus,
                    loaded: domain_status.loaded && dkimStatus.loaded,
                })),
            ),
            meta_data: this.store.pipe(
                select(client(tenant).graph.domains.all),
                map((domains) => domains.filter((domain) => !!domain.isVerified).find((res) => res.isDefault)),
            ),
            meta_data_status: this.store.pipe(select(client(tenant).graph.domains.status)),
            createRemediationAction: DkimSigningConfig.Remediate,
            remediationActionFailure: [updateDKIMSigningConfigFailure],
            href: DkimSigningConfig.href,
            allowAutoRemediation: false,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'OrgUnifiedAuditLog',
            component: UnifiedAuditLog.BaselineComponent,
            fetch_data: [fetchExoAdminAuditLogConfig({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoAdminAuditLogConfig.item)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoAdminAuditLogConfig.status)),
            allowAutoRemediation: false,

            createRemediationAction: UnifiedAuditLog.Remediate,
            remediationActionFailure: [updateExoAdminAuditLogConfigFailure],
            href: UnifiedAuditLog.href,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'OrgExternalResharing',
            component: PreventExternalUsersFromResharing.BaselineComponent,
            fetch_data: [fetchSpoTenant({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.spoTenant.item)),
            select_status: this.store.pipe(select(client(tenant).powershell.spoTenant.status)),
            createRemediationAction: PreventExternalUsersFromResharing.Remediate,
            remediationActionFailure: [updateSpoTenantFailure],
            href: PreventExternalUsersFromResharing.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'SharepointInfectedFilesDownload',
            component: SharepointInfectedFilesDownload.BaselineComponent,
            fetch_data: [fetchSpoTenant({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.spoTenant.item)),
            select_status: this.store.pipe(select(client(tenant).powershell.spoTenant.status)),
            createRemediationAction: SharepointInfectedFilesDownload.Remediate,
            remediationActionFailure: [updateSpoTenantFailure],
            href: SharepointInfectedFilesDownload.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'OrgLinksExpiry',
            component: RequireAnonymousLinksExpireInDays.BaselineComponent,
            fetch_data: [fetchSpoTenant({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.spoTenant.item)),
            select_status: this.store.pipe(select(client(tenant).powershell.spoTenant.status)),
            createRemediationAction: RequireAnonymousLinksExpireInDays.Remediate,
            remediationActionFailure: [updateSpoTenantFailure],
            href: RequireAnonymousLinksExpireInDays.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'LegacyAuthProtocolsEnabled',
            component: LegacyAuthProtocolsEnabled.BaselineComponent,
            fetch_data: [fetchSpoTenant({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.spoTenant.item)),
            select_status: this.store.pipe(select(client(tenant).powershell.spoTenant.status)),
            createRemediationAction: LegacyAuthProtocolsEnabled.Remediate,
            remediationActionFailure: [updateSpoTenantFailure],
            href: LegacyAuthProtocolsEnabled.href,
            allowAutoRemediation: true,
        }),

        // graph
        (tenant: string): RegistryItem => ({
            tag: 'DomainPasswordPolicy',
            component: DomainPasswordPolicy.BaselineComponent,
            fetch_data: [loadDomains({ _tenant: tenant })],

            select_data: this.store.pipe(
                select(client(tenant).graph.domains.all),
                map((domains) => domains.filter((domain) => !!domain.isVerified && !!domain.isRoot)),
            ),

            select_status: this.store.select(client(tenant).graph.domains.status),
            createRemediationAction: DomainPasswordPolicy.Remediate,
            remediationActionFailure: [updateDomainFailure],
            scopes: ['Domain.ReadWrite.All', 'Directory.Read.All'],
            href: DomainPasswordPolicy.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'MfaConditionalAccessPolicy',
            component: MfaConditionalAccessPolicy.BaselineComponent,
            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant }), loadDirectoryRoleTemplates({ msp: tenant })],
            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),
            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),

            meta_data: this.store.pipe(select(client(tenant).graph.users.all)),

            canUpdate: true,
            deprecated: true,
            meta_data_status: this.store.select(client(tenant).graph.users.status),
            createRemediationAction: MfaConditionalAccessPolicy.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],
            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: MfaConditionalAccessPolicy.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPEnforceMFA',
            component: CAPEnforceMFA.BaselineComponent,
            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant }), loadDirectoryRoleTemplates({ msp: tenant })],
            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),
            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),

            meta_data: this.store.pipe(select(client(tenant).graph.users.all)),
            canUpdate: true,
            meta_data_status: this.store.select(client(tenant).graph.users.status),
            createRemediationAction: CAPEnforceMFA.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],

            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: CAPEnforceMFA.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPNoPersistentBrowsers',
            component: CAPNoPersistentBrowsers.BaselineComponent,
            canUpdate: true,
            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),
            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),
            createRemediationAction: CAPNoPersistentBrowsers.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],
            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: CAPNoPersistentBrowsers.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPUnSupportedDeviceBlock',
            component: CAPUnSupportedDeviceBlock.BaselineComponent,
            canUpdate: true,

            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],

            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),

            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),
            createRemediationAction: CAPUnSupportedDeviceBlock.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],

            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: CAPUnSupportedDeviceBlock.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: false,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPRestrictAzureAccess',
            component: CAPRestrictAzureAccess.BaselineComponent,
            canUpdate: true,

            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],

            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),

            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),
            createRemediationAction: CAPRestrictAzureAccess.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],

            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: CAPRestrictAzureAccess.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPRestrictMicrosoftAdminPortalAccess',
            component: CAPRestrictMicrosoftAdminPortalAccess.BaselineComponent,
            canUpdate: true,

            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],

            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),

            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),
            createRemediationAction: CAPRestrictMicrosoftAdminPortalAccess.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],

            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: CAPRestrictMicrosoftAdminPortalAccess.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPAppProtectionPolicy',
            component: CAPAppProtectionPolicy.BaselineComponent,
            canUpdate: true,

            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],

            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),

            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),
            createRemediationAction: CAPAppProtectionPolicy.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],

            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: CAPAppProtectionPolicy.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPSecuringSecurityInfoRegistration',
            component: CAPSecuringSecurityInfoRegistration.BaselineComponent,
            canUpdate: true,

            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],

            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),

            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),
            createRemediationAction: CAPSecuringSecurityInfoRegistration.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],
            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: CAPSecuringSecurityInfoRegistration.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPAppEnforcedRestrictionUnmanagedDevices',
            component: CAPAppEnforcedRestrictionUnmanagedDevices.BaselineComponent,
            canUpdate: true,

            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],

            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),

            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),
            createRemediationAction: CAPAppEnforcedRestrictionUnmanagedDevices.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],

            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: CAPAppEnforcedRestrictionUnmanagedDevices.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPRequireCompliantOrHybridAzureADJoinedDevice',
            component: CAPRequireCompliantOrHybridAzureADJoinedDevice.BaselineComponent,
            canUpdate: true,

            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],

            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),

            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),
            createRemediationAction: CAPRequireCompliantOrHybridAzureADJoinedDevice.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],
            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: CAPRequireCompliantOrHybridAzureADJoinedDevice.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPRiskySignin',
            component: CAPRiskySignin.BaselineComponent,
            canUpdate: true,

            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],

            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),

            select_status: this.store.select(client(tenant).graph.conditionalAccessPolicy.status),
            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],

            createRemediationAction: CAPRiskySignin.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],
            href: CAPRiskySignin.href,
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'LegacyAuthConditionalAccessPolicy',
            component: LegacyAuthConditionalAccessPolicy.BaselineComponent,
            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),
            select_status: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.status)),
            canUpdate: true,
            deprecated: true,
            createRemediationAction: LegacyAuthConditionalAccessPolicy.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],
            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: LegacyAuthConditionalAccessPolicy.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'CAPLegacyAuth',
            component: CAPLegacyAuth.BaselineComponent,
            fetch_data: [loadConditionalAccessPolicy({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.all)),
            select_status: this.store.pipe(select(client(tenant).graph.conditionalAccessPolicy.status)),
            canUpdate: true,
            createRemediationAction: CAPLegacyAuth.Remediate,
            remediationActionFailure: [
                createConditionalAccessPolicyFailure,
                updateConditionalAccessPolicyFailure,
                deleteConditionalAccessPolicyFailure,
            ],
            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess', 'Application.Read.All'],
            href: CAPLegacyAuth.href,
            plans: [['41781fb2-bc02-4b7c-bd55-b576c07bb09d', 'eec0eb4f-6444-4f95-aba0-50c24d67f998']],
            allowAutoRemediation: true,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'SecurityDefaultsPolicy',
            component: SecurityDefaultsPolicy.BaselineComponent,
            fetch_data: [loadSecurityDefaultsPolicy({ _tenant: tenant })],
            select_data: this.store.select(client(tenant).graph.securityDefaults.item),
            select_status: this.store.select(client(tenant).graph.securityDefaults.status),
            createRemediationAction: SecurityDefaultsPolicy.Remediate,
            remediationActionFailure: [updateSecurityDefaultsFailure],
            scopes: ['Policy.Read.All', 'Policy.ReadWrite.ConditionalAccess'],
            href: SecurityDefaultsPolicy.href,
            allowAutoRemediation: false,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'SharepointStorageWarning',
            component: SharepointStorageWarning.BaselineComponent,
            fetch_data: [loadSPOSiteUsage({ _tenant: tenant })],
            select_data: this.store.select(client(tenant).graph.sharepointSiteDetails.all),
            select_status: this.store.select(client(tenant).graph.sharepointSiteDetails.status),
            href: SharepointStorageWarning.href,
            canUpdate: true,
            allowAutoRemediation: false,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'Windows10CompliancePolicy',
            component: Windows10CompliancePolicy.BaselineComponent,

            select_data: selectCompliancePolicyData(this.store, tenant, 'Windows10CompliancePolicy'),

            fetch_data: [loadCompliancePolicies({ _tenant: tenant })],

            select_status: this.store.pipe(select(client(tenant).graph.compliancePolicies.status)),

            plans: [['c1ec4a95-1f05-45b3-a911-aa3fa01094f5', '882e1d05-acd1-4ccb-8708-6ee03664b117']],

            scopes: ['DeviceManagementConfiguration.ReadWrite.All'],
            allowAutoRemediation: true,
            createRemediationAction: Windows10CompliancePolicy.Remediate,

            remediationActionFailure: [
                createCompliancePolicyFailure,
                updateCompliancePolicyFailure,
                deleteCompliancePolicyFailure,
            ],

            href: Windows10CompliancePolicy.href,
        }),

        (tenant: string): RegistryItem => ({
            tag: 'MacOsCompliancePolicy',
            component: MacOsCompliancePolicy.BaselineComponent,

            select_data: selectCompliancePolicyData(this.store, tenant, 'MacOsCompliancePolicy'),

            fetch_data: [loadCompliancePolicies({ _tenant: tenant })],
            select_status: this.store.pipe(select(client(tenant).graph.compliancePolicies.status)),
            plans: [['c1ec4a95-1f05-45b3-a911-aa3fa01094f5', '882e1d05-acd1-4ccb-8708-6ee03664b117']],

            scopes: ['DeviceManagementConfiguration.ReadWrite.All'],
            allowAutoRemediation: true,
            createRemediationAction: MacOsCompliancePolicy.Remediate,

            remediationActionFailure: [
                createCompliancePolicyFailure,
                updateCompliancePolicyFailure,
                deleteCompliancePolicyFailure,
            ],

            href: MacOsCompliancePolicy.href,
        }),

        (tenant: string) => ({
            tag: 'IOSCompliancePolicy',
            component: IOSCompliancePolicy.BaselineComponent,

            select_data: selectCompliancePolicyData(this.store, tenant, 'IOSCompliancePolicy'),

            fetch_data: [loadCompliancePolicies({ _tenant: tenant })],
            select_status: this.store.pipe(select(client(tenant).graph.compliancePolicies.status)),
            plans: [['c1ec4a95-1f05-45b3-a911-aa3fa01094f5', '882e1d05-acd1-4ccb-8708-6ee03664b117']],

            scopes: ['DeviceManagementConfiguration.ReadWrite.All'],
            allowAutoRemediation: true,
            createRemediationAction: IOSCompliancePolicy.Remediate,

            remediationActionFailure: [
                createCompliancePolicyFailure,
                updateCompliancePolicyFailure,
                deleteCompliancePolicyFailure,
            ],

            href: IOSCompliancePolicy.href,
        }),

        (tenant: string) => ({
            tag: 'AutoForwardBlockTransportRule',
            component: AutoForwardBlockTransportRule.BaselineComponent,

            select_data: this.store.pipe(
                select(client(tenant).powershell.exoTransportRules.all),
                map((items) =>
                    items.filter(
                        (res) => res.Identity === AutoForwardBlockTransportRule.BlockAutoForwardingIdentityName,
                    ),
                ),
            ),

            meta_data: this.store.pipe(select(client(tenant).powershell.exoTransportRules.all)),

            fetch_data: [loadExoTransportRules({ _tenant: tenant })],
            select_status: this.store.pipe(select(client(tenant).powershell.exoTransportRules.status)),

            meta_data_status: this.store.pipe(select(client(tenant).powershell.exoTransportRules.status)),
            createRemediationAction: AutoForwardBlockTransportRule.Remediate,
            remediationActionFailure: [
                deleteExoTransportRuleFailure,
                createExoTransportRuleFailure,
                updateExoTransportRuleFailure,
            ],
            href: AutoForwardBlockTransportRule.href,
            allowAutoRemediation: true,
        }),
    ];
}
