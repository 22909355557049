import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, distinct, expand, map, mergeMap, reduce, take } from 'rxjs/operators';
import { loadGeoJson, loadGeoJsonFailure, loadGeoJsonSuccess } from './actions';
import { MspAjaxService } from 'projects/angular-clarity/src/app/services/ajax/msp-ajax.service';

const total: number = 111521;

@Injectable()
export class GeoJsonEffects {
    loadGeoJson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadGeoJson),
            distinct((action) => action.msp_id),
            take(1),
            mergeMap(() =>
                this.ajax.get('https://angular-clarity.s3.eu-west-1.amazonaws.com/countries_compress.geojson').pipe(
                    map((data) =>
                        loadGeoJsonSuccess({
                            data,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            loadGeoJsonFailure({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
    ) {}
}
