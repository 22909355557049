export * from './IOSCompliancePolicy.schema';
export * from './IOSCompliancePolicy.form';

export const tooltips = {
    managedEmailProfileRequired:
        'A managed email account is required. If the user already has an email account on the device, the email account must be removed so Intune can set one up correctly. If no email account exists on the device, the user should contact the IT administrator to configure a managed email account.',
    securityBlockJailbrokenDevices:
        'Prevent jailbroken devices to have corporate access (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    deviceThreatProtectionRequiredSecurityLevel:
        'Select the maximum allowed device threat level for devices evaluated by your connected Threat Defense services. Devices which exceed this threat level get marked as noncompliant. (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    osMinimumVersion:
        'Select the oldest OS version a device can have. (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    osMaximumVersion:
        'Select the newest OS version a device can have. (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    osMinimumBuildVersion:
        'Enter the oldest OS build version a device can have. For example: 20E252 If you want to set an Apple Rapid Security Response update as the minimum OS build, enter the supplemental build version. For example: 20E772520a (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    osMaximumBuildVersion:
        'Enter the newest OS build version a device can have. For example: 20E252 If you want to set an Apple Rapid Security Response update as the maximum OS build, enter the supplemental build version. For example: 20E772520a (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    advancedThreatProtectionRequiredSecurityLevel:
        'Select the maximum allowed machine risk score for devices evaluated by Microsoft Defender for Endpoint. Devices which exceed this score get marked as noncompliant.',
    passcodeRequired:
        'This setting specifies whether to require users to enter a password before access is granted to information on their mobile devices. Recommended value: Require',
    passcodeBlockSimple:
        'This setting specifies whether to block mobile devices from using simple password sequences, such as 1234 or 1111. Recommended Value: Block (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    passcodeMinimumLength:
        'This setting specifies the minimum number of digits or characters in the password. Recommended Value: 4 (4-14) (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    passcodeRequiredType:
        'This setting specifies whether passwords are allowed to be comprised only of numeric characters, or whether they must contain characters other than numbers. Recommendations: Required password type: Alphanumeric, Minimum number of character sets: 1 (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    passcodeMinimumCharacterSetCount:
        'Minimum number of non-alphanumeric characters (like #, %, !, etc.) that the password must contain.',
    passcodeMinutesOfInactivityBeforeScreenTimeout:
        "Maximum minutes after screen lock before password is required. Immediately recommended. Ignored by device if new time is longer than what's currently set on device. (This compliance check is supported for devices with OS versions iOS 8.0 and above)",
    passcodeMinutesOfInactivityBeforeLock:
        "Maximum minutes of inactivity until screen locks. Ignored by device if new time is longer than what's currently set on device. If set to Immediately, devices will use the minimum possible value per device.",
    passcodeExpirationDays:
        'This setting specifies the number of days after which a device password must be changed. Recommended value: 41 (1-65535) (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    passcodePreviousPasscodeBlockCount:
        'This setting specifies the number of recent passwords that cannot be reused. Recommended value: 5 (1-24) (This compliance check is supported for devices with OS versions iOS 8.0 and above)',
    restrictedApps:
        'Add bundle IDs for apps that should be restricted. A device that has at least one app installed which is found on the restricted apps list will be marked as non-compliant.',
};
