import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConditionalAccessPolicy, User, Group, DirectoryRole } from '@microsoft/microsoft-graph-types-beta';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { ErrorObject } from 'ajv';
import { ManageItemsWrapperDialogComponent } from '../manage-items-wrapper-dialog/manage-items-wrapper-dialog.component';
import { RowType, CapItem } from '../model';

interface Config {
    title: string;
    allListTitle: string;
    desiredListTitle: string;
    allListComparativeTitle: string;
    type: RowType;
}

@Component({
    selector: 'sway-cap-exclude-items',
    templateUrl: './cap-exclude-items.component.html',
    styleUrls: ['./cap-exclude-items.component.scss'],
})
export class CapExcludeItemsComponent implements OnInit {
    @Input() readOnly;
    @Input() title: string;
    @Input() form: FormGroup;
    @Input() override: string[];
    @ViewChild('excludedRolesRef') excludedRolesRef;

    excludeRolesDialogConfig = {
        title: 'Roles',
        allListTitle: 'Available roles',
        desiredListTitle: 'Excluded roles in baseline',
        allListComparativeTitle: 'Current tenant roles',
        type: RowType.excludeRoles,
    };

    excludeUsersDialogConfig = {
        title: 'Users',
        allListTitle: 'Available users',
        desiredListTitle: 'Excluded users in baseline',
        allListComparativeTitle: 'Current tenant users',
        type: RowType.excludeUsers,
    };

    excludeGroupsDialogConfig = {
        title: 'Groups',
        allListTitle: 'Available groups',
        desiredListTitle: 'Excluded groups in baseline',
        allListComparativeTitle: 'Current tenant groups',
        type: RowType.excludeGroups,
    };

    errors = [];

    @Input() usersLookup: User[]; // TODO change
    @Input() groupsLookup: Group[]; // TODO change
    @Input() rolesLookup: DirectoryRole[]; // TODO change

    @Input() errors$: BehaviorSubject<ErrorObject<string, Record<string, any>, unknown>[]>;

    @Input() policy: ConditionalAccessPolicy;
    excludedRoles: DirectoryRole[];

    constructor(private dialog: MatDialog) {}

    ngOnInit(): void {
        this.errors$.subscribe((errors) => {
            const excludeRoles = !!errors.find(
                (res) =>
                    res.instancePath.includes('users/excludeRoles') ||
                    res.schemaPath.includes('properties/excludeRoles'),
            );
            const excludeUsers = !!errors.find(
                (res) =>
                    res.instancePath.includes('users/excludeUsers') ||
                    res.schemaPath.includes('properties/excludeUsers'),
            );
            const excludeGroups = !!errors.find(
                (res) =>
                    res.instancePath.includes('users/excludeGroups') ||
                    res.schemaPath.includes('properties/excludeGroups'),
            );
            const excludeGuestsOrExternalUsers = !!errors.find(
                (res) =>
                    res.instancePath.includes('users/excludeGuestsOrExternalUsers') ||
                    res.schemaPath.includes('properties/excludeGuestsOrExternalUsers'),
            );

            this.errors = [
                { type: 'excludeUsers', value: excludeUsers },
                { type: 'excludeRoles', value: excludeRoles },
                { type: 'excludeGroups', value: excludeGroups },
                { type: 'excludeGuestsOrExternalUsers', value: excludeGuestsOrExternalUsers },
            ].filter((res) => !!res.value);
        });
    }

    viewExcludedRoles() {
        const roles = this.form.get('excludeRoles').value;
        if (roles?.length > 0) {
            this.excludedRoles = this.rolesLookup.filter((role) => roles.find((res) => res === role.id));

            this.dialog.open(this.excludedRolesRef, {
                maxHeight: '80vh',
                autoFocus: false,
            });
        }
    }

    manageItems(type: 'excludeUsers' | 'excludeGroups' | 'excludeRoles') {
        let lookup: any = this.usersLookup;

        if (type === 'excludeGroups') {
            lookup = this.groupsLookup;
        } else if (type === 'excludeRoles') {
            lookup = this.rolesLookup;
        }

        let config: Config = this.excludeUsersDialogConfig;

        if (type === 'excludeGroups') {
            config = this.excludeGroupsDialogConfig;
        } else if (type === 'excludeRoles') {
            config = this.excludeRolesDialogConfig;
        }

        const policyItems = this.policy?.conditions?.users[type] || [];

        const allItems: CapItem[] = lookup.map((res) => {
            return {
                displayName: res.displayName,
                id: res.id,
                inBaselineSchema: !!this.form.get(type).value?.some((b_role) => b_role === res.id),
                inPolicy: !!policyItems?.some((b_role) => b_role === res.id),
            };
        });

        const dialogRef = this.dialog.open(ManageItemsWrapperDialogComponent, {
            data: {
                allItems: allItems,
                ...config,
            },
            maxHeight: '90vh',
            width: '70%',
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((res: CapItem[]) => {
                if (res instanceof Array) {
                    const schemaItems = res.filter((res) => res.inBaselineSchema) || [];

                    this.form.get(type).setValue(schemaItems.map((res) => res.id));
                }
            });
    }

    get excludeUserSettings() {
        return this.form.get('excludeUserSettings').value;
    }

    get excludeUserSettingsController(): FormGroup {
        return this.form.get('excludeUserSettings') as FormGroup;
    }

    get excludeUsers(): string[] {
        return this.form.get('excludeUsers').value || [];
    }

    get excludeGuestsOrExternalUsersController(): FormControl {
        return this.form.get('excludeGuestsOrExternalUsers') as FormControl;
    }
}
