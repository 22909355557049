import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import * as actions from './actions';
import { ASN } from './model';
import { MspAjaxService } from 'projects/angular-clarity/src/app/services/ajax/msp-ajax.service';

@Injectable()
export class MspAsnEffects {
    loadASNs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadASNs),
            distinct((action) => action.type),
            mergeMap(() =>
                this.get().pipe(
                    map(({ records }) => actions.loadASNsSuccess({ asns: records })),
                    catchError((error) => of(actions.loadASNsFailure({ error }))),
                ),
            ),
        ),
    );

    createASN$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createASN),
            mergeMap(({ asn }) =>
                this.post(asn).pipe(
                    map((asn) => actions.createAsnSuccess({ asn })),
                    catchError((error) => of(actions.createASNFailure({ error }))),
                ),
            ),
        ),
    );

    deleteASN$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteASN),
            mergeMap(({ id }) =>
                this.delete(id).pipe(
                    map(({ id }) => actions.deleteAsnSuccess({ id })),
                    catchError((error) => of(actions.deleteAsnFailure({ id, error }))),
                ),
            ),
        ),
    );

    get() {
        return this.ajax.get<{ records: ASN[] }>('/api/octiga/v2/msp/asn');
    }

    post(body: Partial<ASN>) {
        return this.ajax.post<ASN>('/api/octiga/v2/msp/asn', body);
    }

    delete(id: string) {
        return this.ajax.delete<ASN>(`/api/octiga/v2/msp/asn/${id}`);
    }

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
    ) {}
}
