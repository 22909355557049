export const fraud_ip = 'It originated from a fraudulent IP.'
export const fraud_ip_in_country = 'It originated from a fraudulent IP that is in your allowed countries.'
export const not_in_allowed_ip = 'It originated from an IP that is not in your allowed IPs.'
export const not_in_allowed_country = 'It originated from an IP that is not in your allowed countries.'
export const allowed_region = 'It originated from an IP in your allowed region.';
export const allowed_ip = 'It originated from an IP in your allowed IP list.'
export const allowed_asn = 'It originated from an IP in your allowed ASN list.'
export const fraud_ip_not_in_allowed_list = 'It originated from a fraudulent IP that not on your allowed list.'
export const fraud_ip_in_region = 'It originated from a fraudulent IP that is in your allowed region.'
export const fraud_ip_in_ip = 'It originated from a fraudulent IP that is in your allowed IP list.';
export const fraud_ip_in_asn = 'It originated from a fraudulent IP that is in your allowed ASN list.';
export const is_microsoft_activity = 'It is a Microsoft system operation event, with the IP address being the server handling the synchronization.'
export const user_not_in_org = 'The account used is not part of your organisation.'
export const not_in_allowed_asn_ip_region_country = 'It originated from an IP that is not in your allowed ASN, IPs, Region or Country.'
