import { getDesiredAssignmentsSchema } from '../generic';
import { MacOsCompliancePolicySchema } from './model';

export const updateSchema = (formValue: any, schema: MacOsCompliancePolicySchema): any => {
    schema.contains.properties.policy.properties.displayName.const = formValue.displayName;
    schema.contains.properties.policy.properties.description.const = formValue.description;

    schema.contains.properties.policy.properties.systemIntegrityProtectionEnabled.const =
        formValue.systemIntegrityProtectionEnabled; // boolean

    // device properties
    if (formValue.osMinimumVersion) {
        schema.contains.properties.policy.properties.osMinimumVersion.const = formValue.osMinimumVersion;
        schema.contains.properties.policy.properties.osMinimumVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.osMinimumVersion = { type: 'null' };
    }

    if (formValue.osMaximumVersion) {
        schema.contains.properties.policy.properties.osMaximumVersion.const = formValue.osMaximumVersion;
        schema.contains.properties.policy.properties.osMaximumVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.osMaximumVersion = { type: 'null' };
    }

    if (formValue.osMinimumBuildVersion) {
        schema.contains.properties.policy.properties.osMinimumBuildVersion.const = formValue.osMinimumBuildVersion;
        schema.contains.properties.policy.properties.osMinimumBuildVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.osMinimumBuildVersion = { type: 'null' };
    }

    if (formValue.osMaximumBuildVersion) {
        schema.contains.properties.policy.properties.osMaximumBuildVersion.const = formValue.osMaximumBuildVersion;
        schema.contains.properties.policy.properties.osMaximumBuildVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.osMaximumBuildVersion = { type: 'null' };
    }

    // system security
    schema.contains.properties.policy.properties.passwordRequired.const = formValue.passwordRequired; // boolean // check

    if (formValue.passwordRequired) {
        schema.contains.properties.policy.properties.passwordBlockSimple = {
            type: 'boolean',
            const: formValue.passwordBlockSimple ?? false,
        }; // boolean
        schema.contains.properties.policy.properties.passwordRequiredType = {
            type: 'string',
            const: formValue.passwordRequiredType,
        }; // string
        schema.contains.properties.policy.properties.passwordMinimumLength = {
            type: 'number',
            const: formValue.passwordMinimumLength,
        }; // number

        schema.contains.properties.policy.properties.passwordMinutesOfInactivityBeforeLock = {
            type: 'number',
            const: formValue.passwordMinutesOfInactivityBeforeLock,
        }; // number

        schema.contains.properties.policy.properties.passwordExpirationDays = {
            type: 'number',
            const: formValue.passwordExpirationDays,
        };
        schema.contains.properties.policy.properties.passwordPreviousPasswordBlockCount = {
            type: 'number',
            const: formValue.passwordPreviousPasswordBlockCount,
        }; // number

        if (formValue.passwordMinimumCharacterSetCount !== 'notConfigured') {
            schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount = {
                type: 'number',
                const: formValue.passwordMinimumCharacterSetCount,
            }; // number
        } else {
            schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount = {
                type: 'null',
            };
        }

        if (formValue.passwordMinimumCharacterSetCount !== 'notConfigured') {
            schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount = {
                type: 'number',
                const: formValue.passwordMinimumCharacterSetCount,
            }; // number
        } else {
            schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount = {
                type: 'null',
            };
        }
    } else {
        delete schema.contains.properties.policy.properties.passwordBlockSimple;
        delete schema.contains.properties.policy.properties.passwordMinimumLength;
        delete schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount;
        delete schema.contains.properties.policy.properties.passwordMinutesOfInactivityBeforeLock;
        delete schema.contains.properties.policy.properties.passwordExpirationDays;
        delete schema.contains.properties.policy.properties.passwordPreviousPasswordBlockCount;
    }

    schema.contains.properties.policy.properties.storageRequireEncryption.const = formValue.storageRequireEncryption; // boolean

    schema.contains.properties.policy.properties.firewallEnabled.const = formValue.firewallEnabled; //boolean
    schema.contains.properties.policy.properties.firewallBlockAllIncoming.const = formValue.firewallBlockAllIncoming; //boolean
    schema.contains.properties.policy.properties.firewallEnableStealthMode.const = formValue.firewallEnableStealthMode; //boolean,
    schema.contains.properties.policy.properties.gatekeeperAllowedAppSource.const =
        formValue.gatekeeperAllowedAppSource;

    schema.contains.properties.assignments = getDesiredAssignmentsSchema(formValue);

    return schema;
};
