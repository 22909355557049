import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RefreshSnackBarComponent } from '../components/refresh-snack-bar/refresh-snack-bar';
import { MspAjaxService } from './ajax/msp-ajax.service';
import { environment } from '../../environments/environment';

function minutes(num: number) {
    return 1000 * 60 * num;
}

@Injectable({
    providedIn: 'root',
})
export class VersionCheckService {
    private currentVersion: string;

    constructor(
        private ajax: MspAjaxService,
        private _snackBar: MatSnackBar,
    ) {}

    public init() {
        if (environment.production) {
            timer(0, minutes(5))
                .pipe(switchMap(() => this.ajax.get<{ version: string }>(`/version.json?t=${new Date().getTime()}`)))
                .subscribe((res) => this.check(res.version));
        }
    }

    private check(version: string) {
        if (this.currentVersion === undefined) {
            console.log('app version:', version);
            this.currentVersion = version;
        } else if (this.currentVersion !== version) {
            console.log('new app version detected:', version);
            this.openSnackBar(
                'A new version of the application is now available. Please refresh your page to avail of its features.',
                'Refresh Now',
            );
        }
    }

    /**
     * Open a snackbar to alert user
     * a new version of application is available,
     * provide them a refresh button in order to get the lastest version of the web app.
     * @param message message on the snack bar
     * @param action text on the action button
     * @returns {boolean}
     */
    private openSnackBar(message: string, action: string) {
        this._snackBar.openFromComponent(RefreshSnackBarComponent, {
            data: { message, action },
            duration: 0,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
        });
    }
}
