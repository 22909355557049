import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CardComponent } from '../card.component';
import { Severities } from 'projects/angular-clarity/src/app/interfaces/events/events-summary.interface';
import { client } from 'projects/angular-clarity/src/app/stores/client';
import { loadMgmtSubscriptions } from 'projects/angular-clarity/src/app/stores/client/octiga/status/subscriptions/actions';
import { loadSummarys } from 'projects/angular-clarity/src/app/stores/client/etl/summary/actions';
@Component({
    selector: 'app-monitoring',
    templateUrl: './monitoring.component.html',
    styleUrls: ['./monitoring.component.scss'],
})
export class MonitoringComponent extends CardComponent implements OnInit, OnDestroy {
    public tenant_id = this.route.snapshot.paramMap.get('tenant');
    subscriptions: Subscription[] = [];
    status$: BehaviorSubject<{ monitoring: { status: boolean; error?: string }; alert: boolean }> = new BehaviorSubject(
        { monitoring: { status: true }, alert: false },
    );
    severity$: BehaviorSubject<{ critical: number | string; danger: number | string; warning: number | string }> =
        new BehaviorSubject({ critical: '-', danger: '-', warning: '-' });
    isLoading = true;

    constructor(
        private clientStore: Store<any>,
        public router: Router,
        private route: ActivatedRoute,
    ) {
        super();
    }

    ngOnInit(): void {
        this.dispatcher();
        this.fetchWeekSummariesWithSeverity();
        this.updateStatus();
    }

    dispatcher() {
        this.clientStore.dispatch(loadSummarys({ _tenant: this.tenant_id, num_days: 7 }));
        this.clientStore.dispatch(loadMgmtSubscriptions({ _tenant: this.tenant_id }));
    }

    ngOnDestroy() {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    private fetchWeekSummariesWithSeverity() {
        const today_iso = new Date().toISOString().split('T')[0] + 'T23:59:59.999Z';
        const week_ago_date = new Date();
        week_ago_date.setDate(week_ago_date.getDate() - 6);
        const week_ago_iso = week_ago_date.toISOString().split('T')[0];

        const dates_sub = this.clientStore
            .pipe(select(client(this.tenant_id).severity.date.byDateRange(week_ago_iso, today_iso)))
            .subscribe((res) => {
                let critical = 0;
                let danger = 0;
                let warning = 0;
                Object.values(res).forEach((date: Severities) => {
                    critical += date.critical;
                    danger += date.danger;
                    warning += date.warning;
                });
                this.severity$.next({ critical, danger, warning });
            });

        this.subscriptions.push(dates_sub);
    }

    updateStatus() {
        const mgmt_sub = this.clientStore
            .pipe(select(client(this.tenant_id).octiga.status.subscriptions.all))
            .subscribe((res) => {
                if (Object.entries(res).length === 0 || typeof res === 'string') {
                    this.status$.next({ ...this.status$.value, monitoring: { status: false, error: res as string } });
                } else {
                    this.status$.next({ ...this.status$.value, monitoring: { status: true } });
                }
            });

        this.subscriptions.push(mgmt_sub);
    }

    isLoaded(loaded: boolean) {
        if (loaded) {
            this.isLoading = false;
        }
    }
}
