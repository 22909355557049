import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, mergeMap, last, map } from 'rxjs/operators';
import * as ExoActions from './actions';
import { EOPProtectionPolicy } from './model';
import { retry } from 'projects/angular-clarity/src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

const EndPoint = '/api/microsoft/powershell/exo/eop-protection-policy-rule';
const EnableEndPoint = '/api/microsoft/powershell/exo/enable-eop-protection-policy-rule';
const DisableEndPoint = '/api/microsoft/powershell/exo/disable-eop-protection-policy-rule';

@Injectable()
export class EOPProtectionPolicyEffects {
    exoEOPProtectionPolicyRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.loadEOPProtectionPolicy),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.get(_tenant).pipe(
                    retry(3000, 3, 'eop policy timeout'),
                    last(),
                    map((data: EOPProtectionPolicy[]) => ExoActions.loadEOPProtectionPolicySuccess({ _tenant, data })),
                    catchError((error) => of(ExoActions.loadEOPProtectionPolicyFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    enableEopProtectionPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.enableExoEOPProtectionPolicy),
            mergeMap((action) =>
                this.enable(action._tenant, action.data).pipe(
                    map((res) => ({ ...res[0] })),
                    map((data: EOPProtectionPolicy) =>
                        ExoActions.enableExoEOPProtectionPolicySuccess({ _tenant: action._tenant, data }),
                    ),
                    catchError((error) =>
                        of(
                            ExoActions.enableExoEOPProtectionPolicyFailure({
                                _tenant: action._tenant,
                                error: { message: error.message, trace: error.trace },
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    disableEopProtectionPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.disableExoEOPProtectionPolicy),
            mergeMap((action) =>
                this.disable(action._tenant, action.data).pipe(
                    map((res) => ({ ...res[0] })),
                    map((data: EOPProtectionPolicy) =>
                        ExoActions.disableExoEOPProtectionPolicySuccess({ _tenant: action._tenant, data }),
                    ),
                    catchError((error) =>
                        of(ExoActions.disableExoEOPProtectionPolicyFailure({ _tenant: action._tenant, error })),
                    ),
                ),
            ),
        ),
    );

    private get(tenant: string) {
        return this.ajax.get(tenant, EndPoint);
    }

    private enable(tenant: string, body: Partial<EOPProtectionPolicy>): Observable<EOPProtectionPolicy> {
        return this.ajax.put(tenant, EnableEndPoint, body);
    }

    private disable(tenant: string, body: Partial<EOPProtectionPolicy>): Observable<EOPProtectionPolicy> {
        return this.ajax.put(tenant, DisableEndPoint, body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
