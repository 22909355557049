import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { CapItem } from '../model';

export interface CapDialogData {
    allItems: CapItem[];
    template: boolean;
    allListTitle: string;
    allListComparativeTitle: string;
    desiredListTitle: string;
    title: string;
}

@Component({
    selector: 'app-manage-items-wrapper-dialog',
    templateUrl: './manage-items-wrapper-dialog.component.html',
    styleUrls: ['./manage-items-wrapper-dialog.component.scss'],
})
export class ManageItemsWrapperDialogComponent implements OnInit {
    allItems$: BehaviorSubject<CapItem[]> = new BehaviorSubject([]);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CapDialogData,
        private dialogRef: MatDialogRef<ManageItemsWrapperDialogComponent>,
    ) {
        this.allItems$.next(this.data.allItems);
    }

    ngOnInit(): void {}

    saveChanges() {
        this.dialogRef.close(this.allItems$.getValue());
    }
}
