import { Injectable } from '@angular/core';
import { Group } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    EMPTY,
    Observable,
    of,
    switchMap,
    catchError,
    concatMap,
    mergeMap,
    expand,
    reduce,
    filter,
    map,
    take,
    distinct,
} from 'rxjs';
import { client } from '../..';
import * as actions from './actions';
import * as GroupMembersActions from './members/actions';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';
import { skipUntilTenantLoaded } from 'projects/angular-clarity/src/app/services/blob.service';

interface GraphGroupsResponse {
    value: Group[];
    '@odata.nextLink'?: string;
}

function parseToken(response: GraphGroupsResponse) {
    let skiptoken: string;
    if (response['@odata.nextLink']) {
        skiptoken = response['@odata.nextLink'].split('skiptoken=')[1];
    }
    return skiptoken;
}

@Injectable()
export class GroupEffects {
    loadGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadGroups),
            distinct((action) => action._tenant),
            skipUntilTenantLoaded(this.store),
            mergeMap(({ _tenant }) =>
                this.getGroupsExpanded(_tenant).pipe(
                    concatMap((groups) => {
                        if (groups.length === 0) {
                            return [
                                actions.loadGroupsSuccess({ _tenant, groups: [] }),
                                GroupMembersActions.membersLoaded({ _tenant }),
                            ];
                        }
                        return [
                            actions.loadGroupsSuccess({ _tenant, groups }),
                            ...groups.map((g) => GroupMembersActions.loadMembers({ _tenant, id: g.id })),
                        ];
                    }),
                    catchError((error: any) => of(actions.loadGroupsFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    private loadGroups(tenant: string, skiptoken?: string): Observable<GraphGroupsResponse> {
        let uri = '/api/microsoft/graph/groups?$top=50';
        if (skiptoken) uri += `&$skiptoken=${skiptoken}`;
        return this.ajax.get(tenant, uri);
    }

    private getGroupsExpanded(tenant: string) {
        return this.loadGroups(tenant).pipe(
            expand((data: GraphGroupsResponse) => {
                const token = parseToken(data);
                return token ? this.loadGroups(tenant, token) : EMPTY;
            }),
            reduce((acc, data) => acc.concat(data.value), [] as Group[]),
        );
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>,
    ) {}
}
