import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, distinct, mergeMap } from 'rxjs/operators';
import * as actions from './actions';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

@Injectable()
export class GraphSPOSharePointSiteDetailsEffects {
    loadSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadSPOSiteUsage),
            distinct((action) => action._tenant),
            mergeMap((action) =>
                this.get(action._tenant).pipe(
                    map((data: any) => actions.loadSPOSiteUsageSuccess({ _tenant: action._tenant, data })),
                    catchError((error) => of(actions.loadSPOSiteUsageFailure({ _tenant: action._tenant, error }))),
                ),
            ),
        ),
    );

    get(_tenant: string) {
        const url = "api/microsoft/graph/reports/getSharePointSiteUsageDetail(period='D7')?$format=application/json";

        return this.ajax.get(_tenant, url).pipe(
            map((res) => res.value),

            map((res) =>
                res.map((site) => ({
                    ...site,
                    storageUsedPercentage:
                        site.storageAllocatedInBytes !== 0
                            ? (site.storageUsedInBytes * 100) / site.storageAllocatedInBytes
                            : 0,

                    storageUsedInMGBytes: (site.storageUsedInBytes / (1024 * 1024 * 1024)).toFixed(2),
                    storageAllocatedInMGBytes: (site.storageAllocatedInBytes / (1024 * 1024 * 1024)).toFixed(2),
                })),
            ),
            map((res) => res.filter((res) => !res.isDeleted)),
        );
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
