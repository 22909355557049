import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { loadPSATenantTicketCount, loadPSATenantTicketCountSuccess, loadPSATenantTicketCountFailure } from './actions';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

@Injectable()
export class ReportPSATenantTicketCountEffects {
    loadPSATenantTicketCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPSATenantTicketCount),
            distinct((action) => action._tenant),
            mergeMap(({ _msp, _tenant, start, end }) =>
                this.getCountWithPaging(_msp, _tenant, start, end).pipe(
                    map((data) => {
                        console.log('ticket response', data);
                        return loadPSATenantTicketCountSuccess({ _msp, _tenant, data });
                    }),
                    catchError((error) => of(loadPSATenantTicketCountFailure({ _msp, _tenant, error }))),
                ),
            ),
        ),
    );

    private getCountWithPaging(msp: string, tenant: string, start: string, end: string) {
        const queryParams = !!start && !!end ? `?start=${start}&end=${end}` : '';
        return this.ajax.get(tenant, `/api/report/msp/${msp}/tenant/${tenant}/tickets/count${queryParams}`);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
