import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of } from 'rxjs';
import {
    catchError,
    concatMap,
    distinct,
    expand,
    filter,
    map,
    mergeMap,
    reduce,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { client } from '../..';
import { deleteBaselineSuccess } from '../baseline/actions';
import * as DeviationActions from './deviation.actions';
import { SwayDeviation } from './deviation.model';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

@Injectable()
export class DeviationEffects {
    private fetchWithPaging(tenant: string) {
        return this.ajax.get(tenant, `/api/sway/tenant/${tenant}/deviation`).pipe(
            expand((data) => {
                if (data.offset) {
                    return this.ajax.get(tenant, `/api/sway/tenant/${tenant}/deviation?offset=${data.offset}`);
                } else {
                    return EMPTY;
                }
            }),
            reduce((acc, data: any) => acc.concat(data.records), []),
        );
    }

    loadDeviations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviationActions.loadDeviations),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.fetchWithPaging(_tenant).pipe(
                    map((data) => DeviationActions.loadDeviationsSuccess({ _tenant, data })),
                    catchError((error) => of(DeviationActions.loadDeviationsFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    updateDeviation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviationActions.updateDeviation),
            mergeMap(({ _tenant, deviation_id, data }) =>
                this.patch(_tenant, deviation_id, data).pipe(
                    map((data) => DeviationActions.updateDeviationSuccess({ _tenant, data })),
                    catchError((error) => of(DeviationActions.updateDeviationFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    private patch(tenant: string, deviation: string, data: any) {
        return this.ajax.patch<SwayDeviation>(tenant, `/api/sway/tenant/${tenant}/deviation/${deviation}`, data);
    }

    createDeviation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviationActions.createDeviation),
            mergeMap(({ _tenant, data }) =>
                this.post(_tenant, data).pipe(
                    map((data) => DeviationActions.createDeviationSuccess({ _tenant, data })),
                    catchError((error) => of(DeviationActions.createDeviationFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    private post(tenant: string, data: any) {
        return this.ajax.post<SwayDeviation>(tenant, `/api/sway/tenant/${tenant}/deviation`, data);
    }

    deleteBaseline$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteBaselineSuccess),
            switchMap(({ _tenant, baselineId }) =>
                of(null).pipe(
                    withLatestFrom(this.store.select(client(_tenant).sway.deviations.byBaselineId(baselineId))),
                    filter(([_, deviation]) => !!deviation),
                    map(([_, deviation]) => {
                        const deviation_id = deviation.id;
                        const data = { resolve_time: new Date().toISOString() };
                        return DeviationActions.updateDeviation({ _tenant, deviation_id, data });
                    }),
                    catchError((error) => of(DeviationActions.updateDeviationFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    // ~~~~~~~~~~~~~~~
    // ~~~~ multi ~~~~
    // ~~~~~~~~~~~~~~~
    createMultipleDeviations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviationActions.createMultipleDeviations),
            concatMap(({ _tenant, data }) =>
                forkJoin(data.map((data) => this.post(_tenant, data))).pipe(
                    map((data) => DeviationActions.createMultipleDeviationsSuccess({ _tenant, data })),
                    catchError((error) => of(DeviationActions.createMultipleDeviationsFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    updateMultipleDeviations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviationActions.updateMultipleDeviations),
            concatMap(({ _tenant, data }) =>
                forkJoin(data.map(({ deviation_id, data }) => this.patch(_tenant, deviation_id, data))).pipe(
                    map((data) => DeviationActions.updateMultipleDeviationsSuccess({ _tenant, data })),
                    catchError((error) => of(DeviationActions.updateMultipleDeviationsFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store,
    ) {}
}
