import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { ASN } from './model';
import { initialStatus, Status } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'MspAsn';

export interface State extends EntityState<ASN>, Status {}

export const adapter: EntityAdapter<ASN> = createEntityAdapter<ASN>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadASNs, (state) => ({ ...state, loading: !state.loaded })),
    on(actions.loadASNsSuccess, (state, action) =>
        adapter.setAll(action.asns, { ...state, loading: false, loaded: true }),
    ),
    on(actions.loadASNsFailure, (state, { error }) => ({ ...state, error })),

    on(actions.createASN, (state) => ({ ...state, creating: true })),
    on(actions.createAsnSuccess, (state, action) => adapter.addOne(action.asn, { ...state, creating: false })),
    on(actions.createASNFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(actions.deleteASN, (state) => ({ ...state, deleting: true })),
    on(actions.deleteAsnSuccess, (state, action) => adapter.removeOne(action.id, { ...state, deleting: false })),
    on(actions.deleteAsnFailure, (state, { error }) => ({ ...state, error, deleting: false })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
