import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Status, initialStatus } from '../../../../status.interface';
import * as actions from './actions';
import { AuthenticationMethodModel } from './model';

export const featureKey = 'graphAuthenticationMethods';

export interface State extends EntityState<AuthenticationMethodModel>, Status {
    // additional entities state properties
}

export const adapter: EntityAdapter<AuthenticationMethodModel> = createEntityAdapter<AuthenticationMethodModel>({
    selectId: (item) => item.userId,
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.fetchGraphAuthenticationMethodsSuccess, (state, action) =>
        adapter.setAll(action.data, { ...state, loaded: true }),
    ),
    on(actions.fetchGraphAuthenticationMethodsFailure, (state, { error }) => ({ ...state, error, loaded: false })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
