import { getCompliancePolicyAssignments } from '..';
import { windows10CompliancePolicyDisplayName } from 'projects/angular-clarity/src/app/modules/sway/tools/schemas/compliance-policies/Windows10CompliancePolicy';
import { Windows10CompliancePolicySchema } from 'projects/angular-clarity/src/app/modules/sway/tools/schemas/compliance-policies/Windows10CompliancePolicy/model';
import {
    createCompliancePolicy,
    updateCompliancePolicy,
} from 'projects/angular-clarity/src/app/stores/client/graph/compliance-policies/policies/actions';
import { PolicyAssignment } from 'projects/angular-clarity/src/app/stores/client/graph/compliance-policies/policies/model';

export function Remediate(_tenant: string, data: PolicyAssignment[], schema: Windows10CompliancePolicySchema) {
    const currentPolicy = data.find((res) => res.policy?.displayName === windows10CompliancePolicyDisplayName);

    const policy = {
        '@odata.type': '#microsoft.graph.windows10CompliancePolicy',
        displayName: schema.contains.properties.policy.properties.displayName?.const,
        description: schema.contains.properties.policy.properties.description?.const,

        // Device Health
        bitLockerEnabled: schema.contains.properties.policy.properties.bitLockerEnabled?.const, // boolean
        secureBootEnabled: schema.contains.properties.policy.properties.secureBootEnabled?.const, // boolean
        codeIntegrityEnabled: schema.contains.properties.policy.properties.codeIntegrityEnabled?.const, // boolean

        // Device Properties
        osMinimumVersion: schema.contains.properties.policy.properties.osMinimumVersion?.const || null, //string
        osMaximumVersion: schema.contains.properties.policy.properties.osMaximumVersion?.const || null, //string
        mobileOsMinimumVersion: schema.contains.properties.policy.properties.mobileOsMinimumVersion?.const || null, //string
        mobileOsMaximumVersion: schema.contains.properties.policy.properties.mobileOsMaximumVersion?.const || null, //string

        // Configuration Manager Compliance
        configurationManagerComplianceRequired:
            schema.contains.properties.policy.properties.configurationManagerComplianceRequired?.const, //boolean

        // System Security (Password)
        passwordRequired: schema.contains.properties.policy.properties.passwordRequired?.const, // boolean // check
        passwordBlockSimple: schema.contains.properties.policy.properties.passwordBlockSimple?.const, // boolean
        passwordRequiredType: schema.contains.properties.policy.properties.passwordRequiredType?.const, // string
        passwordMinimumCharacterSetCount:
            schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount?.const, // TODO
        passwordMinimumLength: schema.contains.properties.policy.properties.passwordMinimumLength?.const, // number
        passwordMinutesOfInactivityBeforeLock:
            schema.contains.properties.policy.properties.passwordMinutesOfInactivityBeforeLock?.const || null, // number
        passwordExpirationDays: schema.contains.properties.policy.properties.passwordExpirationDays?.const, // [Validators.min(1)?.const, Validators.max(225)]
        passwordPreviousPasswordBlockCount:
            schema.contains.properties.policy.properties.passwordPreviousPasswordBlockCount?.const,
        passwordRequiredToUnlockFromIdle:
            schema.contains.properties.policy.properties.passwordRequiredToUnlockFromIdle?.const, //boolean
        // Encryption
        storageRequireEncryption: schema.contains.properties.policy.properties.storageRequireEncryption?.const, // boolean

        // Device Security
        activeFirewallRequired: schema.contains.properties.policy.properties.activeFirewallRequired?.const, //boolean
        antivirusRequired: schema.contains.properties.policy.properties.antivirusRequired?.const, //boolean
        tpmRequired: schema.contains.properties.policy.properties.tpmRequired?.const,
        antiSpywareRequired: schema.contains.properties.policy.properties.antiSpywareRequired?.const, //boolean

        // defender
        defenderEnabled: schema.contains.properties.policy.properties.defenderEnabled?.const,
        defenderVersion: schema.contains.properties.policy.properties.defenderVersion?.const || null, //string
        rtpEnabled: schema.contains.properties.policy.properties.rtpEnabled?.const,
        signatureOutOfDate: schema.contains.properties.policy.properties.signatureOutOfDate?.const,

        // defender for endpoint
        deviceThreatProtectionRequiredSecurityLevel:
            schema.contains.properties.policy.properties.deviceThreatProtectionRequiredSecurityLevel?.const,

        validOperatingSystemBuildRanges:
            schema.contains.properties.policy.properties.validOperatingSystemBuildRanges?.items?.oneOf.map((res) => ({
                description: res.properties.description.const,
                lowestVersion: res.properties.lowestVersion.const,
                highestVersion: res.properties.highestVersion.const,
            })) || [],
    };

    const assignments = getCompliancePolicyAssignments(schema);

    // need to update store
    if (currentPolicy?.id) {
        return updateCompliancePolicy({ _tenant, policy: { ...policy, id: currentPolicy.id }, assignments });
    } else {
        // const gracePeriodHours = schema.contains.properties.policy.properties.validOperatingSystemBuildRanges
        return createCompliancePolicy({
            _tenant,
            policy: {
                ...policy,

                scheduledActionsForRule: [
                    {
                        ruleName: 'DeviceNonCompliance',
                        scheduledActionConfigurations: [
                            {
                                actionType: 'block',
                                gracePeriodHours: 0, // 2 * 24 // convert days to hours
                            },
                        ],
                    },
                ],
            },
            assignments,
        });
    }
}
