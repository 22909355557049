import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { initialStatus, Status } from 'projects/angular-clarity/src/app/stores/status.interface';
export const featureKey = 'mspCwmSubTypes';

interface SubTypesByBoardType {
    data: any;
    board_id: number;
    type_id: number;
}

export interface State extends EntityState<SubTypesByBoardType>, Status {}

export const adapter: EntityAdapter<SubTypesByBoardType> = createEntityAdapter<SubTypesByBoardType>({
    selectId: (item) => `${item.board_id}-${item.type_id}`,
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadMspCwmSubTypesSuccess, (state, { data, board_id, type_id }) => {
        state = { ...state, loaded: true };
        return adapter.upsertOne({ data, board_id, type_id }, state);
    }),
    on(actions.loadMspCwmSubTypesFailure, (state, { error }) => ({ ...state, error })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
