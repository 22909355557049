import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { startWith } from 'rxjs';
import { BaseComponent } from '../../base/base.component';
import { MobileDeviceMailboxPolicy } from 'projects/angular-clarity/src/app/stores/client/powershell/exo/mobile-device-mailbox-policy/model';
import { MobileDeviceMailboxPolicySpec } from 'projects/angular-clarity/src/app/modules/sway/models/baseline-schemas/mobile-device-mailbox-policy-schema';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit, OnChanges {
    defaultPolicy: MobileDeviceMailboxPolicy;
    @Input() data: MobileDeviceMailboxPolicy[];
    MinPasswordLengthIgnored: boolean;

    setDaysStamp = MobileDeviceMailboxPolicySpec.setDaysStamp;
    setTimeStamp = MobileDeviceMailboxPolicySpec.setTimeStamp;

    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.defaultPolicy = this.data.find((res) => res.IsDefault);
    }

    ngOnInit(): void {
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));

        this.defaultPolicy = this.data.find((res) => res.IsDefault);
        this.form = MobileDeviceMailboxPolicySpec.initForm(schema);
        this.MinPasswordLengthIgnored = !this.MinPasswordLength;

        this.observeFormValueChanges(schema);
    }

    observeFormValueChanges(schema): void {
        this.form.valueChanges.pipe(startWith(this.form.value)).subscribe((res) => {
            schema.contains.properties.PasswordHistory.const = +res.PasswordHistory;
            schema.contains.properties.PasswordExpiration.const = MobileDeviceMailboxPolicySpec.setDaysStamp(
                res.PasswordExpiration,
            );
            schema.contains.properties.MinPasswordComplexCharacters.const = +res.MinPasswordComplexCharacters;

            if (!res.MinPasswordLength) {
                schema.contains.properties.MinPasswordLength = {};
                schema.contains.properties.MinPasswordLength.type = 'null';
            } else {
                schema.contains.properties.MinPasswordLength = {};
                schema.contains.properties.MinPasswordLength.type = 'number';
                schema.contains.properties.MinPasswordLength.const = +res.MinPasswordLength;
            }

            schema.contains.properties.MaxInactivityTimeLock.const = MobileDeviceMailboxPolicySpec.setTimeStamp(
                res.MaxInactivityTimeLock,
            );
            schema.contains.properties.RequireDeviceEncryption.const = res.RequireDeviceEncryption;
            schema.contains.properties.DeviceEncryptionEnabled.const = res.DeviceEncryptionEnabled;

            // for fixing issue purpose
            schema.contains.properties.AlphanumericPasswordRequired.const = res.AlphanumericPasswordRequired;

            schema.contains.properties.AllowSimplePassword.const = res.AllowSimplePassword;
            schema.contains.properties.PasswordEnabled.const = res.PasswordEnabled;
            console.log(this.form.invalid, 'invalid');
            if (this.form.invalid) {
                this.baselineErrorChange.next({ remediate: true, save: true });
            } else {
                this.baselineErrorChange.next({ remediate: false, save: false });
            }

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });
        });
    }

    get PasswordExpiration(): string {
        return this.form.get('PasswordExpiration').value;
    }

    set PasswordExpiration(value: string) {
        this.form.get('PasswordExpiration').patchValue(value);
    }

    get MaxInactivityTimeLock(): string {
        return this.form.get('MaxInactivityTimeLock').value;
    }

    set MaxInactivityTimeLock(value: string) {
        this.form.get('MaxInactivityTimeLock').patchValue(value);
    }

    get MinPasswordLength(): string {
        return this.form.get('MinPasswordLength').value;
    }
    set MinPasswordLength(value: string) {
        this.form.get('MinPasswordLength').patchValue(value);
    }

    get formValue() {
        return this.form.value;
    }

    onToggleMaxInactivityTimeLock(e: MatSlideToggleChange) {
        this.MaxInactivityTimeLock = e.checked ? 'Unlimited' : '10';
    }

    onTogglePasswordExpiration(e: MatSlideToggleChange) {
        this.PasswordExpiration = e.checked ? 'Unlimited' : '10';
    }

    onToggleMinPasswordLength(e: MatSlideToggleChange) {
        this.MinPasswordLength = e.checked ? null : '8';
        this.MinPasswordLengthIgnored = !this.MinPasswordLength;
    }
}
