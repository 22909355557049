import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import * as actions from './actions';
import { HostedOutboundSpamFilterPolicy } from './model';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';
import { retry } from 'projects/angular-clarity/src/app/pipes/retry.pipe';

@Injectable()
export class ExoHostedOutboundSpamFilterPolicyEffects {
    fetchHostedOutboundSpamFilterPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchHostedOutboundSpamFilterPolicy),
            distinct((action) => action._tenant),
            mergeMap((action) =>
                this.get(action._tenant).pipe(
                    retry(3000, 3, 'Hosted outbound spam filter policy timeout'),
                    map((data: HostedOutboundSpamFilterPolicy[]) =>
                        actions.fetchHostedOutboundSpamFilterPolicySuccess({ _tenant: action._tenant, data }),
                    ),
                    catchError((error) =>
                        of(actions.fetchHostedOutboundSpamFilterPolicyFailure({ _tenant: action._tenant, error })),
                    ),
                ),
            ),
        ),
    );

    private get(tenant: string): Observable<HostedOutboundSpamFilterPolicy[]> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/exo/hosted-outbound-spam-filter-policy');
    }

    updateExoHostedOutboundSpamFilterPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateExoHostedOutboundSpamFilterPolicy),
            mergeMap((action) =>
                this.put(action._tenant, action.data).pipe(
                    map((res: any) => ({ ...res.filter((res) => res.Identity === 'Default')[0] })),
                    map((data: HostedOutboundSpamFilterPolicy) =>
                        actions.updateExoHostedOutboundSpamFilterPolicySuccess({ _tenant: action._tenant, data }),
                    ),
                    catchError((error) =>
                        of(
                            actions.updateExoHostedOutboundSpamFilterPolicyFailure({
                                _tenant: action._tenant,
                                error: { message: error.message, trace: error.trace },
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    private put(
        tenant: string,
        body: Partial<HostedOutboundSpamFilterPolicy>,
    ): Observable<HostedOutboundSpamFilterPolicy> {
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/hosted-outbound-spam-filter-policy', body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
