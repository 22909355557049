import { BlockAutoForwardingIdentityName } from '.';
import {
    createExoTransportRule,
    deleteExoTransportRule,
    updateExoTransportRule,
} from 'projects/angular-clarity/src/app/stores/client/powershell/exo/transport-rule/actions';
import { ExoTransportRule } from 'projects/angular-clarity/src/app/stores/client/powershell/exo/transport-rule/model';

export function Remediate(_tenant: string, data: Partial<ExoTransportRule[]>, schema: any) {
    // return enableExoTransportRule({_tenant, identity: IdentityName})
    const isNoPolicySchema = !!schema?.not?.contains;

    const policyBeforeUpdate = data.find((res) => res.Identity === BlockAutoForwardingIdentityName);
    // let state = schema?.contains?.properties?.State?.const

    if (isNoPolicySchema) {
        if (policyBeforeUpdate) return deleteExoTransportRule({ _tenant, Identity: policyBeforeUpdate.Identity });
    } else {
        const actions = [];

        if (policyBeforeUpdate) {
            const exoTransportRule: Partial<ExoTransportRule> = {
                Identity: BlockAutoForwardingIdentityName,
                Name: BlockAutoForwardingIdentityName, // TODO
                FromScope: schema.contains.properties.FromScope.const,
                SentToScope: schema.contains.properties.SentToScope.const,
                MessageTypeMatches: 'AutoForward',
                RejectMessageEnhancedStatusCode: schema.contains.properties.RejectMessageEnhancedStatusCode.const,
                RejectMessageReasonText: schema.contains.properties.RejectMessageReasonText.const,
                GenerateIncidentReport: schema.contains.properties.GenerateIncidentReport.const || null,
                Priority: schema.contains.properties.Priority.const,

                ExceptIfSentTo: schema.contains.properties.ExceptIfSentTo?.items?.enum || [],
                ExceptIfRecipientDomainIs: schema.contains.properties.ExceptIfRecipientDomainIs?.items?.enum || [],
            };
            actions.push(updateExoTransportRule({ _tenant, exoTransportRule }));

            // if (state !== policyBeforeUpdate.State) {
            //     actions.push(updateSate(_tenant, state))
            // }
        } else {
            const exoTransportRule: Partial<ExoTransportRule> = {
                Identity: BlockAutoForwardingIdentityName,
                Name: BlockAutoForwardingIdentityName, // TODO
                FromScope: schema.contains.properties.FromScope.const,
                SentToScope: schema.contains.properties.SentToScope.const,
                MessageTypeMatches: 'AutoForward',
                RejectMessageEnhancedStatusCode: schema.contains.properties.RejectMessageEnhancedStatusCode.const,
                RejectMessageReasonText: schema.contains.properties.RejectMessageReasonText.const,
                GenerateIncidentReport: schema.contains.properties.GenerateIncidentReport.const || undefined,
                Priority: schema.contains.properties.Priority.const,

                ExceptIfSentTo: schema.contains.properties.ExceptIfSentTo?.items?.enum || undefined,
                ExceptIfRecipientDomainIs:
                    schema.contains.properties.ExceptIfRecipientDomainIs?.items?.enum || undefined,
            };
            actions.push(createExoTransportRule({ _tenant, exoTransportRule }));
        }

        return actions;
    }
}
