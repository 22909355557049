import { createReducer, on } from '@ngrx/store';
import { updateExoAdminAuditLogConfigSuccess } from '../../powershell/exo/admin-audit-log-config/actions';
import { Status, initialStatus } from './../../../status.interface';
import * as TenantActions from './actions';
import { Tenant } from 'projects/angular-clarity/src/app/interfaces/tenant.interface';

export const featureKey = 'octigaTenant';

export interface State extends Status {
    data: Tenant;
}

export const initialState: State = {
    data: null,
    ...initialStatus,
};

export const reducer = createReducer(
    initialState,
    on(TenantActions.loadTenantSuccess, (state, { data }) => ({ ...state, data, loaded: true })),
    on(TenantActions.loadTenantFailure, (state, { error }) => ({ ...state, error })),
    on(updateExoAdminAuditLogConfigSuccess, (state, { data: { AdminAuditLogEnabled } }) => ({
        ...state,
        data: {
            ...state.data,
            health: {
                ...state.data.health,
                admin_audit_log: AdminAuditLogEnabled,
            },
        },
    })),
);

export const selectTenantState = (state: State) => state;
export const selectTenantData = (state: State) => state.data;
