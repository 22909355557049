import { Classification, Meta } from "../../interfaces/meta.interface";
import {
    fraud_ip_in_asn,
    fraud_ip_in_country,
    fraud_ip_in_ip,
    fraud_ip_in_region,
    fraud_ip_not_in_allowed_list,
    is_microsoft_activity,
    not_in_allowed_ip,
    user_not_in_org,
    not_in_allowed_asn_ip_region_country
} from "../statements";

type FileAccessedFields = {
    ObjectId: string;
};

export function FileAccessedOrDownloadedClx(
    title: string,
    description: string
): Classification<FileAccessedFields>[] {
    return [
        // file accessed: fraudulent IP. not in allowed list.
        {
            severity: "danger",
            title,
            description: `${description} ${fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // file accessed: fraudulent IP.  allowed IP
        {
            severity: "warning",
            title,
            description: `${description} ${fraud_ip_in_ip}`,
            predicates: {
                isAllowedIP: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // file accessed: fraudulent IP. allowed region.
        {
            severity: "warning",
            title,
            description: `${description} ${fraud_ip_in_region}`,
            predicates: {
                isAllowedRegion: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // file accessed: fraudulent IP. in allowed countries.
        {
            severity: "warning",
            title,
            description: `${description} ${fraud_ip_in_country}`,
            predicates: {
                isAllowedCountry: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // file accessed: fraudulent IP. in allowed asn.
        {
            severity: "warning",
            title,
            description: `${description} ${fraud_ip_in_asn}`,
            predicates: {
                isAllowedASN: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // file accessed: user in org. not in allowed list
        {
            severity: "warning",
            title,
            description: `${description} ${not_in_allowed_asn_ip_region_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: true,
            },
        },
        // file accessed: user not in org. not in allowed list
        {
            severity: "info",
            title,
            description: `${description} ${user_not_in_org} ${not_in_allowed_ip}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: false,
            },
        },
        // file accessed: microsoft activity
        {
            severity: "info",
            title,
            description: `${description} ${is_microsoft_activity}`,
            predicates: {
                isMicrosoftActivity: true,
            },
        },
        // file accessed
        {
            severity: "info",
            title,
            description: `${description}`,
        },
    ];
}

type FileDownloadedFields = {
    ObjectId: string;
};

const FileAccessed: Meta<FileAccessedFields> = {
    info: {
        title: "SharePoint File Accessed",
        description: "A user or system account viewed a file.",
    },
    extractor: (log: any) => {
        return {
            ObjectId: log.ObjectId,
        };
    },
    classifications: FileAccessedOrDownloadedClx(
        "SharePoint File Accessed",
        "A file was accessed on SharePoint."
    ),
};

const FileDownloaded: Meta<FileDownloadedFields> = {
    info: {
        title: "SharePoint File Downloaded",
        description: "A user downloaded a file.",
    },
    extractor: (log: any) => {
        return {
            ObjectId: log.ObjectId,
        };
    },
    classifications: FileAccessedOrDownloadedClx(
        "SharePoint File Downloaded",
        "A file was downloaded from SharePoint."
    ),
};

const operations = {
    FileAccessed: FileAccessed,
    FileDownloaded: FileDownloaded,
};

export default operations;
