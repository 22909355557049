import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
    selector: 'app-single-filtered-list',
    templateUrl: './single-filtered-selection-list.component.html',
    styleUrls: ['./single-filtered-selection-list.component.scss'],
})
export class SingleFilteredSelectionListComponent implements OnInit, OnDestroy {
    @Input() placeholder: string = '';
    @Input() options: string[] = [];
    @Input() initial: string;
    @Input() readOnly;
    @Output() selected = new EventEmitter<string>();

    subscription$: Subscription;
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;

    constructor() {}

    ngOnInit() {
        this.myControl.setValue(this.initial);
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value)),
        );

        this.subscription$ = this.myControl.valueChanges.subscribe(() => {
            this.selected.emit(this.myControl.value);
        });
    }

    ngOnDestroy() {
        if (this.subscription$) this.subscription$.unsubscribe();
    }

    private _filter(value: string): string[] {
        if (!this.options) return [];
        const filterValue = value.toLowerCase();
        return this.options.filter((option) => option.toLowerCase().indexOf(filterValue) === 0);
    }
}
