import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    combineLatest,
    Observable,
    of,
    zip,
    catchError,
    delay,
    filter,
    map,
    mergeMap,
    switchMap,
    take,
    distinct,
} from 'rxjs';
import { client } from '../../..';
import * as actions from './actions';
import { PerUserMfaState, PerUserMfaModel } from './model';
import { skipUntilTenantLoaded } from 'projects/angular-clarity/src/app/services/blob.service';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

interface Response {
    id: string;
    status: number;
    headers: {
        [key: string]: string;
    };
    body: {
        perUserMfaState: PerUserMfaState;
    };
}

interface BatchResponse {
    responses: Response[];
}

@Injectable()
export class PerUserMfaStateEffects {
    fetchPerUserMfa$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchGraphPerUserMfaState),
            distinct((action) => action._tenant),
            skipUntilTenantLoaded(this.store),
            switchMap((action) =>
                this.store.pipe(
                    select(client(action._tenant).graph.authenticationMethods.status),
                    filter((status) => !status.loaded),
                    map(() => action),
                    take(1),
                ),
            ),
            mergeMap(({ _tenant, ids }) =>
                this.fetchPerUserMfa(_tenant, ids).pipe(
                    map((data) => actions.fetchGraphPerUserMfaStateSuccess({ _tenant, data })),
                    catchError((error) => of(actions.fetchGraphPerUserMfaStateFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    private fetchPerUserMfa(tenant: string, ids: string[]): Observable<PerUserMfaModel[]> {
        // TODO, karim. make it generic
        const requests = ids.map((id) => ({
            method: 'GET',
            id,
            url: `/users/${id}/authentication/requirements`,
        }));

        const request_batches = []; // batching batches! $batch is limited to 20 requests per batch

        let count = 0;
        do {
            const start = count * 20;
            const end = start + 20;
            request_batches.push(requests.slice(start, end));
        } while (++count * 20 < requests.length);

        return combineLatest(request_batches.map((requests) => this.batch(tenant, { requests }))).pipe(
            take(1),
            map((items) => items.flat(1)),
            switchMap((batches) => {
                const results: PerUserMfaModel[] = [];
                const retry_ids: string[] = [];
                const retry_delays: number[] = [];

                for (const batch of batches) {
                    for (const response of batch.responses) {
                        if (response.status === 200) {
                            results.push({
                                userId: response.id,
                                value: response.body.perUserMfaState, // should be an array, probs not necessary
                            });
                            continue;
                        }

                        if (response.status === 429) {
                            // too many requests
                            retry_ids.push(response.id);
                            retry_delays.push(parseInt(response.headers['Retry-After']) || 0);
                            continue;
                        }

                        console.error(response);
                    }
                }

                if (retry_ids.length === 0) {
                    // no throttled requests
                    return of(results);
                } else {
                    // recur this function to retry throttled requests
                    const delay_ms = Math.max(...retry_delays) * 1000;
                    return of(null).pipe(
                        delay(delay_ms),
                        switchMap(() =>
                            zip(
                                // zip results from this invocation and next
                                of(results),
                                this.fetchPerUserMfa(tenant, retry_ids),
                            ).pipe(map(([a, b]) => a.concat(b))),
                        ),
                    );
                }
            }),
        );
    }

    private batch(tenant: string, body: any): Observable<BatchResponse> {
        return this.ajax.post<BatchResponse>(tenant, '/api/microsoft/graph/$batch', body);
    }

    enablePerUserMfa$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updatePerUserMfaState),
            mergeMap(({ _tenant, userId, perUserMfaState }) =>
                this.updatePerUserMfa(_tenant, userId, perUserMfaState).pipe(
                    map(({ _tenant, userId, perUserMfaState }) =>
                        actions.updatePerUserMfaStateSuccess({ _tenant, userId, perUserMfaState }),
                    ),
                    catchError((error) => of(actions.updatePerUserMfaStateFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    private updatePerUserMfa(_tenant: string, userId: string, perUserMfaState: PerUserMfaState): Observable<any> {
        return this.ajax
            .patch<BatchResponse>(_tenant, `/api/microsoft/graph/users/${userId}/authentication/requirements`, {
                perUserMfaState,
            })
            .pipe(map(() => ({ _tenant, userId, perUserMfaState })));
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>,
    ) {}
}
