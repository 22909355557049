import { User } from '@microsoft/microsoft-graph-types-beta';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { initialStatus, Status } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'groupMembers';

interface GroupMembers {
    id: string;
    members: User[];
}

export interface State extends EntityState<GroupMembers>, Status {
    count: number;
    updateCount: number;
}

export const adapter: EntityAdapter<GroupMembers> = createEntityAdapter<GroupMembers>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
    count: 0,
    updateCount: 0,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadMembers, (state, action) => ({ ...state, count: state.count + 1 })),
    on(actions.loadMembersSuccess, (state, { id, members }) => {
        const count = state.count - 1;
        const loaded = count === 0;
        const loading = count > 0;
        const updated = { ...state, loaded, loading, count };
        return adapter.upsertOne({ id, members }, updated);
    }),

    on(actions.loadMembersFailure, (state, { id, error }) => {
        // TODO: handle error
        const count = state.count - 1;
        const loaded = count === 0;
        const loading = count > 0;
        const updated = { ...state, loaded, loading, count };
        const members = [];
        return adapter.upsertOne({ id, members }, updated);
    }),

    on(actions.addMemberToGroup, (state, action) => ({ ...state, updating: true, updateCount: state.updateCount + 1 })),

    on(actions.addMemberToGroupSuccess, (state, action) => {
        const _members = state.entities[action.group.id] ? state.entities[action.group.id].members : [];
        const members = [..._members, action.user];
        const updateCount = state.updateCount - 1;
        const updating = !(updateCount === 0);
        return adapter.upsertOne({ id: action.group.id, members }, { ...state, updating, updateCount });
    }),

    on(actions.addMemberToGroupFailure, (state, { error }) => ({
        ...state,
        loaded: false,
        error,
        updateCount: state.updateCount - 1,
        updating: !(state.updateCount === 0),
    })),

    on(actions.removeMemberFromGroup, (state, action) => ({
        ...state,
        updating: true,
        updateCount: state.updateCount + 1,
    })),

    on(actions.removeMemberFromGroupSuccess, (state, action) => {
        const updateCount = state.updateCount - 1;
        const updating = !(updateCount === 0);
        const members = state.entities[action.group.id]?.members.filter((member) => member.id !== action.userId);
        return adapter.upsertOne({ id: action.group.id, members }, { ...state, updating, updateCount });
    }),

    on(actions.removeMemberFromGroupFailure, (state, { error }) => ({
        ...state,
        updating: !(state.updateCount === 0),
        error,
        updateCount: state.updateCount - 1,
    })),

    on(actions.membersLoaded, (state) => ({ ...state, count: 0, loaded: true, loading: false })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectInternal = (state: State) =>
    selectAll(state).map((item) => ({
        id: item.id,
        members: item.members.filter((item) => item.userType === 'Member'),
    }));
