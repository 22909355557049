import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { BehaviorSubject, Subscription, filter, skip } from 'rxjs';
import { BaseComponent } from '../../../base/base.component';
import { checkIsBlockingEveryone } from '../shared/utlize';
import { InitCapBaselineFormItem } from '../shared/cap-form';
import { blockUnSupportedDeviceBaselineSchema } from './model';
import { SnackbarService } from 'projects/angular-clarity/src/app/services/snackbar.service';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit, OnDestroy {
    schemaFunc = blockUnSupportedDeviceBaselineSchema;
    schema$: BehaviorSubject<any>;
    policy$: BehaviorSubject<ConditionalAccessPolicy> = new BehaviorSubject(null);

    constructor(
        private snackbar: SnackbarService,
        private fb: FormBuilder,
    ) {
        super();
    }
    initForm(schema): FormGroup {
        return InitCapBaselineFormItem(schema, this.fb);
    }

    ngOnInit(): void {
        this.form = this.initForm(this.baseline.schema);

        this.schema$ = new BehaviorSubject(this.baseline.schema);

        this.grantController.valueChanges.subscribe((grant) => {
            const schema = JSON.parse(JSON.stringify(this.baseline.schema));
            schema.contains.properties.grantControls.properties.builtInControls.items.const = grant;

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });
        });

        this.schema$
            .pipe(
                filter((res) => !!res),
                skip(1),
            )
            .subscribe((schema) => {
                // skip first emit
                this.baselineChange.next({
                    ...this.baseline,
                    schema: JSON.parse(JSON.stringify(schema)),
                });
            });
    }

    onBaselineErrorChange(e) {
        this.baselineErrorChange.next({
            remediate: e.remediate,
            save: e.save,
        });
    }

    get grantController() {
        return this.form?.get('grant') as FormControl;
    }

    ngOnDestroy(): void {
        this.snackbar.dismiss();
    }
}
