export const TargetGroups = {
    allUsers: '#microsoft.graph.allLicensedUsersAssignmentTarget',
    allDevices: '#microsoft.graph.allDevicesAssignmentTarget',
    groupInclusion: '#microsoft.graph.groupAssignmentTarget',
    groupExclusion: '#microsoft.graph.exclusionGroupAssignmentTarget',
};

export function getDesiredAssignmentsSchema(formValue: any) {
    // assignments (include)
    if (formValue.assignments.allUsers || formValue.assignments.allDevices) {
        // include all devices or all users
        let arr_length = 0;
        const arr_enum = [];

        const assignment_schema = {
            type: 'array',
            minItems: arr_length,
            maxItems: arr_length,
            items: {
                type: 'object',
                properties: {
                    target: {
                        type: 'object',

                        oneOf: [],
                    },
                },
            },
        };

        if (formValue.assignments.allDevices) {
            arr_length += 1;
            arr_enum.push({
                properties: {
                    '@odata.type': { type: 'string', const: TargetGroups.allDevices },
                },
            });

            assignment_schema.minItems = arr_length;
            assignment_schema.maxItems = arr_length;
            assignment_schema.items.properties.target.oneOf = arr_enum;
        }
        if (formValue.assignments.allUsers) {
            arr_length += 1;
            arr_enum.push({
                properties: {
                    '@odata.type': { type: 'string', const: TargetGroups.allUsers },
                },
            });

            assignment_schema.minItems = arr_length;
            assignment_schema.maxItems = arr_length;
            assignment_schema.items.properties.target.oneOf = arr_enum;
        }

        return assignment_schema;
    } else if (formValue.assignments.groupInclusion && formValue.assignments.includedGroups?.length > 0) {
        // include groups
        const includedGroups: string[] = formValue.assignments.includedGroups;
        const arrLength = includedGroups.length;

        const assignment_schema = {
            type: 'array',
            minItems: arrLength,
            maxItems: arrLength,
            items: {
                type: 'object',
                properties: {
                    target: {
                        type: 'object',

                        oneOf: includedGroups.map((res) => ({
                            properties: {
                                '@odata.type': { type: 'string', const: TargetGroups.groupInclusion },
                                groupId: { type: 'string', const: res },
                            },
                        })),
                    },
                },
            },
        };

        return assignment_schema;
    } else {
        return {
            type: 'array',
            minItems: 0,
            maxItems: 0,
        };
    }
}
