import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, tap, filter } from 'rxjs';
import { catchError, distinct, mergeMap, map } from 'rxjs/operators';
import * as actions from './actions';
import { ReportSchedule } from './model';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

@Injectable()
export class ReportScheduleEffects {
    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadSchedules),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.get(_tenant).pipe(
                    filter((data) => !!data),
                    map((data) => {
                        return actions.loadSchedulesSuccess({
                            _tenant,
                            data: data.records.map((d) => ({ ...d, emails: JSON.parse(d.emails) })),
                        });
                    }),
                    catchError((error) => {
                        console.log('err', error);
                        return of(actions.loadSchedulesFailure({ _tenant, error }));
                    }),
                ),
            ),
        ),
    );

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createSchedule),
            mergeMap(({ _tenant, data }) =>
                this.post(_tenant, data).pipe(
                    map((data: any) =>
                        actions.createScheduleSuccess({
                            _tenant,
                            data: {
                                ...data,
                                emails: JSON.parse(data.emails),
                            },
                        }),
                    ),
                    catchError((error: any) => {
                        return of(actions.createScheduleFailure({ _tenant, error }));
                    }),
                ),
            ),
        ),
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateSchedule),
            mergeMap(({ _tenant, data }) =>
                this.put(_tenant, data).pipe(
                    map((data: any) =>
                        actions.updateScheduleSuccess({
                            _tenant,
                            data: {
                                ...data,
                                emails: JSON.parse(data.emails),
                            },
                        }),
                    ),
                    catchError((error: any) => of(actions.updateScheduleFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteSchedule),
            mergeMap(({ _tenant, ScheduleId }) =>
                this.delete(_tenant, ScheduleId).pipe(
                    tap((res) => console.log(res, 'success')),
                    map(() => actions.deleteScheduleSuccess({ _tenant, ScheduleId })),
                    catchError((error: any) => of(actions.deleteScheduleFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    /**
     * @description
     * get all Schedules from a specific tenant
     * @param {string} tenant - tenant ID (string)
     */
    private get(tenant: string) {
        return this.ajax.get(tenant, `/api/report/tenant/${tenant}/schedule`);
    }

    /**
     * @description
     * delete a Schedule from a specific tenant
     * @param {string} tenant - tenant ID (string)
     * @param {Schedule} ScheduleId - Schedule
     */
    private delete(tenant: string, ScheduleId: string) {
        return this.ajax.delete(tenant, `/api/report/tenant/${tenant}/schedule/${ScheduleId}`);
    }

    /**
     * @description
     * add a new configured Schedule to a specific tenant
     * @param {string} tenant - tenant ID (string)
     * @param {Partial<ReportSchedule>} body - body Object of ReportSchedule
     */
    private post(tenant: string, body: Partial<ReportSchedule>) {
        return this.ajax.post(tenant, `/api/report/tenant/${tenant}/schedule`, body);
    }

    /**
     * @description
     * edit existing Schedule's details
     * @param {string} tenant - tenant ID (string)
     * @param {Partial<ReportSchedule>} body- body Object of ReportSchedule
     */
    private put(tenant: string, body: Partial<ReportSchedule>): Observable<ReportSchedule> {
        return this.ajax.put(tenant, `/api/report/tenant/${tenant}/schedule`, body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
