import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, filter, skip } from 'rxjs';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { FormBuilder, FormControl } from '@angular/forms';
import { InitCapBaselineFormItem } from '../shared/cap-form';
import { BaseComponent } from '../../../base/base.component';
import { CAPSecurityInfoRegistrationBaselineSchema } from '.';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit {
    schemaFunc = CAPSecurityInfoRegistrationBaselineSchema;
    schema$: BehaviorSubject<any>;
    policy$: BehaviorSubject<ConditionalAccessPolicy> = new BehaviorSubject(null);

    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.form = this.initForm(this.baseline.schema);

        this.schema$ = new BehaviorSubject(this.baseline.schema);

        this.schema$
            .pipe(
                filter((res) => !!res),
                skip(1),
            )
            .subscribe((schema) => {
                // skip first emit
                this.baselineChange.next({
                    ...this.baseline,
                    schema: JSON.parse(JSON.stringify(schema)),
                });
            });
    }

    initForm(schema) {
        return InitCapBaselineFormItem(schema, this.fb);
    }

    onBaselineErrorChange(e) {
        this.baselineErrorChange.next(e);
    }

    mapper(item) {
        const mapping = {
            AllTrusted: 'All trusted locations',
            'urn:user:registersecurityinfo': 'Register Security Information',
            mfa: 'Require Multifactor Authentication.',
        };

        return mapping[item] || item;
    }

    get grantController() {
        return this.form?.get('grant') as FormControl;
    }
}
