import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of, EMPTY } from 'rxjs';
import { catchError, map, mergeMap, expand, reduce } from 'rxjs/operators';
import { updateMsp } from '../../msp/actions';
import * as actions from './actions';
import { MspAjaxService } from 'projects/angular-clarity/src/app/services/ajax/msp-ajax.service';

@Injectable()
export class HaloConfigEffects {
    loadMspHaloConnection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadMspHaloConnection),
            mergeMap(() =>
                this.mspAjax.get('/api/octiga/v2/msp/halo').pipe(
                    map((data) => actions.loadMspHaloConfigSuccess({ data })),
                    catchError((error) => {
                        this.store.dispatch(updateMsp({ data: { halo: false } }));
                        return of(actions.loadMspHaloConfigFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    loadMspHaloConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadMspHaloConfig),
            mergeMap(() =>
                this.getHaloConfig().pipe(
                    map((data) => actions.loadMspHaloConfigSuccess({ data })),
                    catchError((error) => {
                        this.store.dispatch(updateMsp({ data: { halo: false } }));
                        return of(actions.loadMspHaloConfigFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    getClients() {
        return this.mspAjax.get('/api/octiga/v2/halo/client?pageinate=True&page_size=100&page_no=1').pipe(
            expand((res) => {
                if (res.page_size * res.page_no < res.record_count) {
                    return this.mspAjax.get(
                        `/api/octiga/v2/halo/client?pageinate=True&page_size=${res.page_size}&page_no=${res.page_no + 1}`,
                    );
                }
                return EMPTY;
            }),
            reduce((acc, res: any) => acc.concat(res.clients), []),
        );
    }

    getHaloConfig() {
        return combineLatest([
            this.mspAjax.get('/api/octiga/v2/halo/team'),
            this.mspAjax.get('/api/octiga/v2/halo/status'),
            this.mspAjax.get('/api/octiga/v2/halo/TicketType'),
            this.mspAjax.get('/api/octiga/v2/halo/priority?slaid=1'),
            this.getClients(),
        ]).pipe(
            map(([teams, status, ticketTypes, priority, clients]) => ({
                teams,
                status,
                ticketTypes,
                priority,
                clients,
            })),
        );
    }

    constructor(
        private actions$: Actions,
        private mspAjax: MspAjaxService,
        private store: Store<any>,
    ) {}
}
