import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import * as SharingActions from './actions';
import { SharingPolicy } from 'projects/angular-clarity/src/app/interfaces/powershell/exo/sharing-policy.interface';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

@Injectable()
export class SharingPolicyEffects {
    loadSharingPolicies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharingActions.loadSharingPolicies),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.getSharingPolicies(_tenant).pipe(
                    map((data: SharingPolicy[]) => SharingActions.loadSharingPoliciesSuccess({ _tenant, data })),
                    catchError((error) => of(SharingActions.loadSharingPoliciesFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    private getSharingPolicies(tenant: string): Observable<Array<SharingPolicy>> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/exo/sharing-policy');
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
