import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, expand, mergeMap, reduce } from 'rxjs/operators';
import { fetchAlerts, fetchAlertsFailure, fetchAlertsSuccess, upsertAlerts } from './actions';
import { AlertItem } from './model';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

@Injectable()
export class AlertEffects {
    private fetchWithPaging<T>(_tenant: string, start: string, end: string) {
        return this.ajax.get(_tenant, `/api/octiga/v3/tenant/alerts?start=${start}&end=${end}`).pipe(
            expand((data) => {
                if (data.LastEvaluatedKey) {
                    const lek = encodeURIComponent(JSON.stringify(data.LastEvaluatedKey));
                    return this.ajax.get<T[]>(
                        _tenant,
                        `/api/octiga/v3/tenant/alerts?start=${start}&end=${end}&LastEvaluatedKey=${lek}`,
                    );
                } else {
                    return EMPTY;
                }
            }),
            reduce((acc, data: any) => acc.concat(data.Items), []),
        );
    }

    fetchAlerts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchAlerts),
            // distinct(action => action._tenant), TODO: write checks
            mergeMap(({ _tenant, start, end }) =>
                this.fetchWithPaging<AlertItem[]>(_tenant, start, end).pipe(
                    concatMap((alerts) => {
                        {
                            return [
                                upsertAlerts({ _tenant, alerts }),
                                fetchAlertsSuccess({
                                    _tenant,
                                    start,
                                    end,
                                    alerts,
                                }),
                            ];
                        }
                    }),
                    catchError((error) => of(fetchAlertsFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>,
    ) {}
}
