import { User } from '@microsoft/microsoft-graph-types-beta';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Status, initialStatus } from './../../../status.interface';
import * as UserActions from './user.actions';

export const featureKey = 'graphUsers';

interface UserStatus extends Status {
    sigInDatesError: boolean;
}

export interface State extends EntityState<User>, UserStatus {
    // additional entities state properties
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    ...initialStatus,
    sigInDatesError: false,
});

export const reducer = createReducer(
    initialState,
    on(UserActions.reloadGraphUsers, (state) => ({ ...state, loading: true })),
    on(UserActions.loadGraphUsers, (state) => ({ ...state, loading: true })),
    on(UserActions.loadGraphUsersSuccess, (state, action) =>
        adapter.setAll(action.users, { ...state, loading: false, loaded: true }),
    ),
    on(UserActions.loadGraphUsersFailure, (state, { error }) => ({ ...state, error, loading: false, loaded: false })),
    on(UserActions.loadGraphUserSignInDatesFailure, (state) => ({ ...state, sigInDatesError: true })),

    on(UserActions.deleteGraphUsers, (state) => ({ ...state, deleting: true })),

    on(UserActions.deleteGraphUsersSuccess, (state, action) =>
        adapter.removeMany(action.ids, { ...state, deleting: false }),
    ),
    on(UserActions.deleteGraphUsersFailure, (state, { error }) => ({ ...state, error, loading: false, loaded: false })),

    on(UserActions.createGraphUser, (state) => ({ ...state, creating: true })),

    on(UserActions.createGraphUsersSuccess, (state, action) =>
        adapter.addOne({ ...action.user }, { ...state, creating: false }),
    ),
    on(UserActions.reloadUserWithSignInActivitySuccess, (state, action) =>
        adapter.upsertOne({ ...action.user }, { ...state, updating: false }),
    ),
    on(UserActions.createGraphUsersFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(UserActions.assignLicense, (state) => ({ ...state, updating: true })),

    on(UserActions.assignLicenseSuccess, (state, action) =>
        adapter.upsertOne({ ...action.user }, { ...state, updating: false }),
    ),
    on(UserActions.assignLicenseFailure, (state, { error }) => ({ ...state, error, updating: false })),

    on(UserActions.updateGraphUserSuccess, (state, action) =>
        adapter.updateOne({ id: action.userId, changes: { ...action.data } }, { ...state, updating: false }),
    ),

    on(UserActions.createGraphUsersWithRole, (state) => ({ ...state, creating: true })),

    on(UserActions.createGraphUsersWithRoleSuccess, (state, action) =>
        adapter.addMany(action.users, { ...state, creating: false }),
    ),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export function SystemUserFilter(user: User): boolean {
    return (
        !user.userPrincipalName.includes('urn:spo:anon#') &&
        !user.userPrincipalName.includes('octiga_service_') &&
        !user.userPrincipalName.includes('app@sharepoint') &&
        !user.userPrincipalName.includes('DiscoverySearchMailbox')
    );
}

export function MailboxUserFilter(user: User): boolean {
    return user.mail !== null && user.userType !== 'Guest';
}

export function InternalUsersFilter(user: User): boolean {
    return user.userType !== 'Guest';
}

export const selectGraphUserStatus = (state: any): UserStatus => {
    if (!state) return { ...initialStatus, sigInDatesError: false };
    const { loading, loaded, error, creating, updating, count, updateCount, sigInDatesError } = state;
    return { loading, loaded, error, creating, updating, count, updateCount, sigInDatesError };
};
