import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { Category } from 'projects/oct-report-lib/src/public-api';
import { EntityStatus, initialStatus } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'ReportCategory';

export interface State extends EntityState<Category>, EntityStatus {}

export const adapter = createEntityAdapter<Category>({
    selectId: (category: Category) => `${category.name}`,
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadReportCategories, (state) => ({ ...state, loading: !state.loaded })),
    on(actions.loadReportCategoriesSuccess, (state, { data }) => {
        return adapter.setAll(data, { ...state, loading: false, loaded: true });
    }),
    on(actions.loadReportCategoriesFailure, (state, { error }) => ({ ...state, error, loading: false })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
