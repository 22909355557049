import { MessageRuleActions, MessageRulePredicates, User } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';
import { InboxRuleModel } from './model';
import { ChangeType } from 'projects/angular-clarity/src/app/interfaces/changes.interface';
import { EXOInboxRule } from 'projects/angular-clarity/src/app/interfaces/powershell/exo/inbox-rule.interface';

export const fetchGraphInboxRules = createAction(
    '[Graph/Inbox Rule] Fetch',
    props<{ _tenant: string; users: User[] }>(),
);

export const fetchGraphInboxRulesSuccess = createAction(
    '[Graph/Inbox Rule] Fetch Success',
    props<{ _tenant: string; rules: InboxRuleModel[] }>(),
);

export const fetchGraphInboxRulesFailure = createAction(
    '[Graph/Inbox Rule] Fetch Failure',
    props<{ _tenant: string; error: any }>(),
);

export const fetchExoInboxRule = createAction(
    '[EXO/Inbox Rule] Fetch',
    props<{ _tenant: string; user_id: string; displayName: string }>(),
);
export const fetchExoInboxRuleSuccess = createAction(
    '[EXO/Inbox Rule] Fetch Success',
    props<{ _tenant: string; user_id: string; rule: EXOInboxRule }>(),
);

export const fetchExoInboxRuleFailure = createAction(
    '[EXO/Inbox Rule] Fetch Failure',
    props<{ _tenant: string; user_id: string; displayName: string; error: any }>(),
);

export const toggleInboxRule = createAction(
    '[Graph/Inbox Rule] Toggle Inbox Rule',
    (item: {
        _tenant: string;
        mailbox_id: string;
        user: string;
        rule_id: string;
        displayName: string;
        actions: MessageRuleActions;
        conditions: MessageRulePredicates;
        isEnabled: boolean;
    }) => {
        const changeType: ChangeType = 'inbox-rule';
        return {
            ...item,
            changeType,
        };
    },
);

export const toggleInboxRuleSuccess = createAction(
    '[Graph/Inbox Rule] Toggle Inbox Rule Success',
    props<{ _tenant: string; mailbox_id: string; rule_id: string; isEnabled: boolean }>(),
);

export const toggleInboxRuleFailure = createAction(
    '[Graph/Inbox Rule] Toggle Inbox Rule Failure',
    props<{ _tenant: string; error: any }>(),
);
