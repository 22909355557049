import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, distinct } from 'rxjs/operators';
import { Baseline } from './../../../../../stores/client/sway/baseline/model';
import {
    deleteBaselineSuccess,
    updateBaselineSuccess,
    createBaselineSuccess,
} from './../../../../../stores/client/sway/baseline/actions';
import * as actions from './actions';
import { TemplateItem } from './model';
import { MspAjaxService } from 'projects/angular-clarity/src/app/services/ajax/msp-ajax.service';

interface InsertResponse {
    inserted_baselines: Array<Baseline>;
    deleted_baselines: Array<Baseline>;
    records?: Array<TemplateItem>;
    response?: Array<TemplateItem>;
}

interface UpdateResponse {
    updated_baselines: Array<Baseline>;
    updated_item?: TemplateItem;
}

@Injectable()
export class SwayTemplateItemsEffects {
    loadTemplateItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadTemplateItems),
            distinct((action) => action.msp_id),
            mergeMap(({ msp_id }) =>
                this.get(msp_id).pipe(
                    map((items: any) => actions.loadTemplateItemsSuccess({ items: items })),
                    catchError((error) => of(actions.loadTemplateItemsFailure({ error }))),
                ),
            ),
        ),
    );

    createTemplateItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createTemplateItem),
            mergeMap(({ msp_id, template_id, item, _type, required_plans }) =>
                this.post(msp_id, template_id, item, _type, required_plans).pipe(
                    tap((res) => {
                        if (res.inserted_baselines && res.inserted_baselines instanceof Array) {
                            res.inserted_baselines.forEach((baseline) => {
                                this.store.dispatch(
                                    createBaselineSuccess({ _tenant: baseline.tenant_id, data: { ...baseline } }),
                                );
                            });
                        }

                        if (res.deleted_baselines && res.deleted_baselines instanceof Array) {
                            res.deleted_baselines.forEach((baseline) => {
                                this.store.dispatch(
                                    deleteBaselineSuccess({ _tenant: baseline.tenant_id, baselineId: baseline.id }),
                                );
                            });
                        }
                    }),
                    map((res: any) => actions.createTemplateItemSuccess({ item: res.inserted_item })),
                    catchError((error) => of(actions.createTemplateItemFailure({ error }))),
                ),
            ),
        ),
    );

    updateTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateTemplateItem),
            mergeMap(({ msp_id, _type, update_auto_remediation: update_auto_remediation, template_item }) =>
                this.put(msp_id, template_item, _type, update_auto_remediation).pipe(
                    tap((res) => {
                        if (res.updated_baselines && res.updated_baselines instanceof Array) {
                            res.updated_baselines.forEach((baseline) => {
                                this.store.dispatch(
                                    updateBaselineSuccess({ _tenant: baseline.tenant_id, data: { ...baseline } }),
                                );
                            });
                        }

                        return res;
                    }),
                    map((res) => actions.updateTemplateItemSuccess({ item: res.updated_item })),
                    catchError((error) => of(actions.updateTemplateItemFailure({ error }))),
                ),
            ),
        ),
    );

    deleteTemplateItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteTemplateItem),
            mergeMap(({ msp_id, template_id, id, deleteBaselines }) =>
                this.delete(msp_id, template_id, id, deleteBaselines).pipe(
                    mergeMap((_) => [actions.deleteTemplateItemSuccess({ id })]),
                    catchError((error) => of(actions.deleteTemplateItemFailure({ error }))),
                ),
            ),
        ),
    );

    get(msp_id: string) {
        return this.ajax
            .get<{ records: TemplateItem[] }>(`/api/sway/msp/${msp_id}/template/item`)
            .pipe(map((res) => res.records));
    }

    post(msp_id: string, template_id: string, item: TemplateItem, _type: 'tenant' | 'group', required_plans) {
        return this.ajax.post<InsertResponse>(`/api/sway/msp/${msp_id}/template/${template_id}/item?type=${_type}`, {
            item,
            required_plans,
        });
    }

    put(msp_id: string, template_item: Partial<TemplateItem>, type: string, update_auto_remediation: boolean) {
        if (update_auto_remediation) {
            return this.ajax.put<UpdateResponse>(
                `/api/sway/msp/${msp_id}/template/${template_item.template_id}/item/${template_item.id}?type=${type}&update_auto_remediation=${update_auto_remediation}`,
                template_item,
            );
        } else {
            return this.ajax.put<UpdateResponse>(
                `/api/sway/msp/${msp_id}/template/${template_item.template_id}/item/${template_item.id}?type=${type}`,
                template_item,
            );
        }
    }

    delete(msp_id: string, template_id: string, item_id: string, deleteBaselines: boolean) {
        return this.ajax.delete<any>(
            `/api/sway/msp/${msp_id}/template/${template_id}/item/${item_id}?deleteBaselines=${deleteBaselines}`,
        );
    }

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
        private store: Store<any>,
    ) {}
}
