import { Injectable } from '@angular/core';
import { OAuth2PermissionGrant, ServicePrincipal } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { loadServicePrincipalsSuccess } from '../actions';
import * as actions from './actions';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

interface Response {
    '@odata.context': string;
    value: OAuth2PermissionGrant[];
}

@Injectable()
export class GraphServicePrincipalOAuth2PermissionGrantsEffects {
    listen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadServicePrincipalsSuccess),
            mergeMap(({ _tenant, data }) =>
                data.map((sp) => actions.loadServicePrincipalOAuth2PermissionGrants({ _tenant, id: sp.id })),
            ),
        ),
    );

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadServicePrincipalOAuth2PermissionGrants),
            mergeMap(({ _tenant, id }) =>
                this.get(_tenant, id).pipe(
                    map((response) => response.value),
                    map((data) => actions.loadServicePrincipalOAuth2PermissionGrantsSuccess({ _tenant, data, id })),
                ),
            ),
        ),
    );

    get(tenant: string, id: string) {
        return this.ajax.get<Response>(tenant, `/api/microsoft/graph/servicePrincipals/${id}/oauth2PermissionGrants`);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
