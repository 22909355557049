import { updateCasMailbox } from 'projects/angular-clarity/src/app/stores/client/powershell/exo/cas-mailbox/actions';
import { CasMailbox } from 'projects/angular-clarity/src/app/stores/client/powershell/exo/cas-mailbox/model';

export { ValidatorComponent } from './validator.component';
export { FormComponent } from './form.component';

export const fieldName = 'SmtpClientAuthenticationDisabled';
export const href =
    'https://learn.microsoft.com/en-us/exchange/clients-and-mobile-in-exchange-online/authenticated-client-smtp-submission';
export function Remediate(_tenant: string, current: CasMailbox, schema: any) {
    if (schema.properties[fieldName]?.const === true) {
        const data = {
            Identity: current.ExternalDirectoryObjectId,
            [fieldName]: true,
        };
        return updateCasMailbox({ _tenant, data, field: fieldName });
    } else if (schema.properties[fieldName]?.const === false) {
        const data = {
            Identity: current.ExternalDirectoryObjectId,
            [fieldName]: false,
        };
        return updateCasMailbox({ _tenant, data, field: fieldName });
    } else if (schema.properties[fieldName]?.const === null) {
        const data = {
            Identity: current.ExternalDirectoryObjectId,
            [fieldName]: null,
        };
        return updateCasMailbox({ _tenant, data, field: fieldName });
    }
}
