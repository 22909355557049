import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { initialStatus, Status } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'geoJson';

export interface State extends Status {
    data: any;
}

export const initialState: State = {
    ...initialStatus,
    data: null,
};

export const reducer = createReducer(
    initialState,
    on(actions.loadGeoJsonSuccess, (state, { data }) => ({
        ...state,
        loading: false,
        loaded: true,
        data,
    })),
);

export const geoJsonData = (state: State) => state.data;
