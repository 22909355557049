import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Changes } from 'projects/angular-clarity/src/app/interfaces/changes.interface';

export const fetchChanges = createAction(
    '[Octiga/Changes] Fetch Changes Log',
    props<{ _tenant: string; start: string; end: string }>(),
);

export const fetchChangesSuccess = createAction(
    '[Octiga/Changes] Fetch Changes Log Success',
    props<{ _tenant: string; changes: Changes[]; fetchedDates: string[] }>(),
);

export const fetchChangesFailure = createAction(
    '[Octiga/Changes] Fetch Changes Log Failure',
    props<{ _tenant: string; error: any }>(),
);

export const storeChangesToDB = createAction(
    '[Octiga/Changes] Store Changes Log Item',
    props<{ _tenant: string; item: Changes }>(),
);

export const storeChangesToDBSuccess = createAction(
    '[Octiga/Changes] Store Changes Log Item Success',
    props<{ _tenant: string; item: Changes }>(),
);

export const storeChangesToDBFailure = createAction(
    '[Octiga/Changes] Store Changes Log Item Failure',
    props<{ _tenant: string; error: any }>(),
);

export const addChange = createAction(
    '[Octiga/Changes] Add Change Log Item',
    props<{ _tenant: string; change: Changes }>(),
);

export const upsertChange = createAction(
    '[Octiga/Changes] Upsert Change Log Item',
    props<{ _tenant: string; change: Changes }>(),
);

export const addChanges = createAction(
    '[Octiga/Changes] Add Changes Log Items',
    props<{ _tenant: string; changes: Changes[] }>(),
);

export const upsertChanges = createAction(
    '[Octiga/Changes] Upsert Changes Log Items',
    props<{ _tenant: string; changes: Changes[] }>(),
);

export const updateChange = createAction(
    '[Octiga/Changes] Update Changes Log Item',
    props<{ _tenant: string; change: Update<Changes> }>(),
);

export const updateChanges = createAction(
    '[Octiga/Changes] Update Changes Log Items',
    props<{ _tenant: string; changes: Update<Changes>[] }>(),
);

export const deleteChange = createAction(
    '[Octiga/Changes] Delete Changes Log Item',
    props<{ _tenant: string; id: string }>(),
);

export const deleteChanges = createAction(
    '[Octiga/Changes] Delete Changes Log Items',
    props<{ _tenant: string; ids: string[] }>(),
);

export const clearChanges = createAction('[Octiga/Changes] Clear Changes Log', props<{ _tenant: string }>());
