import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, map, take } from 'rxjs/operators';
import * as actions from './actions';
import { MspAjaxService } from 'projects/angular-clarity/src/app/services/ajax/msp-ajax.service';

@Injectable()
export class SwayCategoryEffects {
    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadSwayCategories),
            take(1),
            mergeMap(() =>
                this.get().pipe(
                    map((data) => actions.loadSwayCategoriesSuccess({ data })),
                    catchError((error) => of(actions.loadSwayCategoriesFailure({ error }))),
                ),
            ),
        ),
    );

    /**
     * @description
     * get all categories from a specific tenant (SWAY database)
     * @param {string} tenant - tenant ID (string)
     */

    private get() {
        return this.ajax.get('/api/sway/category');
    }

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
    ) {}
}
