import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import {
    createConditionalAccessPolicy,
    deleteConditionalAccessPolicy,
    updateConditionalAccessPolicy,
} from 'projects/angular-clarity/src/app/stores/client/graph/conditional-access/actions';

export const displayName = 'Octiga Generated Disable Legacy Auth';

// Cloud App Security administrator

const Policy: ConditionalAccessPolicy = {
    displayName: displayName,
    state: 'enabled',
    conditions: {
        users: {
            includeUsers: ['all'],
        },
        applications: {
            includeApplications: ['all'],
        },
        clientAppTypes: ['other', 'exchangeActiveSync'],
    },
    grantControls: {
        operator: 'OR',
        builtInControls: ['block'],
    },
};

export function Remediate(_tenant: string, data: ConditionalAccessPolicy[], schema: any) {
    const state = schema?.contains?.properties?.state?.const;
    const policy = data.find((res) => res.displayName === displayName);

    if (!policy) {
        // create MFA Policy if it is not exsit
        if (state === 'enabled') {
            return createConditionalAccessPolicy({ _tenant, policy: Policy });
        }
    }

    if (policy) {
        if (state === 'enabled') {
            return updateConditionalAccessPolicy({ _tenant, policy: { id: policy.id, state } });
        } else {
            return deleteConditionalAccessPolicy({ _tenant, id: policy.id });
        }
    }
}
