import { Injectable } from '@angular/core';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, mergeMap, last, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { retry } from 'projects/angular-clarity/src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

@Injectable()
export class ConditionalAccessPolicyEffects {
    loadConditionalAccessPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadConditionalAccessPolicy),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.ajax
                    .get<
                        Array<ConditionalAccessPolicy>
                    >(_tenant, '/api/microsoft/graph/identity/conditionalAccess/policies')
                    .pipe(
                        retry(3000, 3, 'conditional access policy timeout'),
                        last(),
                        map((data: any) => {
                            return actions.loadConditionalAccessPolicySuccess({ _tenant, polices: data.value });
                        }),
                        catchError((error) => of(actions.loadConditionalAccessPolicyFailure({ _tenant, error }))),
                    ),
            ),
        ),
    );

    createConditionalAccessPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createConditionalAccessPolicy),
            mergeMap(({ _tenant, policy }) =>
                this.ajax
                    .post<ConditionalAccessPolicy>(
                        _tenant,
                        '/api/microsoft/graph/identity/conditionalAccess/policies',
                        policy,
                    )
                    .pipe(
                        map((policy: ConditionalAccessPolicy) =>
                            actions.createConditionalAccessPolicySuccess({ _tenant, policy }),
                        ),
                        catchError((error) => of(actions.createConditionalAccessPolicyFailure({ _tenant, error }))),
                    ),
            ),
        ),
    );

    updateConditionalAccessPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateConditionalAccessPolicy),
            mergeMap(({ _tenant, policy }) =>
                this.update(_tenant, policy)

                    .pipe(
                        retry(3000, 3, 'conditional access policy timeout'),
                        last(),
                        map((res) => actions.updateConditionalAccessPolicySuccess({ _tenant, policy: { ...policy } })),
                        catchError((error) => of(actions.updateConditionalAccessPolicyFailure({ _tenant, error }))),
                    ),
            ),
        ),
    );

    update(_tenant: string, policy: ConditionalAccessPolicy) {
        const request$ = this.ajax.patch<ConditionalAccessPolicy>(
            _tenant,
            '/api/microsoft/graph/identity/conditionalAccess/policies/' + policy.id,
            policy,
        );

        return request$.pipe(
            switchMap((res) =>
                this.ajax.get(_tenant, `/api/microsoft/graph/identity/conditionalAccess/policies/${policy.id}`),
            ),
        );
    }

    deleteConditionalAccessPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteConditionalAccessPolicy),
            mergeMap(({ _tenant, id }) =>
                this.ajax
                    .delete<string>(_tenant, '/api/microsoft/graph/identity/conditionalAccess/policies/' + id)
                    .pipe(
                        retry(3000, 3, 'conditional access policy timeout'),
                        last(),
                        map(() => actions.deleteConditionalAccessPolicySuccess({ _tenant, id })),
                        catchError((error) => of(actions.deleteConditionalAccessPolicyFailure({ _tenant, error }))),
                    ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
