import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import * as actions from './actions';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';

@Injectable()
export class ReportBaselineEffects {
    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadBaselines),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.get(_tenant).pipe(
                    map((data) => {
                        return actions.loadBaselinesSuccess({ _tenant, data });
                    }),
                    catchError((error) => of(actions.loadBaselinesFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    /**
     * @description
     * get all baselines from a specific tenant (SWAY database)
     * @param {string} tenant - tenant ID (string)
     */
    private get(tenant: string) {
        return this.ajax.get(tenant, `/api/report/tenant/${tenant}/baselines`);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>,
    ) {}
}
