import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { ServicePrincipal_AppRoleAssignments } from './model';
import { initialStatus, Status } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'graphServicePrincipals';

export interface State extends EntityState<ServicePrincipal_AppRoleAssignments>, Status {
    // additional entities state properties
}

export const adapter: EntityAdapter<ServicePrincipal_AppRoleAssignments> =
    createEntityAdapter<ServicePrincipal_AppRoleAssignments>();

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,

    on(actions.loadServicePrincipalsSuccess, (state, action) =>
        adapter.setAll(action.data, { ...state, error: false, loaded: true }),
    ),
    on(actions.loadServicePrincipalsFailure, (state, { error }) => ({ ...state, error, loaded: true })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
