import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    AppListItem,
    DeviceCompliancePolicyAssignment,
    IosCompliancePolicy,
} from '@microsoft/microsoft-graph-types-beta';
import { BaseComponent } from '../../../base/base.component';
import * as IOSCompliancePolicyHelper from 'projects/angular-clarity/src/app/modules/sway/tools/schemas/compliance-policies/IOSCompliancePolicy';
import { initForm } from 'projects/angular-clarity/src/app/modules/sway/tools/schemas/compliance-policies/IOSCompliancePolicy';
import { IOSCompliancePolicySchema } from 'projects/angular-clarity/src/app/modules/sway/tools/schemas/compliance-policies/IOSCompliancePolicy/model';
import { PolicyAssignment } from 'projects/angular-clarity/src/app/stores/client/graph/compliance-policies/policies/model';

// TODO, Validate od Min version

// TODO, filter inclusion.

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit, OnChanges {
    errorString = ''; // string contains all errors
    tooltips = IOSCompliancePolicyHelper.tooltips;
    form: FormGroup;
    formErrors;

    @Input() data: PolicyAssignment[];

    policy: IosCompliancePolicy;
    assignments: DeviceCompliancePolicyAssignment[] = [];

    loaded: boolean;

    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.loadData();
    }

    ngOnInit(): void {
        this.loadData();

        this.form = initForm(this.baseline.schema, this.fb);
        this.loaded = true;

        this.observePolicy();
    }

    setErrorString(): void {
        this.errorString = '';
        this.errors.forEach((res) => {
            this.errorString += '!' + res.instancePath + '!';
        });
    }

    loadData(): void {
        const displayName = this.baseline.schema.contains.properties.policy.properties.displayName?.const;
        const policy_assignments = this.data.find((res) => res?.policy?.displayName === displayName);

        this.policy = policy_assignments?.policy;

        this.assignments = policy_assignments?.assignments || [];

        this.setErrorString();
    }

    observePolicy() {
        const schema: IOSCompliancePolicySchema = JSON.parse(JSON.stringify(this.baseline.schema));

        this.form.valueChanges.subscribe((res) => {
            const updatedSchema = IOSCompliancePolicyHelper.updateSchema(this.formValue, schema);

            console.log(updatedSchema, 'schema');
            const formHasError = Object.keys(this.form.controls).some((control) => this.form.get(control).invalid);

            const nowGroupAssignments = updatedSchema.contains.properties.assignments?.maxItems === 0;

            this.formErrors = formHasError || nowGroupAssignments;

            if (this.formErrors) {
                this.baselineErrorChange.next({ remediate: true, save: true });
            } else {
                this.baselineErrorChange.next({ remediate: false, save: false });
            }

            this.setErrorString();

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(updatedSchema)),
            });
        });
    }

    markFormAsDirty(): void {
        this.form.markAsDirty();
        this.form.markAllAsTouched();
    }

    addRestrictedAppRow(): void {
        const newItem = this.addRestrictedApp();
        (this.form.get('restrictedApps') as FormArray).push(newItem);
    }

    addRestrictedApp(item?: AppListItem): FormGroup {
        return this.fb.group({
            name: [item?.name, [Validators.required]],
            appId: [item?.appId, [Validators.required]],
        });
    }

    removeRestrictedAppRow(index: number): void {
        (this.form.get('restrictedApps') as FormArray).removeAt(index);
    }

    get formValue() {
        return this.form.getRawValue();
    }

    get passcodeRequired() {
        return this.formValue.passcodeRequired;
    }

    get assignmentsValue() {
        return this.formValue.assignments;
    }

    get restrictedAppsControls() {
        return (this.form.get('restrictedApps') as FormArray).controls;
    }
}
