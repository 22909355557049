export { FormComponent } from './form.component';
import { User } from '@microsoft/microsoft-graph-types-beta';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, sample } from 'rxjs';
import { SwaySpec } from '../../../../../../../stores/root/sway/spec/model';
import { Baseline } from '../../../../../../../stores/client/sway/baseline/model';
import { BooleanValidatorComponent } from '../../generic/boolean';

import { UserExclusion } from '../../../group-spec-registry.service';
import { client } from 'projects/angular-clarity/src/app/stores/client';
import { Status } from 'projects/angular-clarity/src/app/stores/status.interface';
import { updatePerUserMfaState } from 'projects/angular-clarity/src/app/stores/client/graph/authentication/perUserMfaState/actions';
import { PerUserMfaState } from 'projects/angular-clarity/src/app/stores/client/graph/authentication/perUserMfaState/model';

export const fieldName = 'perUserMfaState';
export const href =
    'https://learn.microsoft.com/en-us/microsoft-365/admin/security-and-compliance/multi-factor-authentication-microsoft-365?view=o365-worldwide';
export interface ExtendedUser extends User {
    roles: string[];
    groups: string[];
}

export { BooleanValidatorComponent as ValidatorComponent };

export function fetch_data_status(store: Store<any>, tenant_id) {
    // users
    const usersReady$ = store.select(client(tenant_id).graph.users.status).pipe(filter((res) => res.loaded));

    // peruser mfa
    const perUserMfaStateReady$ = store
        .select(client(tenant_id).graph.perUserMfaState.status)
        .pipe(filter((res) => res.loaded));

    return combineLatest([usersReady$, perUserMfaStateReady$]).pipe(
        map(([user_ready, perUserMfaReady]) => ({
            loaded: user_ready.loaded && perUserMfaReady.loaded,
            loading: user_ready.loading && perUserMfaReady.loading,
            error: user_ready.error && perUserMfaReady.error,
            updating: user_ready.updating && perUserMfaReady.updating,
        })),
    );
}

export function fetch_data(store: Store<any>, tenant_id) {
    // users
    const usersReady$ = store.select(client(tenant_id).graph.users.status).pipe(filter((res) => res.loaded));

    const users$ = store.select(client(tenant_id).graph.users.all).pipe(sample(usersReady$));

    // perUserMfaState
    const perUserMfaStateReady$ = store
        .select(client(tenant_id).graph.perUserMfaState.status)
        .pipe(filter((res) => res.loaded));
    const perUserMfaStates$ = store
        .select(client(tenant_id).graph.perUserMfaState.all)
        .pipe(sample(perUserMfaStateReady$));

    // role members
    const directoryRoleMembersReady$ = store
        .select(client(tenant_id).graph.directoryRole.members.status)
        .pipe(filter((res) => res.loaded));
    const directoryRoleMembers$ = store
        .select(client(tenant_id).graph.directoryRole.members.all)
        .pipe(sample(directoryRoleMembersReady$));

    // group members
    const groupsMemberReady$ = store
        .select(client(tenant_id).graph.groups.members.status)
        .pipe(filter((res) => res.loaded));
    const groups$ = store.select(client(tenant_id).graph.groups.all).pipe(sample(groupsMemberReady$));

    return combineLatest([users$, perUserMfaStates$, directoryRoleMembers$, groups$]).pipe(
        map(([users, perUserMfaStates, roles, groups]) => {
            const user_data: any[] = [];

            for (const user of users) {
                const perUserMfaState = perUserMfaStates.find((item) => item.userId === user.id)?.value;
                // filter roles where user is member

                const userRoles = roles
                    .filter((role) => role?.members?.some((member) => member.id === user.id))
                    .map((role) => role.roleTemplateId);

                const userGroups = groups
                    .filter((group) => group.members.some((member) => member.id === user.id))
                    .map((group) => group.id);

                user_data.push({
                    ...user,
                    roles: userRoles,
                    groups: userGroups,
                    perUserMfaState,
                });
            }
            return user_data;
        }),
    );
}

export function select_status(store: Store<any>, tenant_id): Observable<Status> {
    // users
    const usersReady$ = store.select(client(tenant_id).graph.users.status).pipe(filter((res) => res.loaded));

    // perUserMfa Ready
    const PerUserMfaReady$ = store
        .select(client(tenant_id).graph.perUserMfaState.status)
        .pipe(filter((res) => res.loaded));

    return combineLatest([usersReady$, PerUserMfaReady$]).pipe(
        map(([userStatus, perUserMfaState]) => ({
            loading: userStatus.loading || perUserMfaState.loading,
            loaded: userStatus.loaded && perUserMfaState.loaded,
            updating: userStatus.updating || perUserMfaState.updating,
            error: userStatus.error || perUserMfaState.error,
        })),
    );
}

function excludeUserOnTenantBaseline(baseline: Baseline, user: ExtendedUser): UserExclusion {
    const schema = baseline.schema;
    const includeRoles: string[] =
        schema?.contains?.properties.conditions.properties?.users?.properties?.includeRoles?.items?.enum;
    const includeUsers: string[] =
        schema?.contains?.properties.conditions.properties?.users?.properties?.includeUsers?.items?.enum;
    const includeGroups: string[] =
        schema?.contains?.properties.conditions.properties?.users?.properties?.includeGroups?.items?.enum;

    if (includeUsers?.[0]?.toLocaleLowerCase() === 'all') {
        // if (isRoleExcluded || isUserExcluded || isGroupExcluded) {
        // return;
        // }

        return {
            type: 'tenant_baseline_override',
            desc: 'Excluded because this user is manged by MFA conditional access policy and all users included.', //
            icon: 'arrow_upward',
        };
    } else if (includeUsers?.[0]?.toLocaleLowerCase() === 'none') {
        return;
    } else {
        const isRoleIncluded = user.roles?.some((userRole) =>
            includeRoles?.some((includedRole) => includedRole === userRole),
        );
        const isUserIncluded = includeUsers?.some((includedUser) => includedUser === user.id);
        const isGroupIncluded = user.groups.some((userGroup) =>
            includeGroups?.some((includedGroup) => includedGroup === userGroup),
        );

        if (isUserIncluded) {
            // we don't care about exclusion because we explicitly included the user.
            return {
                type: 'tenant_baseline_override',
                desc: 'Excluded because this user is manged by MFA conditional access policy.',
                icon: 'arrow_upward',
            };
        } else if (isRoleIncluded) {
            return {
                type: 'tenant_baseline_override',
                desc: 'Excluded because this user is an admin user and is manged by MFA conditional access policy.',
                icon: 'arrow_upward',
            };
        } else if (isGroupIncluded) {
            return {
                type: 'tenant_baseline_override',
                desc: 'Excluded because this user is manged by MFA conditional access policy.',
                icon: 'arrow_upward',
            };
        }
    }
}

export function exclude(user: ExtendedUser, specs: SwaySpec[], baselines: Baseline[]): UserExclusion | undefined {
    if (!user.accountEnabled) {
        return {
            desc: 'Excluded because this user is blocked from sign-in.',
            type: 'sign-in_blocked',
            icon: 'not_interested',
            class: 'na-icon',
        };
    } else if (user.userType === 'Guest') {
        return {
            type: 'guest_user',
            desc: 'Excluded because this user is a guest user.',
            icon: 'not_interested',
            class: 'na-icon',
        };
    }
    // else if (
    //     user.assignedPlans.filter((plan) => plan.capabilityStatus === 'Enabled')
    //         .length === 0
    // ) {
    //     return {
    //         status: true,
    //         desc: 'Excluded because this user does not have any assigned plans.',
    //     };
    // }

    const MFASpec = specs.find((spec) => spec.tag === 'MfaConditionalAccessPolicy');
    const enforceMFASpec = specs.find((spec) => spec.tag === 'CAPEnforceMFA');

    const MFABaseline = baselines.find((baseline) => baseline.spec_id === MFASpec?.id);
    const enforceMFABaseline = baselines.find((baseline) => baseline.spec_id === enforceMFASpec?.id);

    if (enforceMFABaseline) {
        return excludeUserOnTenantBaseline(enforceMFABaseline, user);
    }

    if (MFABaseline) {
        return excludeUserOnTenantBaseline(MFABaseline, user);
    }

    return undefined;
}

export function Remediate(_tenant: string, current: User, schema: any) {
    let perUserMfaState: PerUserMfaState = 'disabled';

    if (schema.properties[fieldName]?.enum) {
        perUserMfaState = 'enabled';
    }

    return updatePerUserMfaState({ _tenant, userId: current.id, perUserMfaState });
}
