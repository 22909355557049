import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, distinct, map, mergeMap, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { Tenant } from 'projects/angular-clarity/src/app/interfaces/tenant.interface';
import { TenantAjaxService } from 'projects/angular-clarity/src/app/services/ajax/tenant-ajax.service';
import { BlobService, cacheEnabledForTenant } from 'projects/angular-clarity/src/app/services/blob.service';

@Injectable()
export class TenantEffects {
    loadTenant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadTenant),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.getTenant(_tenant).pipe(
                    switchMap((data) => {
                        if (cacheEnabledForTenant(data)) {
                            return this.blobService
                                .load(data.msp_id, data.id)
                                .pipe(
                                    map((data_actions) => [
                                        actions.loadTenantSuccess({ _tenant, data }),
                                        ...data_actions,
                                    ]),
                                );
                        } else {
                            return of([actions.loadTenantSuccess({ _tenant, data })]);
                        }
                    }),
                    concatMap((res) => res),
                    catchError((error) => of(actions.loadTenantFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    deleteTenantRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteTenantRequest),
            concatMap(({ _tenant }) =>
                this.ajax.delete(_tenant, '/api/octiga/v3/tenant-request').pipe(
                    map((data) => actions.deleteTenantRequestSuccess({ _tenant, data })),
                    catchError((error) => of(actions.deleteTenantRequestFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    private getTenant(tenant: string): Observable<Tenant> {
        return this.ajax.get(tenant, '/api/octiga/v3/tenant');
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private blobService: BlobService,
    ) {}
}
