import { Classification, Meta } from "../../interfaces/meta.interface";
import { riskyFolders, riskyWords } from '../utils/riskWords';
import {
    fraud_ip_in_asn,
    fraud_ip_in_country,
    fraud_ip_in_ip,
    fraud_ip_in_region,
    fraud_ip_not_in_allowed_list,
    is_microsoft_activity,
    not_in_allowed_ip,
} from "../statements";

//const risky_folders: string[] = ["rss", "delete", "trash", "junk", "spam"];
const mail_forwarding = "A mail-forwarding rule has been created/modified.";
const move_item = "A move-to-folder rule has been created/modified.";
const fraud_ip = "It originated from a fraudulent IP.";
const forwards_outside = "It forwards mail outside of your organisation.";
const forwards_within = "It forwards mail within your organisation.";

const GetParam = (log: any, name: string) => {
    if (log.Parameters) {
        let param = log.Parameters.find((p: any) => p.Name === name);
        return param ? param.Value : undefined;
    }
};

type DistributionGroupMemberDescFields = {
    group: string;
    member: string;
};
type MailboxLocationDescFields = {
    mailboxId: string;
    mailboxLocationType: string;
};
type MailboxPermissionDescFields = {
    mailbox: string;
    accessRights: string;
};
type RecipientPermissionDescFields = {
    recipient: string;
    accessRights: string;
};
type UnifiedGroupLinksDescFields = {
    unifiedGroupId: string;
    recipientIds: string;
    linkType: string;
};
type EnableMailboxDescFields = {
    archive: boolean;
    user: string;
};

function GeneralEventClx(
    title: string,
    description: string
): Classification<any>[] {
    return [
        // fraudulent and not in allowed list
        {
            severity: "critical",
            title,
            description: `${description} ${fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // not in allowed list.
        {
            severity: "danger",
            title,
            description: `${description} ${not_in_allowed_ip}`,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false,
                isMicrosoftActivity: false,
            },
        },
        // fraudulent IP.
        {
            severity: "warning",
            title,
            description: `${description} ${fraud_ip}`,
            predicates: {
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        {
            severity: "info",
            title,
            description: `${description} ${is_microsoft_activity}`,
            predicates: {
                isMicrosoftActivity: true,
            },
        },
        {
            severity: "info",
            title,
            description: `${description}`,
        },
    ];
}

const enable_mailbox_description = (fields: EnableMailboxDescFields) => {
    return fields.archive
        ? `An Archive was created for the existing user named ${fields.user} who already has a mailbox.`
        : `A mailbox was created for the existing user named ${fields.user}.`;
};

// Classifications: Enable-Mailbox
const EnableMailboxClx: Classification<any, EnableMailboxDescFields>[] = [
    // enable mailbox. fraudulent and not in allowed list.
    {
        severity: "critical",
        title: "Mailbox for existing user Created",
        description: (fields: any) =>
            `${enable_mailbox_description(
                fields
            )} ${fraud_ip_not_in_allowed_list}`,
        predicates: {
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
            isFraudulentIP: true,
        },
    },
    // enable mailbox. not in allowed list.
    {
        severity: "danger",
        title: "Mailbox for existing user Created",
        description: (fields: any) =>
            `${enable_mailbox_description(fields)} ${not_in_allowed_ip}`,
        predicates: {
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
        },
    },
    // enable mailbox. fraudulent IP.
    {
        severity: "warning",
        title: "Mailbox for existing user Created",
        description: (fields: any) =>
            `${enable_mailbox_description(fields)} ${fraud_ip}`,
        predicates: {
            isFraudulentIP: true,
        },
    },
    // enable mailbox.
    {
        severity: "info",
        title: "Mailbox for existing user Created",
        description: (fields: any) => enable_mailbox_description(fields),
    },
];

type InboxRuleFields = {
    forwarding_set: string;
    move_item: boolean;
    risky_folders: boolean;
    risky_keywords: boolean;
};

// Classifications: InboxRule (TODO: Review)
const mail_forward_clx: Classification<InboxRuleFields>[] = [
    // mail forward: outside org and fraudulent IP and not in allowed asn/ country / region / IP
    {
        severity: "critical",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_outside} ${fraud_ip_not_in_allowed_list}`,
        predicates: {
            isForwardedOutsideOfOrg: true,
            isFraudulentIP: true,
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: outside org and fraudulent IP and not in allowed country/region, but in allowed IP
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_outside} ${fraud_ip_in_ip}`,
        predicates: {
            isForwardedOutsideOfOrg: true,
            isFraudulentIP: true,
            isAllowedIP: true,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: outside org and fraudulent IP and in allowed Region
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_outside} ${fraud_ip_in_region}`,
        predicates: {
            isForwardedOutsideOfOrg: true,
            isFraudulentIP: true,
            isAllowedRegion: true,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: outside org and fraudulent IP and in allowed country
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_outside} ${fraud_ip_in_country}`,
        predicates: {
            isAllowedCountry: true,
            isForwardedOutsideOfOrg: true,
            isFraudulentIP: true,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: outside org and fraudulent IP and in allowed asn
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_outside} ${fraud_ip_in_asn}`,
        predicates: {
            isAllowedASN: true,
            isForwardedOutsideOfOrg: true,
            isFraudulentIP: true,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: outside org and not in allowed country
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_outside} ${not_in_allowed_ip}`,
        predicates: {
            isForwardedOutsideOfOrg: true,
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: outside org and in allowed country.
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_outside}`,
        predicates: {
            isForwardedOutsideOfOrg: true,
            isAllowedCountry: true,
            isFraudulentIP: false,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: outside org and in allowed ASN.
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_outside}`,
        predicates: {
            isForwardedOutsideOfOrg: true,
            isAllowedASN: true,
            isFraudulentIP: false,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: outside org and in allowed region.
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_outside}`,
        predicates: {
            isForwardedOutsideOfOrg: true,
            isAllowedRegion: true,
            isFraudulentIP: false,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: outside org and in allowed IP.
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_outside}`,
        predicates: {
            isForwardedOutsideOfOrg: true,
            isAllowedIP: true,
            isFraudulentIP: false,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: inside org and fraudulent IP and not in allowed list
    {
        severity: "critical",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_within} ${fraud_ip_not_in_allowed_list}`,
        predicates: {
            isFraudulentIP: true,
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: inside org and fraudulent IP and not in allowed country and allowed IP
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_within} ${fraud_ip_in_ip}`,
        predicates: {
            isFraudulentIP: true,
            isAllowedIP: true,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: inside org and fraudulent IP and in allowed region
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_within} ${fraud_ip_in_region}`,
        predicates: {
            isFraudulentIP: true,
            isAllowedRegion: true,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: inside org and fraudulent IP and in allowed country
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_within} ${fraud_ip_in_country}`,
        predicates: {
            isFraudulentIP: true,
            isAllowedCountry: true,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: inside org and fraudulent IP and in allowed asn
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_within} ${fraud_ip_in_asn}`,
        predicates: {
            isFraudulentIP: true,
            isAllowedASN: true,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: inside org and not in allowed list
    {
        severity: "danger",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding} ${forwards_within} ${not_in_allowed_ip}`,
        predicates: {
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
        },
        fields: {
            forwarding_set: [true],
        },
    },
    // mail forward: inside org
    {
        severity: "info",
        title: "Mail-Forwarding Rule Created/Modified",
        description: `${mail_forwarding}`,
        fields: {
            forwarding_set: [true],
        },
    },
];

const move_to_folder_clx: Classification<InboxRuleFields>[] = [
    // move to folder: fraudulent and not in allowed list
    {
        severity: "critical",
        title: "Move-to-folder Rule Created/Modified",
        description: `${move_item} ${fraud_ip_not_in_allowed_list}`,
        predicates: {
            isFraudulentIP: true,
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
        },
        fields: {
            move_item: [true],
        },
    },
    // move to folder: dodgy folder. dodgy keyword.
    {
        severity: "critical",
        title: "Move-to-folder Rule Created/Modified",
        description: `${move_item} It matches risky keyword(s) and it moves to a suspicious folder.`,
        fields: {
            risky_folders: [true],
            risky_keywords: [true],
            move_item: [true],
        },
    },
    // move to folder: dodgy keyword.
    {
        severity: "danger",
        title: "Move-to-folder Rule Created/Modified",
        description: `${move_item} It contains risky keyword(s).`,
        fields: {
            risky_keywords: [true],
            move_item: [true],
        },
    },
    // move to folder: dodgy folder.
    {
        severity: "danger",
        title: "Move-to-folder Rule Created/Modified",
        description: `${move_item} It moves to a suspicious folder.`,
        fields: {
            risky_folders: [true],
            move_item: [true],
        },
    },
    // move to folder: fraudulent IP. allowed IP.
    {
        severity: "danger",
        title: "Move-to-folder Rule Created/Modified",
        description: `${move_item} ${fraud_ip_in_ip}`,
        predicates: {
            isFraudulentIP: true,
            isAllowedIP: true,
        },
        fields: {
            move_item: [true],
        },
    },
    // move to folder: fraudulent IP. allowed region.
    {
        severity: "danger",
        title: "Move-to-folder Rule Created/Modified",
        description: `${move_item} ${fraud_ip_in_region}`,
        predicates: {
            isFraudulentIP: true,
            isAllowedRegion: true,
        },
        fields: {
            move_item: [true],
        },
    },
    // move to folder: fraudulent IP in allowed country
    {
        severity: "danger",
        title: "Move-to-folder Rule Created/Modified",
        description: `${move_item} ${fraud_ip_in_country}`,
        predicates: {
            isAllowedCountry: true,
            isFraudulentIP: true,
        },
        fields: {
            move_item: [true],
        },
    },
    // move to folder: fraudulent IP in allowed asn
    {
        severity: "danger",
        title: "Move-to-folder Rule Created/Modified",
        description: `${move_item} ${fraud_ip_in_country}`,
        predicates: {
            isAllowedASN: true,
            isFraudulentIP: true,
        },
        fields: {
            move_item: [true],
        },
    },
    // move to folder: not in allowed list
    {
        severity: "danger",
        title: "Move-to-folder Rule Created/Modified",
        description: `${move_item} ${not_in_allowed_ip}`,
        predicates: {
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
        },
        fields: {
            move_item: [true],
        },
    },
    // move to folder:
    {
        severity: "info",
        title: "Move-to-folder Rule Created/Modified",
        description: move_item,
        fields: {
            move_item: [true],
        },
    },
];

type MailItemsAccessedFields = {
    risky_keyword: boolean;
};

function MailboxOperations(log: any) {
    return {
        login_type: log.InternalLogonType,
        internal_login_type: log.LogonType,
        result: log.OperationResult,
        external_access: log.ExternalAccess,
        owner: log.MailboxOwnerUPN,
        client: log.ClientInfoString,
        risky_keyword:
            !!log.AffectedItems && !!log.AffectedItems[0]["Subject"]
                ? riskyWords.some((word) =>
                    log.AffectedItems[0]["Subject"]
                        .toLowerCase()
                        .includes(word)
                )
                : false,
    };
}

function MailEventClx(
    title: string,
    description: string
): Classification<MailItemsAccessedFields>[] {
    return [
        // fraudulent and not in allowed list
        {
            severity: "critical",
            title,
            description: `${description} ${fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // not in allowed list.
        {
            severity: "danger",
            title,
            description: `${description} ${not_in_allowed_ip}`,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false,
                isMicrosoftActivity: false,
            },
        },
        // fraudulent IP.
        {
            severity: "warning",
            title,
            description: `${description} ${fraud_ip}`,
            predicates: {
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        {
            severity: "info",
            title,
            description: `${description} ${is_microsoft_activity}`,
            predicates: { isMicrosoftActivity: true },
        },
    ];
}

function new_transport_rule_description(fields: any) {
    return `The transport rule named "${fields.name}" was created.`;
}

const NewTransportRuleClx: Classification<any, { name: string }>[] = [
    // fraudulent and not in allowed list
    {
        severity: "critical",
        title: "Transport Rule Created",
        description: (fields: any) =>
            `${new_transport_rule_description(
                fields
            )} ${fraud_ip_not_in_allowed_list}`,
        predicates: {
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
            isFraudulentIP: true,
        },
    },
    // not in allowed list.
    {
        severity: "danger",
        title: "Transport Rule Created",
        description: (fields: any) =>
            `${new_transport_rule_description(fields)} ${not_in_allowed_ip}`,
        predicates: {
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
        },
    },
    // fraudulent IP.
    {
        severity: "warning",
        title: "Transport Rule Created",
        description: (fields: any) =>
            `${new_transport_rule_description(fields)} ${fraud_ip}`,
        predicates: {
            isFraudulentIP: true,
        },
    },
    {
        severity: "info",
        title: "Transport Rule Created",
        description: (fields: any) => new_transport_rule_description(fields),
    },
];

function enable_transport_rule_description(fields: { name: string }) {
    return `The transport rule named "${fields.name}" was enabled.`;
}

// Classifications: Enable-TransportRule
const EnableTransportRuleClx: Classification<any, { name: string }>[] = [
    // enable transport rule. fraudulent and not in allowed list.
    {
        severity: "critical",
        title: "Transport Rule Enabled",
        description: (fields: any) =>
            `${enable_transport_rule_description(
                fields
            )} ${fraud_ip_not_in_allowed_list}`,
        predicates: {
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
            isFraudulentIP: true,
        },
    },
    //  enable transport rule. not in allowed list.
    {
        severity: "danger",
        title: "Transport Rule Enabled",
        description: (fields: any) =>
            `${enable_transport_rule_description(
                fields
            )} ${not_in_allowed_ip}`,
        predicates: {
            isAllowedASN: false,
            isAllowedCountry: false,
            isAllowedRegion: false,
            isAllowedIP: false,
        },
    },
    //  enable transport rule. fraudulent IP.
    {
        severity: "warning",
        title: "Transport Rule Enabled",
        description: (fields: any) =>
            `${enable_transport_rule_description(fields)} ${fraud_ip}`,
        predicates: {
            isFraudulentIP: true,
        },
    },
    //  enable transport rule.
    {
        severity: "info",
        title: "Transport Rule Enabled",
        description: (fields: any) => enable_transport_rule_description(fields),
    },
];

// Add-DistributionGroupMember
const AddDistributionGroupMember: Meta<any, DistributionGroupMemberDescFields> =
{
    info: {
        title: "Distribution Group Member Added",
        description:
            "A single recipient was added to distribution group and mail-enabled security group.",
    },
    description_extractor: (log: any) => {
        return {
            group: GetParam(log, "Identity"),
            member: GetParam(log, "Member"),
        };
    },
    classifications: GeneralEventClx(
        "Distribution Group Member Added",
        "A recipient was added to distribution group and mail-enabled security group."
    ),
};

// Add-MailobxLocation
const MailboxLocation: Meta<any, MailboxLocationDescFields> = {
    info: {
        title: "Mail Location Added",
        description: "A Mailbox Location was added.",
    },
    description_extractor: (log: any) => {
        return {
            mailboxId: GetParam(log, "Identity"),
            mailboxLocationType: GetParam(log, "MailboxLocationType"),
        };
    },
    classifications: GeneralEventClx(
        "Mail Location Added",
        "A Mailbox Location was added."
    ),
};

// Add-MailboxPermission
const MailboxPermission: Meta<any, MailboxPermissionDescFields> = {
    info: {
        title: "Mailbox Permissions Added",
        description: "Permission was added to a mailbox.",
    },
    description_extractor: (log: any) => {
        return {
            mailbox: GetParam(log, "User"),
            accessRights: GetParam(log, "AccessRights"),
        };
    },
    classifications: GeneralEventClx(
        "Mailbox Permissions Added",
        "Permission was added to a mailbox."
    ),
};

// Add-RecipientPermission
const RecipientPermission: Meta<any, RecipientPermissionDescFields> = {
    info: {
        title: "Recipient Permission Added",
        description: "Permission was added to a recipient.",
    },
    description_extractor: (log: any) => {
        return {
            recipient: GetParam(log, "Trustee"),
            accessRights: GetParam(log, "AccessRights"),
        };
    },
    classifications: GeneralEventClx(
        "Recipient Permission Added",
        "Permission was added to a recipient."
    ),
};

// Add-UnifiedGroupLinks
const UnifiedGroupLinks: Meta<any, UnifiedGroupLinksDescFields> = {
    info: {
        title: "Microsoft 365 Group Members Added",
        description:
            "Members, owners and subscribers were added to Microsoft 365 Group.",
    },
    description_extractor: (log: any) => {
        return {
            unifiedGroupId: GetParam(log, "Identity"),
            recipientIds: GetParam(log, "Links"),
            linkType: GetParam(log, "LinkType"),
        };
    },
    classifications: GeneralEventClx(
        "Microsoft 365 Group Members Added",
        "Members were added to Microsoft 365 Group."
    ),
};

// Enable-AddressListPaging
const EnableAddressListPaging: Meta<any> = {
    info: {
        title: "Address List container Enabled",
        description: "An Address List container was enabled.",
    },
    classifications: GeneralEventClx(
        "Address List container Enabled",
        "An Acitive Directory virtual list view for address lists was enabled."
    ),
};

// Enable-Mailbox
const EnableMailbox: Meta<any, EnableMailboxDescFields> = {
    info: {
        title: "Mailbox for existing user Created",
        description: "A mailbox was created for the existing user.",
    },
    description_extractor: (log: any) => {
        return {
            archive: GetParam(log, "Archive"),
            user: GetParam(log, "Identity"),
        };
    },
    classifications: EnableMailboxClx,
};

// Enable-TransportRule
const EnableTransportRule: Meta<any, { name: string }> = {
    info: {
        title: "Transport Rule Enabled",
        description: "A Transport Rule was enabled.",
    },
    description_extractor: (log: any) => {
        return {
            name: GetParam(log, "Identity"),
        };
    },
    classifications: EnableTransportRuleClx,
};

// New-InboxRule, Set-InboxRule
const InboxRule: Meta<InboxRuleFields, any> = {
    info: {
        title: "Inbox Rule Created/Modified",
        description: "An inbox rule has been created/modified.",
    },
    extractor: (log: any) => {
        let forward_to = GetParam(log, "ForwardTo");
        let redirect_to = GetParam(log, "RedirectTo");
        let forward_as_attachment_to = GetParam(log, "ForwardAsAttachmentTo");
        let move_to_folder = GetParam(log, "MoveToFolder");
        let subjectContainWords = GetParam(log, "SubjectContainsWords");
        return {
            forwarding_set:
                forward_to || redirect_to || forward_as_attachment_to,
            move_item: move_to_folder ? true : false,
            forward_to,
            redirect_to,
            forward_as_attachment_to,
            move_to_folder,
            org: log.OrganizationName,
            risky_folders: !!move_to_folder
                ? riskyFolders.some((f) =>
                    move_to_folder.toLowerCase().includes(f)
                )
                : false,
            risky_keywords: !!subjectContainWords
                ? riskyWords.some((w) =>
                    subjectContainWords.toLowerCase().includes(w)
                )
                : false,
        };
    },
    description_extractor: (log: any) => {
        let obj: any = {};
        obj["name"] = GetParam(log, "Name");
        let move_to_folder = GetParam(log, "MoveToFolder");
        if (!!move_to_folder) {
            obj["move_to_folder"] = move_to_folder;
        }
        obj["subjectContainWords"] = GetParam(log, "SubjectContainsWords");
        return obj;
    },
    classifications: [...mail_forward_clx, ...move_to_folder_clx],
};

// Remove-InboxRule
const RemoveInboxRule: Meta<any> = {
    info: {
        title: "Inbox Rule Removed",
        description: "An inbox rule has been deleted.",
    },
    classifications: GeneralEventClx(
        "Inbox Rule Removed",
        "An inbox rule has been deleted."
    ),
};

const NewTransportRule: Meta<any, { name: string }> = {
    info: {
        title: "Transport Rule Created",
        description: "A transport rule has been created.",
    },
    description_extractor: (log: any) => {
        return {
            name: GetParam(log, "Name"),
        };
    },
    classifications: NewTransportRuleClx,
};

// Set-CASMailbox
const SetCASMailbox: Meta<MailItemsAccessedFields> = {
    info: {
        title: "CAS Mailbox Updated",
        description: "Client Access Server settings were updated.",
    },
    extractor: MailboxOperations,
    classifications: MailEventClx(
        "CAS Mailbox Updated",
        "CAS mailbox settings were updated."
    ),
};

const operations = {
    "Add-DistributionGroupMember": AddDistributionGroupMember,
    "Add-MailboxLocation": MailboxLocation,
    "Add-MailboxPermission": MailboxPermission,
    "Add-RecipientPermission": RecipientPermission,
    "Add-UnifiedGroupLinks": UnifiedGroupLinks,
    "Enable-AddressListPaging": EnableAddressListPaging,
    "Enable-Mailbox": EnableMailbox,
    "Enable-TransportRule": EnableTransportRule,
    // 'Install-AdminAuditLogConfig': InstallAdminAuditLogConfig,
    // 'Install-DataClassificationConfig': InstallDataClassificationConfig,
    // 'Install-DefaultSharingPolicy': InstallDefaultSharingPolicy,
    // 'Install-ResourceConfig': InstallResourceConfig,
    // 'New-ConditionalAccessPolicy': ConditionalAccessPolicy,
    // 'New-DistributionGroup': DistributionGroup,
    // 'New-ExchangeAssistanceConfig': ExchangeAssistanceConfig,
    // 'New-FolderMoveRequest': FolderMoveRequest,
    "New-InboxRule": InboxRule,
    // 'New-Mailbox': NewMailbox,
    // 'New-ManagementRoleAssignment': ManagementRoleAssignment,
    // 'New-RemoteDomain': RemoteDomain,
    "New-TransportRule": NewTransportRule,
    // 'Remove-DistributionGroupMember': RemoveDistrubutionGroupMember,
    "Remove-InboxRule": RemoveInboxRule,
    // 'Remove-Mailbox': RemoveMailbox,
    // 'Remove-MailboxPermission': RemoveMailboxPermission,
    // 'Remove-RecipientPermission': RemoveRecipientPermission,
    // 'Remove-TransportRule': RemoveTransportRule,
    // 'Remove-UnifiedGroup': RemoveUnifiedGroup,
    // 'Remove-UnifiedGroupLinks': RemoveUnifiedGroupLinks,
    // 'Set-AdminAuditLogConfig': SetAdminAuditLogConfig,
    // 'Set-AntiPhishPolicy': SetAntiPhishPolicy,
    // 'Set-AntiPhishRule': SetAntiPhishRule,
    "Set-CASMailbox": SetCASMailbox,
    "Set-InboxRule": InboxRule,
    // 'Set-ConditionalAccessPolicy': SetConditionalAccessPolicy,
    // 'Set-DistributionGroup': SetDistributionGroup,
    // 'Set-ExchangeAssistanceConfig': SetExchangeAssistanceConfig,
    // 'Set-HostedContentFilterPolicy': SetHostedContentFilterPolicy,
    // 'Set-Mailbox': Mailbox,
    // 'Set-MailboxJunkEmailConfiguration': SetMailboxJunkMailConfiguaration,
    // 'Set-MailboxPlan': SetMailboxPlan,
    // 'Set-OrganizationConfig': SetOrganizationConfig,
    // 'Set-OwaMailboxPolicy': SetOwaMailboxPolicy,
    // 'Set-RecipientEnforcementProvisioningPolicy': SetRecipientEnforcementProvisioningPolicy,
    // 'Set-RemoteDomain': SetRemoteDomain,
    // 'Set-SyncUser': SetSyncUser,
    // 'Set-TenantObjectVersion': SetTenantObjectVersion,
    // 'Set-TransportConfig': SetTransportConfig,
    // 'Set-UnifiedGroup': SetUnifiedGroup
    // 'Set-User': SetUser
};

export default operations;
