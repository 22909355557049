import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import * as fromStepper from './stepper.reducer';

import { environment } from 'projects/angular-clarity/src/environments/environment';

export const storeFeatureKey = 'billing';

export interface State {
    [fromStepper.featureKey]: fromStepper.State;
}

export const reducers: ActionReducerMap<State> = {
    [fromStepper.featureKey]: fromStepper.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export const getRootState = createFeatureSelector<State>(storeFeatureKey);

// Summaries
// const selectCountrySummaryFeature = createFeatureSelector<State, CountrySummary.State>(CountrySummary.featureKey);
const stepper = createSelector(getRootState, (state) => state[fromStepper.featureKey]);
// export const getCountrySummaries = createSelector(selectCountrySummaryFeature, CountrySummary.getData);

// const stepper = createFeatureSelector<State, fromStepper.State>(fromStepper.featureKey);
export const selectStep = createSelector(stepper, fromStepper.getStep);
