import { BooleanFormComponent, BooleanValidatorComponent } from '../generic/boolean';
import { updateCasMailbox } from 'projects/angular-clarity/src/app/stores/client/powershell/exo/cas-mailbox/actions';
import { CasMailbox } from 'projects/angular-clarity/src/app/stores/client/powershell/exo/cas-mailbox/model';

export const fieldName = 'PopEnabled';
export const href =
    'https://learn.microsoft.com/en-us/exchange/clients-and-mobile-in-exchange-online/pop3-and-imap4/enable-or-disable-pop3-or-imap4-access';
export { BooleanFormComponent as FormComponent, BooleanValidatorComponent as ValidatorComponent };

export function Remediate(_tenant: string, current: CasMailbox, schema: any) {
    if (schema.properties[fieldName]?.const === true) {
        const data = {
            Identity: current.ExternalDirectoryObjectId,
            [fieldName]: true,
        };
        return updateCasMailbox({ _tenant, data, field: fieldName });
    }

    if (schema.properties[fieldName]?.const === false) {
        const data = {
            Identity: current.ExternalDirectoryObjectId,
            [fieldName]: false,
        };
        return updateCasMailbox({ _tenant, data, field: fieldName });
    }
}
