import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MspAccessRolesDTO_Fetch } from '../models/msp-access-roles';
import { MspAjaxService } from 'projects/angular-clarity/src/app/services/ajax/msp-ajax.service';

@Injectable({
    providedIn: 'root',
})
export class MspAccessRolesService {
    readonly BASE_URL = '/api/octiga/v2/msp/access/roles';

    readonly FETCH_ACCESS_ROLES = `${this.BASE_URL}`;

    constructor(private http: MspAjaxService) {}

    fetchAccessRoles(): Observable<MspAccessRolesDTO_Fetch> {
        return this.http.get<MspAccessRolesDTO_Fetch>(this.FETCH_ACCESS_ROLES);
    }
}
