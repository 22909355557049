import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { Baseline } from './model';
import { initialStatus, Status } from 'projects/angular-clarity/src/app/stores/status.interface';

export const featureKey = 'swayBaseline';

export interface State extends EntityState<Baseline>, Status {}

export const adapter = createEntityAdapter<Baseline>({});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadBaselinesSuccess, (state, { data }) => adapter.setAll(data, { ...state, loaded: true })),
    on(actions.createBaselineFailure, (state, { error }) => ({ ...state, error, updating: false })),

    on(actions.createBaseline, (state, { data }) => ({ ...state, creating: true })),

    on(actions.createBaselineSuccess, (state, { data }) => adapter.upsertOne(data, { ...state, creating: false })),

    on(actions.updateBaseline, (state, { data }) => ({ ...state, updating: true })),
    on(actions.upsertBaselineItems, (state) => ({ ...state, updating: true })),

    on(actions.deleteBaselineTemplateSuccess, (state, { baselines }) =>
        adapter.removeMany(baselines, { ...state, deleting: false }),
    ),

    on(actions.detachBaselineTemplateSuccess, (state, { baselines }) =>
        adapter.upsertMany(baselines, { ...state, updating: false }),
    ),
    on(actions.upsertBaselineItemsSuccess, (state, { baselines }) =>
        adapter.upsertMany(baselines, { ...state, updating: false }),
    ),

    on(actions.updateBaselineSuccess, (state, { data }) => adapter.upsertOne(data, { ...state, updating: false })),
    on(actions.updateBaselineFailure, (state, { error }) => ({ ...state, error, updating: false })),

    on(actions.deleteBaselineSuccess, (state, { baselineId }) => adapter.removeOne(baselineId, { ...state })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
