import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, switchMap } from 'rxjs';
import { MspAccessGroupsService } from '../../services/msp-access-groups.service';
import { MspAccessRolesService } from '../../services/msp-access-roles.service';
import {
    createMspAccessGroup,
    createMspAccessGroup_success,
    deleteMspAccessGroup,
    fetchMspAccessGroups,
    fetchMspAccessGroups_success,
    fetchMspAccessRoles,
    fetchMspAccessRoles_success,
    getMspAccessGroup,
    getMspAccessGroup_success,
    updateMspAccessGroup,
    updateMspAccessGroup_success,
} from './msp-access-groups.actions';
import { EffectsBase } from 'projects/angular-clarity/src/app/modules/shared/libs/state/effects-base';
import { LoaderState } from 'projects/angular-clarity/src/app/modules/shared/libs/loader/store/loader.reducer';
import { loadSession } from 'projects/angular-clarity/src/app/stores/root/octiga/session/actions';

@Injectable()
export class MspAccessGroupsEffects extends EffectsBase {
    fetchMspAccessGroups$ = this.createEffect(
        fetchMspAccessGroups,
        exhaustMap(() =>
            this.mspAccessGroupsService.fetchAccessGroups().pipe(
                switchMap((mspAccessGroups) => [fetchMspAccessGroups_success({ accessGroups: mspAccessGroups })]),
                this.catchError('Error fetching access groups', fetchMspAccessGroups),
            ),
        ),
    );

    getMspAccessGroup$ = this.createEffect(
        getMspAccessGroup,
        exhaustMap(({ groupId }) =>
            this.mspAccessGroupsService.getAccessGroup(groupId).pipe(
                switchMap((selectedAccessGroup) => [getMspAccessGroup_success({ selectedAccessGroup })]),
                this.catchError('Error getting access group', getMspAccessGroup),
            ),
        ),
    );

    createMspAccessGroup$ = this.createEffect(
        createMspAccessGroup,
        exhaustMap(({ props: { newGroup }, successFnc }) =>
            this.mspAccessGroupsService.createAccessGroup(newGroup).pipe(
                switchMap(() => [createMspAccessGroup_success(), loadSession()]),
                this.success('Access group created successfully', successFnc),
                this.catchError(
                    'Error attempting to create access group. Please check if the group name is duplicated.',
                    createMspAccessGroup,
                ),
            ),
        ),
    );

    updateMspAccessGroup$ = this.createEffect(
        updateMspAccessGroup,
        exhaustMap(({ props: { groupId, updatedGroup }, successFnc }) =>
            this.mspAccessGroupsService.updateAccessGroup(groupId, updatedGroup).pipe(
                switchMap(() => [updateMspAccessGroup_success(), loadSession()]),
                this.success('Access group update successful', successFnc),
                this.catchError(
                    'Failed to update the access group. Please check if the group name is duplicated.',
                    updateMspAccessGroup,
                ),
            ),
        ),
    );

    fetchMspAccessRoles$ = this.createEffect(
        fetchMspAccessRoles,
        exhaustMap(() =>
            this.mspAccessRolesService.fetchAccessRoles().pipe(
                switchMap((accessRoles) => [fetchMspAccessRoles_success({ accessRoles })]),
                this.catchError('Error fetching access roles', fetchMspAccessRoles),
            ),
        ),
    );

    deleteMspAccessGroup$ = this.createEffect(
        deleteMspAccessGroup,
        exhaustMap(({ props: { groupId }, successFnc }) =>
            this.mspAccessGroupsService
                .deleteAccessGroup(groupId)
                .pipe(
                    this.success('Access group deleted', successFnc),
                    this.catchError('Error deleting access group', deleteMspAccessGroup),
                )
                .pipe(switchMap(() => [loadSession()])),
        ),
        false,
    );

    constructor(
        private mspAccessGroupsService: MspAccessGroupsService,
        private mspAccessRolesService: MspAccessRolesService,
        protected actions$: Actions,
        protected loaderStore: Store<LoaderState>,
        protected snackbar: MatSnackBar,
    ) {
        super(actions$, loaderStore, snackbar);
    }
}
